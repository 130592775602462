import * as React from "react";
import { Layout, Menu, Icon, Badge, Button, Modal, Row, Input } from "antd";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { Badges } from "../../../helpers/model_services/RequestService";
import { connect } from "react-redux";
import { AppState } from "../../../store/appStore";
import { badgesActions } from "../../../redux/badges";
import Search from "antd/lib/input/Search";
import SubMenu from "antd/lib/menu/SubMenu";
import getName from "../../../helpers/MyFunctions";
const { Header } = Layout;

export interface Props extends RouteComponentProps {
  loggedIn: boolean;
  badges: Badges;
  fetchBadges: () => void;
  userFullName: string;
}

const HeaderBar: React.FC<Props> = ({
  loggedIn,
  location: { pathname },
  fetchBadges,
  badges: { bcCount, callMeCount, reqCount },
  history,
  userFullName
}) => {
  const cur = pathname.split("/")[1];

  React.useEffect(() => {
    if (loggedIn) {
      fetchBadges();
    }
  }, [cur]);
  return (
    <Header
      style={{
        left: false ? 80 : 200,
        right: 0,
        paddingLeft: 0,
        paddingRight: 0,
        background: "#fff",
        position: "fixed",
        zIndex: 1
      }}
    >
      {loggedIn ? (
        <Menu
          selectedKeys={[cur]}
          theme="light"
          mode="horizontal"
          style={{ lineHeight: "64px" }}
        >
          <Menu.Item key={`requests`}>
            <Link to={`/requests/completed`}>
              <i className="fas fa-file-alt" style={{ marginRight: 6 }} />
              <span>Requests</span>
              <Badge
                count={reqCount}
                style={{ marginLeft: 5, boxShadow: "none" }}
              />
            </Link>
          </Menu.Item>
          <Menu.Item key={`business-cases`}>
            <Link to={`/business-cases/open`}>
              <i className="fas fa-briefcase" style={{ marginRight: 6 }} />
              <span>Business cases</span>
              <Badge
                count={bcCount}
                style={{ marginLeft: 5, boxShadow: "none" }}
              />
            </Link>
          </Menu.Item>
          <Menu.Item key={`clients`}>
            <Link to={`/clients/new`}>
              <i className="fas fa-phone-volume" style={{ marginRight: 6 }} />
              <span>Call me</span>
              <Badge
                count={callMeCount}
                style={{ marginLeft: 5, boxShadow: "none" }}
              />
            </Link>
          </Menu.Item>
          <Menu.Item key={`visa`}>
            <Link to={`/visa/sales`}>
              <i className="fas fa-file-alt" style={{ marginRight: 6 }} />
              <span>Visa applications</span>
            </Link>
          </Menu.Item>
          <Menu.Item key={`settings`}>
            <Link to={`/settings/countries`}>
              <i className="fas fa-cog" style={{ marginRight: 6 }} />
              <span>Settings</span>
            </Link>
          </Menu.Item>
          <SubMenu
            title={
              <span className="submenu-title-wrapper">
                <i className="fas fa-user" style={{ marginRight: 6 }}></i>
                {userFullName}
              </span>
            }
            style={{ float: "right" }}
          >
            <Menu.Item>
              <Link to="/logout">
                <Icon type="logout" />
                <span>Log out</span>
              </Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key={`search`} style={{ float: "right" }}>
            <Search
              placeholder="Search"
              onSearch={value => history.push(`/search/${value}`)}
              style={{ width: 200 }}
            />
          </Menu.Item>
        </Menu>
      ) : (
        <Menu
          selectedKeys={[cur]}
          theme="light"
          mode="horizontal"
          style={{ lineHeight: "64px" }}
        >
          <Menu.Item style={{ float: "right" }}>
            <Link to={"/login"}>
              <Icon type="login" />
              <span>Log in</span>
            </Link>
          </Menu.Item>
        </Menu>
      )}
    </Header>
  );
};
const mapStateToProps = ({
  user: {
    loggedIn,
    user: { name, lastName }
  },
  badges: { badges }
}: AppState) => ({
  loggedIn,
  badges,
  userFullName: getName(name, lastName)
});

const dispatchToProps = {
  fetchBadges: badgesActions.fetchBadges
};

export default withRouter(
  connect(
    mapStateToProps,
    dispatchToProps
  )(HeaderBar)
);
