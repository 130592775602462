import * as React from "react";
import { Row, Switch, Col, DatePicker, Collapse } from "antd";
import InputField from "../_common/InputField/InputField";
import { Mode } from "../../types";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import MyCard from "../_common/MyCard";

export interface Props {
  identityRecognizedData: string;
  disabled: boolean;
}

const Iqama: React.SFC<Props> = ({ disabled, identityRecognizedData }) => {
  return (
    <MyCard
      title="National ID / Iqama"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.nationalIdentityNumber"
            label="National ID / Iqama"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.idDocumentValidTo"
            label="Expiration date"
            node={<DatePicker />}
            type="date"
          />
        </Col>
      </Row>
      {identityRecognizedData !== null ? (
        <Row>
          <Collapse bordered={false}>
            <Collapse.Panel
              header="Recognized data"
              key="1"
              style={{ marginBottom: 12, border: 0 }}
            >
              <p>{identityRecognizedData}</p>
            </Collapse.Panel>
          </Collapse>
        </Row>
      ) : null}
    </MyCard>
  );
};

const mapStateToProps = ({ traveler: { mode } }: AppState) => ({
  mode
});

export default connect(mapStateToProps)(Iqama);
