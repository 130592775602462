import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import { Button, Modal, Row, Form, Select, message } from "antd";
import Traveler from "../../model/Traveler";
import VisaRequestService from "../../helpers/model_services/VisaRequestService";
import { requestActions } from "../../redux/request/requestSlice";

export interface Props {
  travelers: Traveler[];
  refresh: () => void;
}

const visaService = new VisaRequestService();

const SwapVisas: React.FC<Props> = ({ travelers, refresh }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [travelerA, setTravelerA] = React.useState<number>();
  const [travelerB, setTravelerB] = React.useState<number>();
  const close = () => {
    setVisible(false);
    setTravelerA(undefined);
    setTravelerB(undefined);
  };
  return (
    <React.Fragment>
      <Button
        className="dark"
        type="primary"
        size="small"
        style={{ marginRight: 12 }}
        disabled={travelers.length < 2}
        onClick={() => setVisible(true)}
      >
        Swap visa requests
      </Button>
      <Modal
        title="Swap visa requests"
        visible={visible}
        onOk={() => {
          if (travelerA && travelerB) {
            visaService.swap(travelerA, travelerB).then(() => {
              refresh();
              close();
            });
          } else {
            message.error("Both travelers have to be selected!");
          }
        }}
        onCancel={close}
      >
        <Row>
          <Form.Item label="First traveler" style={{ marginBottom: 12 }}>
            <Select<number>
              style={{ width: 200 }}
              autoFocus
              onChange={value => setTravelerA(value)}
              value={travelerA}
            >
              {travelers.map(({ name, lastName, id }) => (
                <Select.Option key={id} value={id}>
                  {`${name} ${lastName}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="Second traveler" style={{ marginBottom: 12 }}>
            <Select<number>
              style={{ width: 200 }}
              autoFocus
              onChange={value => setTravelerB(value)}
              value={travelerB}
            >
              {travelers.map(({ name, lastName, id }) => (
                <Select.Option key={id} value={id}>
                  {`${name} ${lastName}`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Row>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelers }
  }
}: AppState) => ({
  travelers
});

const mapDispatchToProps = {
  refresh: requestActions.fetchRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SwapVisas);
