import AbstractRestService from "./AbstractRestService";
import User from "../../model/User";
import { AxiosPromise } from "axios";
import UserLoginRequest from "../../model/UserLoginRequest";

export default class UserService extends AbstractRestService<User> {
  constructor() {
    super("User");
  }

  login(credentials: UserLoginRequest) {
    return this._http.post(this.apiUrl + "login", credentials);
  }

  profile(): AxiosPromise<User> {
    return this._http.get(this.apiUrl + "profile");
  }

  sendResetLink(email: string) {
    return this._http.post(`${this.apiUrl}reset-password`, { email });
  }

  resetPassword(newPassword: string, resetString: string) {
    return this._http.patch(`${this.apiUrl}reset-password`, {
      resetString,
      newPassword
    });
  }
}
