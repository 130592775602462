import * as React from "react";
import { createSlice } from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { Mode } from "../../types";
import Traveler from "../../model/Traveler";
import TravelerService from "../../helpers/model_services/TravelerService";
import DocumentsCheckList from "../../model/DocumentsCheckListItem";
import { requestActions } from "../request/requestSlice";
import { Modal, List } from "antd";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";
import { travelerNoteActions } from "./travelerNotesSlice";
import TravelerDocumentsCheckListsService from "../../helpers/model_services/TravelersCheckListService";

export interface TravelerState {
  loading: boolean;
  traveler: Traveler;
  error: string;
  visible: boolean;
  mode: Mode;
}

export interface TravelerActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: Traveler;
  setVisible: boolean;
  changeMode: Mode;
}

export const travelerSlice = createSlice<
  TravelerActions,
  TravelerState,
  AppState
>({
  slice: "traveler",
  initialState: {
    loading: false,
    traveler: {} as Traveler,
    error: "Error!",
    visible: false,
    mode: "view"
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      traveler: payload,
      loading: false
    }),
    setVisible: (state, payload) => ({
      ...state,
      visible: payload
    }),
    changeMode: (state, payload) => ({
      ...state,
      mode: payload
    })
  }
});

const { fetchError, fetchStart, fetchSuccess } = travelerSlice.actions;

const travelerService = new TravelerService();

const checklistService = new TravelerDocumentsCheckListsService();

const docService = new TravelerDocumentsService();

function sortChecklist(
  checklist: DocumentsCheckList[],
  roleName: string
): DocumentsCheckList[] {
  return checklist.sort(
    (
      { responsibilityPerson: aRoles, requestedDocuments: aDoc },
      { responsibilityPerson: bRoles, requestedDocuments: bDoc }
    ) => {
      const aContainsRole = aRoles.includes(roleName);
      const bContainsRole = bRoles.includes(roleName);
      return (aContainsRole && bContainsRole) ||
        (!aContainsRole && !bContainsRole)
        ? aDoc.localeCompare(bDoc)
        : aContainsRole
        ? -1
        : 1;
    }
  );
}

const fetchTraveler = (
  travelerId?: number
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    traveler: {
      traveler: { id: _id }
    },
    user: {
      user: {
        role: { name: roleName }
      }
    }
  } = getState();
  const id = travelerId ? travelerId : _id;
  // START
  dispatch(fetchStart());
  dispatch(travelerNoteActions.fetchStart());

  try {
    const { data } = await travelerService.getById(id);

    // SUCCESS
    dispatch(fetchSuccess(data));
    dispatch(travelerNoteActions.fetchSuccess(data.noteTraveler));

    return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
    return false;
  }
};

const verifyChecklist = (
  travelerId: number,
  visaId: number
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const {
      user: {
        user: {
          role: { name }
        }
      }
    } = getState();
    const { data } = await checklistService.getCheckListById(
      travelerId,
      visaId
    );
    const filteredChecklistByRole = data.filter(c =>
      c.checkName.toLowerCase().includes(name.toLowerCase())
    );
    if (filteredChecklistByRole.length > 0) {
      const filteredChecklist = filteredChecklistByRole.filter(
        c => !c.documentChecked && c.isMandatory
      );
      if (filteredChecklist.length === 0) {
        return true;
      } else {
        const response = await new Promise<boolean>(res => {
          Modal.confirm({
            title:
              "Checklist is not completed for current role and status. Are you sure?",
            okText: "Ok",
            cancelText: "Cancel",
            onOk: () => res(true),
            onCancel: () => res(false),
            content: (
              <List<DocumentsCheckList>
                size="small"
                bordered
                dataSource={filteredChecklist}
                renderItem={item => (
                  <List.Item>{item.requestedDocuments}</List.Item>
                )}
              />
            )
          });
        });
        return response;
      }
    } else return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
  return false;
};

const openTraveler = (
  travelerId: number
): ThunkAction<void, AppState, null, AnyAction> => async dispatch => {
  try {
    const response = await dispatch(fetchTraveler(travelerId));
    if (response) {
      dispatch(actions.setVisible(true));
    }
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
};

const updateTraveler = (
  traveler: Traveler
): ThunkAction<
  Promise<boolean>,
  AppState,
  null,
  AnyAction
> => async dispatch => {
  dispatch(fetchStart());
  try {
    await travelerService.update(traveler);
    await dispatch(fetchTraveler());
    return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
  return false;
};

const deleteTraveler = (
  traveler: Traveler
): ThunkAction<void, AppState, null, AnyAction> => async dispatch => {
  dispatch(fetchStart());
  travelerService
    .delete(traveler)
    .then(({ data }) => {
      dispatch(requestActions.fetchRequest());
    })
    .catch(error => {
      dispatch(fetchError(error.toString()));
    });
};

const submitTraveler = (
  values: Traveler,
  file?: any
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  dispatch(fetchStart());
  try {
    const {
      traveler: { mode },
      requestDetail: {
        request: { itemId }
      }
    } = getState();
    switch (mode) {
      case "new":
        const { data: newId, status: statusC } = await travelerService.create({
          ...values,
          reqItemId: itemId[0]
        });
        if (file) {
          await docService.uploadFile(newId, 1, file);
        }
        if (statusC === 200) {
          dispatch(requestActions.fetchRequest());
          return true;
        } else {
          dispatch(fetchError(`Status ${statusC}`));
          return false;
        }
      case "edit":
        const { status: statusU } = await travelerService.update(values);
        if (statusU === 200) {
          dispatch(requestActions.fetchRequest());
          return true;
        } else {
          dispatch(fetchError(`Status ${statusU}`));
          return false;
        }
      default:
        dispatch(fetchError("Invalid edit mode for this action"));
        return false;
    }
  } catch (error) {
    dispatch(fetchError(error.toString()));
    return false;
  }
};

const { actions } = travelerSlice;

export const travelerActions = {
  ...actions,
  fetchTraveler,
  updateTraveler,
  submitTraveler,
  openTraveler,
  deleteTraveler,
  verifyChecklist
};
export const travelerReducer = travelerSlice.reducer;
