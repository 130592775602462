import * as React from "react";
import TagsService from "../../../helpers/model_services/TagsService";
import BasicPage from "../../_common/BasicPage/BasicPage";
import Tags from "../../../model/Tags";
import { useSearchProps } from "../../_hooks/useColumnProps";

export interface Props {}

const TagsPage: React.FC<Props> = () => {
  return (
    <BasicPage<Tags>
      restService={new TagsService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        }
      ]}
    />
  );
};

export default TagsPage;
