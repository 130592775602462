import AbstractRestService from "./AbstractRestService";
import DocumentType from "../../model/DocumentType";

export default class DocumentsTypesService extends AbstractRestService<
  DocumentType
> {
  constructor() {
    super("Codelists/DocumentsTypes");
  }
}
