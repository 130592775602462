import * as React from "react";
import { Typography, Form } from "antd";
const { Text } = Typography;
export interface Props {
  label: string;
  text?: string | React.ReactNode;
}

const TextWithLabel: React.SFC<Props> = ({ label, text = "" }) => {
  return (
    <Form.Item
      colon
      label={<Text strong>{label}</Text>}
      labelCol={{
        span: 6
      }}
      wrapperCol={{
        span: 18
      }}
      style={{ marginBottom: 0 }}
    >
      {typeof text === "string" ? <Text>{text}</Text> : text}
    </Form.Item>
  );
};

export default TextWithLabel;
