import * as React from "react";
import AgeCategoryService from "../../../helpers/model_services/AgeCategoryService";
import BasicPage from "../../_common/BasicPage/BasicPage";
import { useSearchProps } from "../../_hooks/useColumnProps";

export interface Props {}

const AgeCategoriesPage: React.FC<Props> = () => {
  return (
    <BasicPage
      restService={new AgeCategoryService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        }
      ]}
    />
  );
};

export default AgeCategoriesPage;
