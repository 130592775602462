import * as React from "react";
import { Row, DatePicker, Col, Select } from "antd";
import InputField from "../_common/InputField/InputField";
import MyCard from "../_common/MyCard";
import useSelectVals from "../_hooks/useSelectVals";
import PassportTypeService from "../../helpers/model_services/PassportTypeService";

export interface Props {
  disabled: boolean;
}

const PassportInfo: React.SFC<Props> = ({ disabled }) => {
  const { data: passTypes } = useSelectVals(new PassportTypeService());
  return (
    <MyCard
      title="Passport"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.passportNumber"
            label="Passport number"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.passportTypeId"
            label="Passport type"
            type="select"
            node={
              <Select>
                {passTypes.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.passportIssuedAt"
            label="Date of issue"
            node={<DatePicker />}
            type="date"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.passportValidUntil"
            label="Passport exp. date"
            node={<DatePicker />}
            type="date"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.passportIssuedBy"
            label="Issued by"
          />
        </Col>
      </Row>
    </MyCard>
  );
};

export default PassportInfo;
