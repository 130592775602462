import * as React from "react";
import User from "../../../model/User";
import { toast } from "react-toastify";
import {
  Theme,
  createStyles,
  WithStyles,
  Paper,
  Typography,
  Button,
  withStyles,
  TextField
} from "@material-ui/core";
import UserService from "../../../helpers/model_services/UserService";

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
        .spacing.unit * 3}px`
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.primary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit
    },
    submit: {
      marginTop: theme.spacing.unit * 3
    }
  });

export interface Props extends WithStyles<typeof styles> {}

export interface State {
  user: User;
  disabled: boolean;
}

class ProfilePage extends React.Component<Props, State> {
  private userService: UserService = new UserService();
  private title: string = "";
  state = {
    user: {} as User,
    disabled: true
  };

  async componentDidMount() {
    const { data } = await this.userService.profile();
    const { username, email } = data;
    this.title = "Profile of " + username + " [" + email + "]";
    this.setState({ user: data });
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { disabled } = this.state;
    /* if (!disabled) {
      const { userId, firstName, lastName, email, isAdmin } = this.state.user;
      const { status } = await http.put(apiEndpoint + "/Users/" + userId, {
        firstName,
        lastName,
        email,
        isAdmin
      });

      if (status === 200) {
        toast.success("Údaje byly změněny.");
      }
    } */
    toast.error("Not implemented yet.");
    this.setState({ disabled: !disabled });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const user: User = { ...this.state.user };
    user[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ user });
  };

  renderInput(
    label: string,
    name: "username" | "name" | "email" | "phone",
    type: string = "text",
    autoFocus: boolean = false
  ): JSX.Element {
    const { user, disabled } = this.state;
    return (
      <TextField
        autoFocus={autoFocus}
        margin="dense"
        label={label}
        type={type}
        value={user[name]}
        fullWidth
        name={name}
        onChange={this.handleChange}
        disabled={disabled}
      />
    );
  }

  render() {
    const { disabled } = this.state;
    const { classes } = this.props;
    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            {this.title}
          </Typography>
          <form onSubmit={this.handleSubmit} className={classes.form}>
            {this.renderInput("Username", "username", "text", true)}
            {this.renderInput("Name", "name")}
            {this.renderInput("Email address", "email", "email")}
            {this.renderInput("Phone number", "phone", "number")}
            <Button type="submit" fullWidth variant="contained" color="primary">
              {disabled ? "Edit" : "Save"}
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(ProfilePage);
