import * as React from "react";
import { Modal, Button } from "antd";
import InvoiceTable from "./InvoiceTable";
import Invoice from "../../../model/Invoice";
export interface Props {
  isPaymentOrder?: boolean;
  deletedInvoice: Invoice[];
}

const DeletedInvoices: React.FC<Props> = ({
  isPaymentOrder = false,
  deletedInvoice
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <Button
        type="primary"
        className="dark"
        onClick={() => {
          setVisible(true);
        }}
        size="small"
      >
        HISTORY
      </Button>
      <Modal
        title={`Deleted ${isPaymentOrder ? "payment orders" : "invoices"}`}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        onOk={() => {
          setVisible(false);
        }}
      >
        <InvoiceTable
          isPaymentOrder={isPaymentOrder}
          deletedInvoices={deletedInvoice}
          isDelete
        />
      </Modal>
    </React.Fragment>
  );
};

export default DeletedInvoices;
