import * as React from "react";
import { Row, Col } from "antd";
import InputField from "../_common/InputField/InputField";
import { Mode } from "../../types";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import MyCard from "../_common/MyCard";

export interface Props {
  mode: Mode;
}

const InvitedByPerson: React.SFC<Props> = ({ mode }) => {
  const disabled = mode === "view";
  return (
    <MyCard
      title="Invited by person / Hotel name"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.invitedByPersonName"
            label="Name"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.invitedByPersonLastName"
            label="Surname"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.hotelName"
            label="Hotel name"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.invitedByPersonContact"
            label="Address"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.invitedByPersonEmail"
            label="Email"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.invitedByPersonPhone"
            label="Tel."
          />
        </Col>
      </Row>
    </MyCard>
  );
};

const mapStateToProps = ({ visa: { mode } }: AppState) => ({
  mode
});

export default connect(mapStateToProps)(InvitedByPerson);
