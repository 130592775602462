import * as React from "react";
import { Table, Icon, Tooltip, Button, Popconfirm, message } from "antd";
import Invoice from "../../../model/Invoice";
import moment from "moment/moment.js";
import { connect } from "react-redux";
import { AppState } from "../../../store/appStore";
import InvoiceService from "../../../helpers/model_services/InvoiceService";
import { financeActions } from "../../../redux/request/financeSlice";

export interface Props {
  data: Invoice[];
  isPaymentOrder?: boolean;
  refresh: () => void;
  deletedInvoices?: Invoice[];
  isDelete?: boolean;
}

const service = new InvoiceService();

const InvoiceTable: React.SFC<Props> = ({
  data,
  refresh,
  isPaymentOrder = false,
  deletedInvoices = [],
  isDelete = false
}) => {
  const _data = isDelete ? deletedInvoices : data;
  return (
    <Table<Invoice>
      columns={[
        {
          dataIndex: "invoiceNumber",
          title: "#"
        },
        {
          dataIndex: "createdAt",
          title: "Date",
          render: date =>
            date === null ? null : moment(date).format("D.M.YYYY")
        },
        {
          dataIndex: "id",
          render: (id: number, record) => (
            <span className="visa-buttons">
              <Popconfirm
                title={`Which type of ${
                  isPaymentOrder ? "payment order" : "invoice"
                } do you want to download?`}
                okText="Complex"
                cancelText="Simple"
                onConfirm={() => {
                  service.getInvoiceHtml(id, true).then(({ data }) => {
                    var tab = window.open("about:blank", "_blank");
                    tab!.document.write(data); // where 'html' is a variable containing your HTML
                    tab!.document.close(); // to finish loading the page
                  });
                }}
                onCancel={() => {
                  service.getInvoiceHtml(id, false).then(({ data }) => {
                    var tab = window.open("about:blank", "_blank");
                    tab!.document.write(data); // where 'html' is a variable containing your HTML
                    tab!.document.close(); // to finish loading the page
                  });
                }}
              >
                <Tooltip placement="top" title="Download">
                  <Icon type="eye" style={{ fontSize: 20 }} />
                </Tooltip>
              </Popconfirm>
              {isPaymentOrder && deletedInvoices.length === 0 ? (
                <React.Fragment>
                  <Popconfirm
                    placement="top"
                    title="Are you sure you want to turn this payment order into an invoice?"
                    onConfirm={() =>
                      service.makeInvoice(id).then(({ status }) => {
                        if (status === 200) {
                          refresh();
                          message.success("Success");
                        }
                      })
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="primary" size="small" className="orange">
                      INVOICE
                    </Button>
                  </Popconfirm>
                </React.Fragment>
              ) : null}
              {deletedInvoices.length === 0 ? (
                <Popconfirm
                  placement="top"
                  title="Are you sure you want to delete this record?"
                  onConfirm={() => service.delete(record).then(() => refresh())}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip placement="top" title="Delete">
                    <Button type="link" style={{ padding: 0 }}>
                      <Icon
                        type="delete"
                        theme="twoTone"
                        twoToneColor="#ed1b24"
                        style={{ fontSize: 20 }}
                      />
                    </Button>
                  </Tooltip>
                </Popconfirm>
              ) : null}
            </span>
          )
        }
      ]}
      dataSource={_data.filter(d => !d.isInvoice === isPaymentOrder)}
      size="middle"
      onRow={row => ({
        onClick: () => {}
      })}
      rowKey={({ id }) => id.toString()}
      pagination={false}
    />
  );
};

const mapStateToProps = ({
  finance: {
    finance: { invoices }
  }
}: AppState) => ({
  data: invoices
});

const mapDispatchToProps = {
  refresh: financeActions.fetchFinance
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceTable);
