import { RequestNote } from "../../model/Note";
import { Mode } from "../../types";
import {
  createSlice,
  ThunkAction,
  AnyAction
} from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import RequestNoteService from "../../helpers/model_services/RequestNoteService";
import getName from "../../helpers/MyFunctions";
import { requestActions } from "./requestSlice";

export interface RequestNoteState {
  loading: boolean;
  requestNotes: RequestNote[];
  error: string;
  visible: boolean;
  mode: Mode;
}

export interface RequestNoteActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: RequestNote[];
  setVisible: boolean;
  setMode: Mode;
}

const service = new RequestNoteService();

export const requestNoteSlice = createSlice<
  RequestNoteActions,
  RequestNoteState,
  AppState
>({
  slice: "requestNotes",
  initialState: {
    loading: false,
    requestNotes: [],
    error: "Error!",
    visible: false,
    mode: "view"
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      loading: false,
      requestNotes: payload
    }),
    setVisible: (state, payload) => ({
      ...state,
      visible: payload
    }),
    setMode: (state, payload) => ({
      ...state,
      mode: payload
    })
  }
});

const submitNote = (
  values: RequestNote
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    requestNotes: { mode }
  } = getState();
  const { fetchStart, fetchError } = requestNoteActions;
  try {
    fetchStart();
    switch (mode) {
      case "new":
        const {
          user: {
            user: { id: userId }
          },
          requestDetail: {
            request: { id: requestId }
          }
        } = getState();
        await service.create({ ...values, userId, requestId });
        break;
      case "edit":
        await service.update(values);
        break;
      case "delete":
        await service.delete(values);
        break;
      default:
        dispatch(fetchError("Invalid edit mode for this action."));
        return false;
        break;
    }
    await dispatch(requestActions.fetchRequest());
    return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
    return false;
  }
};

export const requestNoteActions = {
  ...requestNoteSlice.actions,
  submitNote
};
export const requestNoteReducer = requestNoteSlice.reducer;
