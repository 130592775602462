import AbstractRestService from "./AbstractRestService";
import { RequestNote } from "../../model/Note";

export default class RequestNoteService extends AbstractRestService<
  RequestNote
> {
  constructor() {
    super("Codelists/Note");
  }
}
