import * as React from "react";
import { Table } from "antd";
import { RequestListItem } from "../../helpers/model_services/RequestService";
import {
  ColumnProps,
  PaginationConfig,
  SorterResult,
  TableCurrentDataSource
} from "antd/lib/table";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import { requestListActions } from "../../redux/request/requestListSlice";
import { withRouter, RouteComponentProps } from "react-router-dom";
import moment from "moment/moment.js";
import {
  useSearchProps,
  useIntervalProps,
  useIntervalDateProps
} from "../_hooks/useColumnProps";

export interface Props
  extends RouteComponentProps<{ type: string; filter: string; id?: string }> {
  loading: boolean;
  dataSource: RequestListItem[];
  businessCase: boolean;
  pagination: PaginationConfig;
  onTableChange: (
    pagination: PaginationConfig,
    filters: Record<keyof RequestListItem, string[]>,
    sorter: SorterResult<RequestListItem>,
    extra: TableCurrentDataSource<RequestListItem>
  ) => void;
  filters: Record<keyof RequestListItem, string[]>;
  sorter: SorterResult<RequestListItem>;
}

const RequestTable: React.FC<Props> = ({
  loading,
  dataSource,
  businessCase,
  pagination,
  onTableChange,
  history,
  match: {
    params: { type, filter }
  },
  filters,
  sorter
}) => {
  const columns: ColumnProps<RequestListItem>[] = [
    {
      title: businessCase ? "BC ID" : "ID",
      render: record => {
        return `#${record}`;
      },
      ...useIntervalProps(businessCase ? "bc_Id" : "id", true),
      filteredValue: filters[businessCase ? "bc_Id" : "id"],
      sortOrder:
        sorter.field === (businessCase ? "bc_Id" : "id") && sorter.order
    },
    {
      title: "Creation date",
      ...useIntervalDateProps("createdAt", true),
      render: (text: Date) =>
        moment(moment.utc(text).toDate()).format("D.M.YYYY HH:mm"),
      filteredValue: filters["createdAt"],
      sortOrder: sorter.field === "createdAt" && sorter.order
    },
    {
      title: "Customer",
      ...useSearchProps("customerName", { apiFilter: true }),
      filteredValue: filters["customerName"],
      sortOrder: sorter.field === "customerName" && sorter.order
    },
    {
      title: "Contact Phone",
      ...useSearchProps("contactPhone", { apiFilter: true }),
      filteredValue: filters["contactPhone"],
      sortOrder: sorter.field === "contactPhone" && sorter.order
    },
    {
      title: "Requested country",
      ...useSearchProps("requestedCountry", { apiFilter: true }),
      filteredValue: filters["requestedCountry"],
      sortOrder: sorter.field === "requestedCountry" && sorter.order
    },
    {
      title: "Requested region",
      ...useSearchProps("requestedRegion", { apiFilter: true }),
      filteredValue: filters["requestedRegion"],
      sortOrder: sorter.field === "requestedRegion" && sorter.order
    },
    {
      title: "Confirmed country",
      ...useSearchProps("confirmedCountry", { apiFilter: true }),
      filteredValue: filters["confirmedCountry"],
      sortOrder: sorter.field === "confirmedCountry" && sorter.order
    },
    {
      title: "Confirmed region",
      ...useSearchProps("confirmedRegion", { apiFilter: true }),
      filteredValue: filters["confirmedRegion"],
      sortOrder: sorter.field === "confirmedRegion" && sorter.order
    },
    {
      title: "# of Travelers",
      ...useIntervalProps("numberOfTravelers", true),
      filteredValue: filters["numberOfTravelers"],
      sortOrder: sorter.field === "numberOfTravelers" && sorter.order
    },
    {
      title: "Price",
      ...useIntervalProps("price", true),
      filteredValue: filters["price"],
      sortOrder: sorter.field === "price" && sorter.order
    },
    {
      title: "Paid",
      ...useIntervalProps("paid", true),
      filteredValue: filters["paid"],
      sortOrder: sorter.field === "paid" && sorter.order
    }
  ];
  return (
    <Table<RequestListItem>
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      size="middle"
      onRow={row => ({
        onClick: () => {
          history.push(`/${type}/${filter}/${row.id}`);
        }
      })}
      rowKey={({ id }) => id.toString()}
      pagination={pagination}
      onChange={onTableChange}
    />
  );
};

const mapStateToProps = ({
  requestList: {
    loading,
    requestList: { requestModel },
    isBc,
    pagination,
    filters,
    sorter
  }
}: AppState) => ({
  loading,
  dataSource: requestModel,
  businessCase: isBc,
  pagination,
  filters,
  sorter
});

const mapDispatchToProps = {
  onTableChange: requestListActions.onTableChange
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestTable)
);
