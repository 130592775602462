import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import { Button, Icon } from "antd";
import { visaActions } from "../../redux/visa/visaSlice";

export interface Props {
  travelerId: number;
  visaId: number;
  role: string;
  statusId: number;
  workflowId: number;
  onStatusChange: (
    visaId: number,
    travelerId: number,
    statusId: number,
    verify: boolean
  ) => Promise<boolean>;
}

const ForwardButton: React.FC<Props> = ({
  role,
  statusId,
  workflowId,
  onStatusChange,
  travelerId,
  visaId
}) => {
  let text = "";
  let nextStatus: number;
  const [loading, setLoading] = React.useState<boolean>(false);
  switch (workflowId) {
    case 1:
      switch (statusId) {
        case 10: // Sales
          if (role === "sales") {
            text = "DataEntry";
            nextStatus = 30;
          }
          break;
        case 30: // Data entry
          if (role === "data-entry") {
            text = "Ready to submit";
            nextStatus = 50;
          }
          break;
        case 50: // Ready to submit
          if (role === "runner") {
            text = "Submitted";
            nextStatus = 60;
          }
          break;
        case 60: // Submitted
          if (role === "runner" || role === "data-entry") {
            text = "Ready to collect";
            nextStatus = 70;
          }
          break;
        case 70: // Ready to collect
          if (role === "runner" || role === "data-entry") {
            text = "Collected";
            nextStatus = 80;
          }
          break;
        default:
          break;
      }
      break;
    case 2:
      switch (statusId) {
        case 10: // Sales
          if (role === "sales") {
            text = "DataEntry";
            nextStatus = 30;
          }
          break;
        case 30: // Data entry
          if (role === "data-entry") {
            text = "Ready to collect";
            nextStatus = 70;
          }
          break;
        case 70: // Ready to collect
          if (role === "runner" || role === "data-entry") {
            text = "Collected";
            nextStatus = 80;
          }
          break;
        default:
          break;
      }
      break;
    case 3:
      switch (statusId) {
        case 10: // Sales
          if (role === "sales") {
            text = "DataEntry";
            nextStatus = 30;
          }
          break;
        case 30: // Data entry
          if (role === "data-entry") {
            text = "Apply online";
            nextStatus = 70;
          }
          break;
        case 40: // Apply online
          if (role === "data-entry") {
            text = "Ready to collect";
            nextStatus = 70;
          }
          break;
        case 70: // Ready to collect
          if (role === "runner" || role === "datae-ntry") {
            text = "Collected";
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return text.length > 0 ? (
    <Button
      loading={loading}
      type="primary"
      className="orange"
      onClick={() => {
        setLoading(true);
        onStatusChange(visaId, travelerId, nextStatus, true).then(() => {
          setLoading(false);
        });
      }}
    >
      <span>
        To {text}{" "}
        <Icon type="caret-right" style={{ verticalAlign: "middle" }} />
      </span>
    </Button>
  ) : null;
};

export const mapStateToProps = ({
  user: {
    user: {
      role: { name: role }
    }
  },
  visa: {
    visa: { travelerId, id: visaId, visaRequestStatusesId: statusId },
    workflowId
  }
}: AppState) => ({
  role: role.toLowerCase(),
  workflowId,
  travelerId,
  visaId,
  statusId
});

export const mapDispatchToProps = {
  onStatusChange: visaActions.changeStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForwardButton);
