import * as React from "react";
import { Row, Col, Select } from "antd";
import InputField from "../_common/InputField/InputField";
import { OptionProps } from "antd/lib/select";
import NewCustomer from "../request/NewCustomer";
import CustomerService, {
  CustomerNames
} from "../../helpers/model_services/CustomerService";

const customerService = new CustomerService();

export interface Props {
  setFieldValue(field: string, value: any): void;
  label: string;
  name: string;
}

let timeout: NodeJS.Timeout;

function fetch(value: string, callback: (data: CustomerNames[]) => void) {
  if (timeout) {
    clearTimeout(timeout);
  }

  function fake() {
    customerService.findNames(value).then(({ data }) => {
      callback(data);
    });
  }
  timeout = setTimeout(fake, 300);
}

const CustomerInput: React.FC<Props> = ({ setFieldValue, name, label }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<CustomerNames[]>([]);
  return (
    <Row>
      <Col span={20}>
        <InputField
          name={name}
          label={label}
          type="select"
          node={
            <Select<number>
              style={{ width: 200 }}
              autoFocus
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={value => {
                fetch(value, data => setData(data));
              }}
            >
              {data
                .sort((a, b) =>
                  a.lastName === b.lastName
                    ? a.name.localeCompare(b.name)
                    : a.lastName.localeCompare(b.lastName)
                )
                .map(({ name, lastName, id }) => (
                  <Select.Option key={id} value={id}>
                    {`${lastName} ${name}`}
                  </Select.Option>
                ))}
            </Select>
          }
        />
      </Col>
      <Col span={4}>
        <NewCustomer
          loading={loading}
          onSubmit={values =>
            new Promise<boolean>(async (res, rej) => {
              setLoading(true);
              try {
                const { data: newId } = await customerService.register(values);
                setFieldValue(name, newId);
                setData([
                  { id: newId, name: values.name, lastName: values.lastName }
                ]);
                res(true);
              } catch (error) {
                res(false);
              }
              setLoading(false);
            })
          }
        />
      </Col>
    </Row>
  );
};

export default CustomerInput;
