import * as React from "react";
import { Table, Button, Drawer, Modal } from "antd";
import { TableProps } from "antd/lib/table";
import { uniqueId } from "lodash";
import { InputConfig } from "../../../types";
import AntDForm from "../AntDForm/AntDForm";
import {
  inputPropsToColumns,
  getColumnsWithActions
} from "../../../helpers/columnPropsHelper";
import BaseClass from "../../../model/_BaseClass";
import MyCard from "../MyCard";

export interface Props<T extends BaseClass> {
  tableProps: TableProps<T>;
  title?: string;
  onSubmit: (values: T) => void;
  onNew: any;
  inputsConfig: InputConfig<T>[];
  initialValues: T;
  setSelected: React.Dispatch<React.SetStateAction<T | undefined>>;
  onDelete: (v: T) => void;
}

function AntDTableWithDrawerForm<T extends BaseClass>({
  title,
  onSubmit,
  onNew,
  inputsConfig,
  initialValues,
  tableProps,
  setSelected,
  onDelete
}: Props<T>) {
  const [drawerVisibility, setDrawerVisibility] = React.useState<boolean>(
    false
  );

  return (
    <MyCard
      style={{
        background: "#fff",
        marginTop: 84,
        marginLeft: 12,
        minHeight: 280,
        padding: 0
      }}
      headStyle={{ background: "#fff" }}
      title={title}
      extra={
        <div>
          <Button
            type="primary"
            onClick={() => {
              onNew();
              setDrawerVisibility(true);
            }}
            icon="plus"
          >
            New
          </Button>
          <Drawer
            title={title}
            width={500}
            onClose={() => {
              setDrawerVisibility(false);
              setSelected({ id: 0 } as T);
            }}
            visible={drawerVisibility}
            style={{
              overflow: "auto",
              height: "calc(100% - 108px)",
              paddingBottom: "108px"
            }}
          >
            <AntDForm
              onSubmit={onSubmit}
              initVals={initialValues}
              inputsConfig={inputsConfig}
            >
              <div
                style={{
                  left: 0,
                  bottom: 0,
                  width: "100%",
                  borderTop: "1px solid #e9e9e9",
                  padding: "10px 16px",
                  background: "#fff",
                  textAlign: "right"
                }}
              >
                <Button
                  onClick={() => setDrawerVisibility(false)}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => setDrawerVisibility(false)}
                >
                  Submit
                </Button>
              </div>
            </AntDForm>
          </Drawer>
        </div>
      }
    >
      <Table
        {...{
          ...tableProps,
          columns: getColumnsWithActions(
            inputPropsToColumns(inputsConfig),
            ({ edit }) => {
              if (edit) {
                edit();
              }
              setDrawerVisibility(true);
            },
            record => {
              onDelete(record);
            }
          ),
          size: "middle"
        }}
        rowKey={({ id }) => (id ? id.toString() : uniqueId("tableRowKey"))}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
      />
    </MyCard>
  );
}

export default AntDTableWithDrawerForm;
