import AbstractRestService from "./AbstractRestService";
import VisaRequestPurposes from "../../model/VisaRequestPurposes";

export default class VisaRequestPurposesService extends AbstractRestService<
  VisaRequestPurposes
> {
  constructor() {
    super("Codelists/VisaRequestPurposes");
  }
}
