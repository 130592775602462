import { RequestListState } from "../redux/request/requestListSlice";
import { configureStore } from "@redux-ts-starter-kit/core";
import { requestListReducer } from "../redux/request/requestListSlice";
import {
  RequestDetailState,
  requestReducer
} from "../redux/request/requestSlice";
import {
  travelerReducer,
  TravelerState
} from "../redux/traveler/travelerSlice";
import { userReducer, UserState } from "../redux/user/userSlice";
import throttle from "lodash/throttle";
import { VisaRequestState, visaReducer } from "../redux/visa/visaSlice";
import { combineReducers } from "redux";
import {
  TravelerNoteState,
  travelerNotesReducer
} from "../redux/traveler/travelerNotesSlice";
import {
  RequestNoteState,
  requestNoteReducer
} from "../redux/request/requestNoteSlice";
import { VisaNoteState, visaNoteReducer } from "../redux/visa/visaNoteSlice";
import { FinanceState, financeReducer } from "../redux/request/financeSlice";
import { VisaListState, visaListReducer } from "../redux/visa/visaListSlice";
import { BadgesState, badgesReducer } from "../redux/badges";

export interface AppState {
  requestList: RequestListState;
  requestDetail: RequestDetailState;
  requestNotes: RequestNoteState;
  finance: FinanceState;
  traveler: TravelerState;
  travelerNotes: TravelerNoteState;
  user: UserState;
  visa: VisaRequestState;
  visaNotes: VisaNoteState;
  visaList: VisaListState;
  badges: BadgesState;
}

export const loadState = () => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state: AppState) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch {
    // ignore write errors
  }
};
const store = configureStore({
  reducer: combineReducers({
    requestList: requestListReducer,
    requestNotes: requestNoteReducer,
    requestDetail: requestReducer,
    finance: financeReducer,
    traveler: travelerReducer,
    user: userReducer,
    visa: visaReducer,
    visaNotes: visaNoteReducer,
    travelerNotes: travelerNotesReducer,
    visaList: visaListReducer,
    badges: badgesReducer
  }),
  preloadedState: loadState()
});

store.subscribe(throttle(() => saveState(store.getState())));

export default store;
