import * as React from "react";
import Fee from "../../../model/Fee";
import { Table, Button, Drawer, Select, Form, Row } from "antd";
import { connect } from "react-redux";
import { AppState } from "../../../store/appStore";
import Traveler from "../../../model/Traveler";
import FeeService from "../../../helpers/model_services/FeeService";
import { Mode } from "../../../types";
import InputField from "../../_common/InputField/InputField";
import { requestActions } from "../../../redux/request/requestSlice";
import { Formik } from "formik";
import getName from "../../../helpers/MyFunctions";
import ActionButtons from "../../_common/ActionButtons";
import VisaRequestService, {
  ExtraFee,
  VisaRequestListItem
} from "../../../helpers/model_services/VisaRequestService";
import AddDiscount from "./AddDiscount";
import { TableRowSelection } from "antd/lib/table";

export interface Props {
  data: Fee[];
  visaRequests: VisaRequestListItem[];
  itemId: number;
  refresh: () => void;
  travelers: Traveler[];
  rowSelection: TableRowSelection<Fee>;
  selectedPrice: number;
}

const spans = {
  wrapperColSpan: 20,
  labelColSpan: 4
};

const feeService = new FeeService();
const visaService = new VisaRequestService();

const FeeTable: React.FC<Props> = ({
  data,
  visaRequests,
  itemId,
  refresh,
  travelers,
  rowSelection,
  selectedPrice
}) => {
  const initialVisaId = visaRequests.length === 0 ? 0 : visaRequests[0].id;
  const [action, setAction] = React.useState<Mode>("view");
  const [selected, setSelected] = React.useState<Fee>({
    itemId
  } as Fee);
  const [visaId, setVisaId] = React.useState<number>(initialVisaId);
  const [extraFees, setExtraFees] = React.useState<ExtraFee[]>([]);
  const [visible, setVisible] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (visaId !== 0) {
      visaService.getExtraFees(visaId).then(({ data }) => {
        setExtraFees(data);
      });
    }
  }, [visaId]);

  return (
    <React.Fragment>
      <Row>
        <Table<Fee>
          columns={[
            {
              dataIndex: "travelerId",
              title: "Client",
              render: id => {
                const index = travelers.findIndex(({ id: _id }) => id === _id);
                if (index === -1) {
                  return null;
                } else {
                  const { name, lastName } = travelers[index];
                  return getName(name, lastName);
                }
              }
            },
            {
              dataIndex: "visaRequestId",
              title: "Product ID"
            },
            {
              dataIndex: "feeType.name",
              title: "Product type"
            },
            {
              dataIndex: "netPrice",
              title: "Price",
              render: text => `${text} SAR`
            },
            {
              dataIndex: "sellPrice",
              title: "New price",
              render: (text: number) => (text !== 0 ? `${text} SAR` : null)
            },
            {
              title: "Actions",
              render: (text, record) => (
                <ActionButtons
                  onOpen={() => {
                    setAction("edit");
                    setSelected(record);
                    setVisible(true);
                  }}
                  openTooltip="Edit"
                  onDelete={() => {
                    feeService.delete(record).then(() => refresh());
                  }}
                />
              )
            }
          ]}
          dataSource={data}
          size="middle"
          pagination={false}
          rowKey={({ id }) => id.toString()}
          rowSelection={rowSelection}
        />
      </Row>
      <Row>
        <Button
          type="primary"
          className="green"
          style={{ margin: 12 }}
          size="small"
          onClick={() => {
            setVisible(true);
            setAction("new");
            setSelected({
              itemId,
              date: new Date(),
              visaRequestId: initialVisaId
            } as Fee);
          }}
        >
          + Extra service
        </Button>
        <AddDiscount />
        <Drawer
          title="Add extra services"
          width={500}
          onClose={() => setVisible(false)}
          visible={visible}
          style={{
            overflow: "auto",
            height: "calc(100% - 108px)",
            paddingBottom: "108px"
          }}
        >
          <Formik
            enableReinitialize
            initialValues={selected}
            onSubmit={async values => {
              try {
                switch (action) {
                  case "new":
                    await feeService.create(values as Fee);
                    break;
                  case "edit":
                    await feeService.update(values as Fee);
                    break;
                  default:
                    break;
                }
                refresh();
                setVisible(false);
              } catch (error) {}
            }}
            render={({ handleSubmit, setFieldValue }) => (
              <Form layout="horizontal" hideRequiredMark>
                <InputField
                  {...spans}
                  label="Product"
                  name="visaRequestId"
                  type="select"
                  node={
                    <Select<number>
                      onChange={e => {
                        setFieldValue("visaRequestId", e);
                        setVisaId(e);
                      }}
                    >
                      {visaRequests.map(({ id, travelerId }) => {
                        const i = travelers.findIndex(t => t.id === travelerId);
                        const t = travelers[i];
                        return (
                          <Select.Option key={id} value={id}>
                            {`Visa #${id} - ${getName(t.name, t.lastName)}`}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  }
                />
                <InputField
                  {...spans}
                  label="Service"
                  name="typeOfPaymentFeeId"
                  type="select"
                  node={
                    <Select<number>
                      onChange={val => {
                        const index = extraFees.findIndex(
                          e => e.typeOfPaymentFeeId === val
                        );
                        if (index !== -1) {
                          const fee = extraFees[index];
                          setFieldValue("typeOfPaymentFeeId", val);
                          setFieldValue("netPrice", fee.netPrice);
                          setFieldValue("sellPrice", fee.sellPrice);
                        }
                      }}
                    >
                      {extraFees.map(({ name, typeOfPaymentFeeId }) => (
                        <Select.Option
                          key={typeOfPaymentFeeId}
                          value={typeOfPaymentFeeId}
                        >
                          {name}
                        </Select.Option>
                      ))}
                    </Select>
                  }
                />
                <InputField label="Net price" name="netPrice" {...spans} />
                <InputField label="Sell price" name="sellPrice" {...spans} />
                <div
                  style={{
                    left: 0,
                    bottom: 0,
                    width: "100%",
                    borderTop: "1px solid #e9e9e9",
                    padding: "10px 16px",
                    background: "#fff",
                    textAlign: "right"
                  }}
                >
                  <Button
                    onClick={() => setVisible(false)}
                    style={{ marginRight: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button type="primary" onClick={() => handleSubmit()}>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          />
        </Drawer>
      </Row>
      <Row className="total-selected">TOTAL SELECTED: {selectedPrice} SAR</Row>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelers, itemId, visaRequest }
  },
  finance: {
    finance: { fees }
  }
}: AppState) => ({
  data: fees.filter(f => f.feeInvoceItem.length === 0),
  travelers,
  itemId: itemId.length === 0 ? 0 : itemId[0],
  visaRequests: visaRequest
});

const mapDispatchToProps = {
  refresh: requestActions.fetchRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeeTable);
