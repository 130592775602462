import * as React from "react";
import { Form, Input, Icon, Button } from "antd";
import Title from "antd/lib/typography/Title";
import PasswordRecovery from "./_common/PasswordRecovery/PasswordRecovery";
import { Formik, Field, FieldProps } from "formik";
import { Credentials, userActions } from "../redux/user/userSlice";
import { connect } from "react-redux";
import { Redirect, withRouter, RouteComponentProps } from "react-router-dom";
import { AppState } from "../store/appStore";

export interface Props extends RouteComponentProps {
  loggedIn: boolean;
  loading: boolean;
  onLogin: (values: Credentials) => Promise<boolean>;
}

const LoginForm: React.FC<Props> = ({
  onLogin,
  location,
  loading,
  loggedIn
}) => {
  const { from } = location.state || { from: { pathname: "/" } };
  return loggedIn ? (
    <Redirect to={from} />
  ) : (
    <Formik<Credentials>
      initialValues={{ email: "", password: "" }}
      onSubmit={values => {
        onLogin(values);
      }}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Title level={3}>Log in</Title>
          <Field
            name="email"
            render={({ field }: FieldProps<Credentials>) => {
              return (
                <Form.Item>
                  <Input
                    {...field}
                    autoFocus
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Email"
                    type="text"
                  />
                </Form.Item>
              );
            }}
          />
          <Field
            name="password"
            render={({ field }: FieldProps<Credentials>) => {
              return (
                <Form.Item>
                  <Input.Password
                    {...field}
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Password"
                  />
                </Form.Item>
              );
            }}
          />
          <PasswordRecovery />
          <Form.Item>
            <Button
              loading={loading}
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
            >
              {loading ? "Logging in" : "Log in"}
            </Button>
          </Form.Item>
        </Form>
      )}
    />
  );
};

const mapStateToProps = ({ user: { loading, loggedIn } }: AppState) => ({
  loading,
  loggedIn
});

const mapDispatchToProps = {
  onLogin: userActions.login
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginForm)
);
