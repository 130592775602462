import { createSlice } from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import User from "../../model/User";
import UserService from "../../helpers/model_services/UserService";
import axios from "axios";
import { push } from "react-router-redux";
import { ThunkResult } from "../../types";

export interface UserState {
  loading: boolean;
  user: User;
  error: string;
  loggedIn: boolean;
}

export interface UserActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: User;
  logout: never;
  login: never;
}

export interface Credentials {
  email: string;
  password: string;
}

export const userSlice = createSlice<UserActions, UserState, AppState>({
  slice: "user",
  initialState: {
    loading: false,
    user: {} as User,
    error: "Error!",
    loggedIn: false
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      user: payload,
      loading: false
    }),
    logout: state => {
      sessionStorage.clear();
      window.location.href = "/";
      return {
        ...state,
        user: {} as User,
        loggedIn: false
      };
    },
    login: state => ({
      ...state,
      loggedIn: true
    })
  }
});

const {
  fetchError,
  fetchStart,
  fetchSuccess,
  login: _login
} = userSlice.actions;

const userService = new UserService();

const getProfile = (): ThunkAction<
  void,
  AppState,
  null,
  AnyAction
> => async dispatch => {
  dispatch(fetchStart());
  userService
    .profile()
    .then(({ data }) => {
      dispatch(fetchSuccess(data));
    })
    .catch(error => {
      fetchError(error.response);
    });
};

const fetchUser = (
  userId?: number
): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    user: {
      user: { id: _id }
    }
  } = getState();
  const id = userId ? userId : _id;
  dispatch(fetchStart());
  userService
    .getById(id)
    .then(({ data }) => {
      dispatch(fetchSuccess(data));
    })
    .catch(error => {
      fetchError(error.response);
    });
};

const updateUser = (
  user: User
): ThunkAction<void, AppState, null, AnyAction> => async dispatch => {
  dispatch(fetchStart());
  userService
    .update(user)
    .then(() => {
      dispatch(fetchUser());
    })
    .catch(error => {
      fetchError(error.response);
    });
};

const login = (
  credentials: Credentials
): ThunkResult<Promise<boolean>> => async dispatch => {
  dispatch(fetchStart());
  try {
    const { status, data } = await userService.login(credentials);
    if (status === 200) {
      sessionStorage.setItem("token", data);
      /* tslint:disable:no-string-literal */
      axios.defaults.headers.common["Authorization"] = "Bearer " + data;
      dispatch(_login());
      dispatch(getProfile());
      dispatch(push("/requests/all"));
      return true;
    }
  } catch (error) {
    dispatch(fetchError(error.response));
    return false;
  }
  dispatch(fetchError(`Error!`));
  return false;
};

const { actions } = userSlice;

export const userActions = {
  ...actions,
  fetchUser,
  updateUser,
  getProfile,
  login
};
export const userReducer = userSlice.reducer;
