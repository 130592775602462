import * as React from "react";
import {
  Row,
  DatePicker,
  Select,
  Tooltip,
  Icon,
  Col,
  Collapse,
  Checkbox
} from "antd";
import useSelectVals from "../_hooks/useSelectVals";
import CountryService from "../../helpers/model_services/CountryService";
import MaritalStatusService from "../../helpers/model_services/MaritalStatusService";
import InputField from "../_common/InputField/InputField";
import { OptionProps } from "antd/lib/select";
import MyCard from "../_common/MyCard";
import AgeCategoryService from "../../helpers/model_services/AgeCategoryService";

export interface Props {
  recognizedData: string;
  disabled: boolean;
}

const PersonalInformation: React.FC<Props> = ({ recognizedData, disabled }) => {
  const { data: countries } = useSelectVals(new CountryService());
  const { data: maritalStatuses } = useSelectVals(new MaritalStatusService());
  const { data: ageCategories } = useSelectVals(new AgeCategoryService());

  return (
    <MyCard bordered={false}>
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.name"
            label="First name"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.lastName"
            label="Surname"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.surnameAtBirth"
            label={
              <span>
                Surname at birth&nbsp;
                <Tooltip title="Fill this field only if it's different from current surname">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>
            }
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.sex"
            label="Gender"
            type="select"
            node={
              <Select>
                <Select.Option key="Female" value="Female">
                  Female
                </Select.Option>
                <Select.Option key="Male" value="Male">
                  Male
                </Select.Option>
              </Select>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.dateOfBirth"
            label="Date of birth"
            node={<DatePicker />}
            type="date"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.placeOfBirth"
            label="Place of birth"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.countryOfBirthId"
            label="Country of birth"
            type="select"
            node={
              <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={(
                  inputValue: string,
                  option: React.ReactElement<OptionProps>
                ) =>
                  option.props
                    .children!.toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {countries.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.ageCategoryId"
            label="Age category"
            type="select"
            node={
              <Select>
                {ageCategories.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.nationalityId"
            label="Nationality"
            type="select"
            node={
              <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={(
                  inputValue: string,
                  option: React.ReactElement<OptionProps>
                ) =>
                  option.props
                    .children!.toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {countries.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}{" "}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.maritalStatusId"
            label="Marital status"
            type="select"
            node={
              <Select>
                {maritalStatuses.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.childLegalGuardian"
            label="Child legal guardian"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.isSaudi"
            label="Saudi"
            type="check"
            node={<Checkbox />}
          />
        </Col>
      </Row>
      {recognizedData !== null ? (
        <Row>
          <Collapse bordered={false}>
            <Collapse.Panel
              header="Recognized data"
              key="1"
              style={{ marginBottom: 12, border: 0 }}
            >
              <p>{recognizedData}</p>
            </Collapse.Panel>
          </Collapse>
        </Row>
      ) : null}
    </MyCard>
  );
};

export default PersonalInformation;
