import * as React from "react";
import { Row, DatePicker, Checkbox, Col, Icon, Tooltip, Collapse } from "antd";
import InputField from "../_common/InputField/InputField";
import { Mode } from "../../types";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import MyCard from "../_common/MyCard";

export interface Props {
  mode: Mode;
  recognizedData: string;
}

const PreviousVisa: React.SFC<Props> = ({ mode, recognizedData }) => {
  const disabled = mode === "view";
  return (
    <MyCard
      title="Previous visa"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        <Col span={6}>
          <InputField
            label="Previous Visa"
            type="check"
            name="visa.hasPastVisa"
            node={<Checkbox />}
            disabled={disabled}
            wrapperColSpan={8}
            labelColSpan={16}
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.previousVisaValidFrom"
            label="Valid from"
            node={<DatePicker />}
            type="date"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.previousVisaValidTo"
            label="Valid until"
            node={<DatePicker />}
            type="date"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <InputField
            label="Previous fingerprints"
            type="check"
            name="visa.hasPreviousFingerPrint"
            node={<Checkbox />}
            disabled={disabled}
            wrapperColSpan={8}
            labelColSpan={16}
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.previousFingerprintDate"
            label="Date"
            node={<DatePicker />}
            type="date"
          />
        </Col>
      </Row>
      {recognizedData !== null ? (
        <Row>
          <Collapse bordered={false}>
            <Collapse.Panel
              header="Recognized data"
              key="1"
              style={{ marginBottom: 12, border: 0 }}
            >
              <p>{recognizedData}</p>
            </Collapse.Panel>
          </Collapse>
        </Row>
      ) : null}
    </MyCard>
  );
};

const mapStateToProps = ({ visa: { mode } }: AppState) => ({
  mode
});

export default connect(mapStateToProps)(PreviousVisa);
