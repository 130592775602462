import * as React from "react";
import UserService from "../../../helpers/model_services/UserService";
import RoleService from "../../../helpers/model_services/RoleService";
import {
  Table,
  Button,
  Drawer,
  Select,
  Input,
  message,
  Popconfirm
} from "antd";
import User from "../../../model/User";
import MyCard from "../../_common/MyCard";
import { Mode } from "../../../types";
import MyForm from "../../_common/CardTable/MyForm";
import InputField from "../../_common/InputField/InputField";
import ActionButtons from "../../_common/ActionButtons";
import { useSearchProps } from "../../_hooks/useColumnProps";
import useSelectVals from "../../_hooks/useSelectVals";

export interface Props {}

const userService = new UserService();

const UserPage: React.FC<Props> = () => {
  const [selected, setSelected] = React.useState<User>({} as User);
  const [action, setAction] = React.useState<Mode>("view");
  const [users, setUsers] = React.useState<User[]>([]);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const { data: roles } = useSelectVals(new RoleService());
  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await userService.getAll();
      setUsers(data);
    } catch (error) {}
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  const onClose = () => {
    setVisible(false);
    setAction("view");
    setSelected({ id: 0 } as User);
  };
  return (
    <MyCard
      style={{ marginTop: 84 }}
      headStyle={{ background: "#fff" }}
      extra={
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
            setAction("new");
            setSelected({} as User);
          }}
        >
          + New
        </Button>
      }
    >
      <Table<User>
        loading={loading}
        rowKey={({ id }) => id.toString()}
        dataSource={users}
        size="middle"
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
        columns={[
          {
            title: "Username",
            ...useSearchProps("username")
          },
          {
            title: "Name",
            ...useSearchProps("name")
          },
          {
            title: "Email address",
            ...useSearchProps("email")
          },
          {
            title: "Phone",
            ...useSearchProps("phone")
          },
          {
            title: "Role",
            ...useSearchProps("roleId", {
              select: { values: roles }
            })
          },
          {
            title: "Reset password",
            key: "reset_password",
            render: (val, { email }) => (
              <Popconfirm
                placement="top"
                title="Are you sure?"
                onConfirm={e => {
                  if (e) {
                    userService.sendResetLink(email).then(({ status }) => {
                      if (status === 200) {
                        message.success(`Reset link sent to ${email}`);
                      }
                    });
                    e.stopPropagation();
                  }
                }}
                onCancel={e => {
                  if (e) {
                    e.stopPropagation();
                  }
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">Reset</Button>
              </Popconfirm>
            )
          },
          {
            title: "Action",
            key: "x",
            render: (text, record, index) => {
              const { email, id, name, phone, roleId, username } = record;
              return (
                <ActionButtons
                  onOpen={() => {
                    setAction("edit");
                    setSelected({
                      email,
                      id,
                      name,
                      phone,
                      roleId,
                      username
                    } as User);
                    setVisible(true);
                  }}
                  openTooltip="Edit"
                  onDelete={() => {
                    userService
                      .delete({
                        email,
                        id,
                        name,
                        phone,
                        roleId,
                        username
                      } as User)
                      .then(() => fetchData());
                  }}
                />
              );
            }
          }
        ]}
      />
      <Drawer title="Form" width={500} onClose={onClose} visible={visible}>
        <MyForm
          onSubmit={async values => {
            try {
              switch (action) {
                case "new":
                  await userService.create(values as User);
                  message.success("User was succesfully created.");
                  break;
                case "edit":
                  await userService.update(values as User);
                  message.success("User was succesfully updated.");
                  break;
                default:
                  break;
              }

              fetchData();
              onClose();
            } catch (error) {}
          }}
          initVals={selected}
        >
          <InputField label="Username" name="username" />
          <InputField label="Name" name="name" />
          <InputField label="Email" name="email" />
          <InputField label="Phone" name="phone" />
          <InputField
            label="Role"
            name="roleId"
            type="select"
            node={
              <Select>
                {roles.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
          {action === "new" && (
            <InputField
              label="Password"
              name="password"
              node={<Input.Password />}
            />
          )}
          <div
            style={{
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right"
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </MyForm>
      </Drawer>
    </MyCard>
  );
};

export default UserPage;
