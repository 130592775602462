import * as React from "react";
import { Row, Col, Icon, Button, Tooltip, Form, message } from "antd";
import { Typography } from "antd";
import "../../css/ModalHeader.css";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import getName from "../../helpers/MyFunctions";
import Traveler from "../../model/Traveler";
import { Formik } from "formik";
import { travelerActions } from "../../redux/traveler/travelerSlice";
import { Mode } from "../../types";

const { Title, Text } = Typography;

export interface Props {
  traveler: Traveler;
  onSubmit: (values: Traveler) => Promise<boolean>;
  loading: boolean;
  mode: Mode;
  changeMode: (payload: Mode) => void;
}

const TravelerHeader: React.FC<Props> = ({
  traveler,
  onSubmit,
  loading,
  mode,
  changeMode
}) => {
  const [edit, setEdit] = React.useState<boolean>(false);
  const { name, lastName } = traveler;
  const travelerName = getName(name, lastName);
  const isEditMode = mode === "edit";
  return (
    <Formik<Traveler>
      enableReinitialize
      initialValues={traveler}
      onSubmit={values =>
        onSubmit(values).then(res => {
          if (res) {
            message.success("Tags were updated.");
            setEdit(false);
          }
        })
      }
      onReset={(values, { setValues }) => setValues(traveler)}
      render={({
        handleSubmit,
        values: { isVIP, haveMobileApp },
        setFieldValue,
        resetForm
      }) => {
        return (
          <Form layout="horizontal" hideRequiredMark onSubmit={handleSubmit}>
            <Row className="dunia-header" gutter={24}>
              <Col span={12}>
                <Title className="dunia-header" level={3}>
                  Traveler: {travelerName}
                </Title>
              </Col>

              <Col span={4} style={{ verticalAlign: "middle" }}>
                {isVIP || edit ? (
                  <React.Fragment>
                    <i
                      className="fa fa-gem"
                      style={{
                        fontSize: 36,
                        marginRight: 12,
                        cursor: "pointer",
                        color: isVIP ? "#fcad1c" : "#BCBCBC"
                      }}
                      onClick={() => {
                        if (edit) {
                          setFieldValue("isVIP", !isVIP);
                        }
                      }}
                    />
                    <Text className="dunia-header">VIP Client</Text>
                  </React.Fragment>
                ) : null}
              </Col>

              <Col span={4} style={{ verticalAlign: "middle" }}>
                {haveMobileApp || edit ? (
                  <React.Fragment>
                    <i
                      className="fas fa-mobile"
                      style={{
                        fontSize: 36,
                        marginRight: 12,
                        cursor: "pointer",
                        color: haveMobileApp ? "#fcad1c" : "#BCBCBC"
                      }}
                      onClick={() => {
                        if (edit) {
                          setFieldValue("haveMobileApp", !haveMobileApp);
                        }
                      }}
                    />
                    <Text className="dunia-header">Has mob. app.</Text>
                  </React.Fragment>
                ) : null}
              </Col>

              <Col
                span={4}
                style={{
                  textAlign: "right",
                  paddingRight: 24
                }}
              >
                {!isEditMode ? (
                  <Tooltip placement="top" title="Switch to edit mode">
                    <Button
                      type="link"
                      onClick={() => changeMode("edit")}
                      style={{ padding: 0, marginLeft: 12, marginRight: 16 }}
                    >
                      <Icon
                        type="edit"
                        theme="twoTone"
                        twoToneColor="#fcad1c"
                        style={{ fontSize: 24 }}
                      />
                    </Button>
                  </Tooltip>
                ) : edit ? (
                  <span>
                    <Tooltip placement="top" title="Save">
                      <Button type="link" htmlType="submit" loading={loading}>
                        <Icon type="save" style={{ fontSize: 28 }} />
                      </Button>
                    </Tooltip>
                    <Tooltip placement="top" title="Cancel">
                      <Button
                        htmlType="reset"
                        type="link"
                        onClick={() => {
                          resetForm();
                          setEdit(false);
                        }}
                        style={{ padding: 0, marginRight: 16 }}
                      >
                        <Icon
                          type="close-circle"
                          theme="twoTone"
                          twoToneColor="#fcad1c"
                          style={{ fontSize: 28 }}
                        />
                      </Button>
                    </Tooltip>
                  </span>
                ) : (
                  <Button
                    className="orange"
                    onClick={() => {
                      setEdit(true);
                    }}
                    type="primary"
                  >
                    Edit tags
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        );
      }}
    />
  );
};

const mapStateToProps = ({
  traveler: { traveler, loading, mode }
}: AppState) => ({
  traveler,
  loading,
  mode
});

const mapDispatchToProps = {
  onSubmit: travelerActions.updateTraveler,
  changeMode: travelerActions.changeMode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TravelerHeader);
