import * as React from "react";
import AbstractRestService from "../../../helpers/model_services/AbstractRestService";
import { InputConfig } from "../../../types";
import useData from "../../_hooks/useData";
import { TableProps } from "antd/lib/table";
import AntDTableWithDrawerForm from "../AntDTableWithDrawerForm/AntDTableWithDrawerForm";
import { handleSubmit } from "../../../helpers/formHelper";
import BaseClass from "../../../model/_BaseClass";
import { Spin } from "antd";

export interface Props<T extends BaseClass> {
  restService: AbstractRestService<T>;
  tableProps?: TableProps<T>;
  title?: string;
  inputsConfig: InputConfig<T>[];
}

function BasicPage<T extends BaseClass>({
  restService,
  title,
  inputsConfig,
  tableProps
}: Props<T>) {
  const {
    data,
    setData,
    action,
    setAction,
    setSelected,
    selected,
    handleAction
  } = useData(tableProps ? tableProps.dataSource : undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const refresh = async () => {
    setLoading(true);
    const { data } = await restService.getAll();
    setData(data);
    setLoading(false);
  };
  React.useEffect(() => {
    refresh();
  }, []);
  const onSubmit = handleSubmit(restService, action, setAction);
  const onDelete = handleSubmit(restService, "delete", setAction);
  return (
    <Spin spinning={loading}>
      <AntDTableWithDrawerForm
        inputsConfig={inputsConfig}
        tableProps={{ ...tableProps, dataSource: data }}
        onSubmit={v =>
          onSubmit(v).then(res => {
            if (res) {
              refresh();
            }
          })
        }
        title={title}
        initialValues={selected ? selected : ({} as T)}
        onNew={handleAction("new")}
        onDelete={v =>
          onDelete(v).then(res => {
            if (res) {
              refresh();
            }
          })
        }
        setSelected={setSelected}
      />
    </Spin>
  );
}

export default BasicPage;
