import { InputConfig } from "../types";
import BaseClass from "../model/_BaseClass";
import { ColumnProps } from "antd/lib/table";
import * as React from "react";
import ActionButtons from "../components/_common/ActionButtons";

export function inputPropsToColumns<T extends BaseClass>(
  inputsConfig: InputConfig<T>[]
): ColumnProps<T>[] {
  return inputsConfig.map(
    ({
      label,
      inputProps,
      selectOptions,
      selectProps,
      renderArray,
      dataIndex,
      ...rest
    }) => {
      return { title: label, key: dataIndex, dataIndex, ...rest };
    }
  );
}

export function getColumnsWithActions<T extends BaseClass>(
  columns: ColumnProps<T>[],
  onEdit: (record: T) => void,
  onDelete: (record: T) => void
): ColumnProps<T>[] {
  columns.push({
    title: "Action",
    key: "x",
    align: "right",
    width: 70,
    dataIndex: "",
    render: record => (
      <ActionButtons
        onOpen={() => onEdit(record)}
        openTooltip="Edit"
        onDelete={() => onDelete(record)}
      />
    )
  });
  return columns;
}
