import * as React from "react";
import { Row, Button } from "antd";
import { Mode } from "../../types";
import MyCard from "../_common/MyCard";
import PersonalInformation from "./PersonalInformation";
import TravelerContacts from "./TravelerContacts";
import PassportInfo from "./PassportInfo";
import Iqama from "./Iqama";
import Employments from "./Employments";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import { travelerActions } from "../../redux/traveler/travelerSlice";
import Family from "./Family";
export interface Props {
  mode: Mode;
  loading: boolean;
  recognizedData: string;
  travelerDetail?: boolean;
  identityRecognizedData: string;
}

const TravelerForm: React.SFC<Props> = ({
  recognizedData,
  mode,
  loading,
  travelerDetail = false,
  identityRecognizedData
}) => {
  const disabled = mode === "view";
  return (
    <React.Fragment>
      <Row>
        <MyCard
          title="Personal information"
          extra={
            mode !== "view" && (
              <Button
                className="dark"
                loading={loading}
                type="primary"
                form={!travelerDetail ? "visa-form" : undefined}
                htmlType="submit"
              >
                {loading ? "Saving" : "Save"}
              </Button>
            )
          }
        >
          <Row gutter={12}>
            <PersonalInformation
              recognizedData={recognizedData}
              disabled={disabled}
            />
          </Row>
          <Row gutter={12}>
            <TravelerContacts disabled={disabled} />
          </Row>
          <Row gutter={12}>
            <PassportInfo disabled={disabled} />
          </Row>
          <Row gutter={12}>
            <Iqama
              disabled={disabled}
              identityRecognizedData={identityRecognizedData}
            />
          </Row>
          <Row gutter={12}>
            <Employments disabled={disabled} />
          </Row>
          <Row gutter={12}>
            <Family disabled={disabled} />
          </Row>
        </MyCard>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = ({ traveler: { mode, loading } }: AppState) => ({
  mode,
  loading
});

const mapDispatchToProps = {
  onSubmit: travelerActions.submitTraveler
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TravelerForm);
