import { createSlice } from "@redux-ts-starter-kit/core";
import Request from "../../model/Request";
import { AppState } from "../../store/appStore";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import RequestService, {
  RequestDetail,
  Finance
} from "../../helpers/model_services/RequestService";
import { Mode } from "../../types";
import UserService from "../../helpers/model_services/UserService";
import { requestNoteActions } from "./requestNoteSlice";
import { travelerActions } from "../traveler/travelerSlice";
import { financeActions, financeSlice } from "./financeSlice";
import VisaRequestService, {
  VisaOptions
} from "../../helpers/model_services/VisaRequestService";
import DiscountService from "../../helpers/model_services/DiscountService";
import CountryService from "../../helpers/model_services/CountryService";

const requestService = new RequestService();
const discountService = new DiscountService();

export interface RequestDetailState {
  loading: boolean;
  request: RequestDetail;
  error: string;
  visible: boolean;
  mode: Mode;
  notesMode: Mode;
  formOptions: VisaOptions;
}

export interface RequestDetailActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: RequestDetail;
  setVisible: boolean;
  setNotesMode: Mode;
  setFormOptions: VisaOptions;
}

export const requestSlice = createSlice<
  RequestDetailActions,
  RequestDetailState,
  AppState
>({
  slice: "requestDetail",
  initialState: {
    loading: false,
    request: ({
      travelers: [],
      visaRequest: [],
      travelersDocuments: [],
      fee: [],
      requestNote: [],
      itemId: [],
      payments: []
    } as unknown) as RequestDetail,
    error: "Error!",
    visible: false,
    mode: "edit",
    notesMode: "view",
    formOptions: { porpouses: [], entryCount: [], servicePlace: [], tier: [] }
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      request: payload,
      loading: false,
      visible: true
    }),
    setVisible: (state, payload) => ({
      ...state,
      visible: payload
    }),
    setNotesMode: (state, payload) => ({
      ...state,
      notesMode: payload
    }),
    setFormOptions: (state, payload) => ({
      ...state,
      formOptions: payload
    })
  }
});

const {
  fetchError,
  fetchStart,
  fetchSuccess,
  setFormOptions
} = requestSlice.actions;

const userService = new UserService();
const visaService = new VisaRequestService();
const countryService = new CountryService();

const fetchRequest = (
  reqId?: number
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    requestDetail: {
      request: { id: _id }
    },
    visa: { inVisaSection },
    traveler: {
      traveler: { id: travelerId }
    }
  } = getState();

  const id = reqId ? reqId : _id;
  try {
    // START
    dispatch(fetchStart());
    dispatch(requestNoteActions.fetchStart());

    const { data } = await requestService.getDetail(id);

    if (data.itemId.length > 0) {
      const itemId = data.itemId[0];

      // VISA FORM OPTIONS
      const { data: options } = await visaService.getOptions(itemId);
      dispatch(setFormOptions(options));
    }

    const { requestedCountryId, confirmedCountryId } = data;

    if (confirmedCountryId !== null) {
      const { data: confirmedCountry } = await countryService.getById(
        confirmedCountryId
      );
      data.confirmedCountry = confirmedCountry;
    }
    if (requestedCountryId !== null) {
      const { data: requestedCountry } = await countryService.getById(
        requestedCountryId
      );
      data.requestedCountry = requestedCountry;
    }

    if (inVisaSection) {
      dispatch(fetchSuccess(data));
      return false;
    }

    // FINANCE
    dispatch(
      financeActions.fetchFinance(
        id,
        data.itemId.length > 0 ? data.itemId[0] : undefined
      )
    );

    data.travelers = data.travelers.map(
      ({ travelersDocuments, ...rest }, index) => ({
        ...{
          travelersDocuments: travelersDocuments.filter(
            d => d.documentUrl !== null
          ),
          ...rest
        },
        alphaId: String.fromCharCode(65 + index)
      })
    );
    data.visaRequest = data.visaRequest.map(vr => {
      const { travelerId } = vr;
      const traveler = data.travelers.find(({ id }) => id === travelerId);
      return {
        ...vr,
        alphaId: traveler && traveler.alphaId ? traveler.alphaId : ""
      };
    });
    data.travelersDocuments = data.travelersDocuments
      .map(doc => {
        const { travelersId } = doc;
        const traveler = data.travelers.find(({ id }) => id === travelersId);
        return {
          ...doc,
          alphaId: traveler && traveler.alphaId ? traveler.alphaId : ""
        };
      })
      .filter(d => d.documentUrl !== null);
    // SUCCESS
    dispatch(requestNoteActions.fetchSuccess(data.requestNote));

    const index = data.travelers.findIndex(
      traveler => traveler.id === travelerId
    );
    if (index !== -1) {
      dispatch(travelerActions.fetchSuccess(data.travelers[index]));
    }
    dispatch(fetchSuccess(data));
    return true;
  } catch (error) {
    dispatch(fetchError(error));
    return false;
  }
};

const updateRequest = (
  req: Request
): ThunkAction<
  Promise<boolean>,
  AppState,
  null,
  AnyAction
> => async dispatch => {
  try {
    dispatch(fetchStart());
    const { status } = await requestService.update(req);
    if (status === 200) {
      await dispatch(fetchRequest());
      return true;
    }
  } catch (error) {
    dispatch(fetchError(error));
  }
  return false;
};

const updateBusinessPartner = (
  values: RequestDetail
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const { data } = await requestService.getById(
      getState().requestDetail.request.id
    );
    const {
      businnessPartner,
      businnessPartnerBy,
      businnessPartnerMail,
      businnessPartnerTel
    } = values;
    const resp = await dispatch(
      updateRequest({
        ...data,
        businnessPartner,
        businnessPartnerBy,
        businnessPartnerMail,
        businnessPartnerTel
      })
    );
    return resp;
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
  return false;
};

const assignSales = (): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const { data: user } = await userService.profile();
    const { data: newReq } = await requestService.getById(
      getState().requestDetail.request.id
    );
    newReq.responsibleSalesId = user.id;
    dispatch(updateRequest(newReq));
  } catch (error) {}
};

const changeStatus = (
  statusId: number
): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const { data: newReq } = await requestService.getById(
      getState().requestDetail.request.id
    );
    newReq.requestStatusesId = statusId;
    dispatch(updateRequest(newReq));
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
};

const changeCustomer = (
  customerId: number
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    dispatch(fetchStart());
    const { data: oldReq } = await requestService.getById(
      getState().requestDetail.request.id
    );
    const newReq = { ...oldReq, customerId };
    const { status } = await requestService.update(newReq);
    if (status === 200) {
      await dispatch(fetchRequest());
      return true;
    }
  } catch (error) {
    dispatch(fetchError(error));
  }
  return false;
};

const addDiscount = (
  discountId: number
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    requestDetail: {
      request: { id: reqId }
    }
  } = getState();
  try {
    dispatch(fetchStart());
    await discountService.addDiscount(reqId, discountId);

    await dispatch(fetchRequest());
    return true;
  } catch (error) {
    dispatch(fetchError(error));
  }
  return false;
};

const { actions } = requestSlice;

export const requestActions = {
  ...actions,
  fetchRequest,
  assignSales,
  changeStatus,
  updateBusinessPartner,
  changeCustomer,
  addDiscount
};
export const requestReducer = requestSlice.reducer;
