import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Modal, Spin } from "antd";
import RequestDetail from "../../request/RequestDetail";
import NewRequestModal from "../../request/NewRequest";
import { AppState } from "../../../store/appStore";
import { requestListActions } from "../../../redux/request/requestListSlice";
import { connect } from "react-redux";
import { requestActions } from "../../../redux/request/requestSlice";
import MyCard from "../../_common/MyCard";
import RequestTable from "../../request/RequestTable";
import RequestHeader from "../../request/RequestHeader";

export interface Props
  extends RouteComponentProps<{ type: string; filter: string; id?: string }> {
  fetchList: () => void;
  setStatus: (statusId: number) => void;
  setVisible: (v: boolean) => void;
  visible: boolean;
  setBc: (v: boolean) => void;
  loading: boolean;
  openRequest: (id: number) => void;
}

export interface State {}

class RequestPage extends React.Component<Props> {
  componentDidMount() {
    this.updateValues(true);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const {
        match: {
          params: { filter: curFil }
        }
      } = this.props;
      const {
        match: {
          params: { filter: prevFil }
        }
      } = prevProps;
      this.updateValues(curFil !== prevFil);
    }
  }

  updateValues = (changedFilter: boolean, close: boolean = false) => {
    const {
      match: {
        params: { filter, id, type }
      },
      setBc,
      setStatus,
      fetchList,
      openRequest
    } = this.props;
    setBc(type === "business-cases");
    if (changedFilter) {
      const statusId = getStatusId(filter);
      if (statusId !== -1 && !close) {
        setStatus(statusId);
      }

      fetchList();
    }

    if (id && !close) {
      openRequest(Number(id));
    }
  };

  render() {
    const {
      fetchList,
      setVisible,
      visible,
      loading,
      history,
      match: {
        params: { type, filter }
      },
      location: { pathname }
    } = this.props;

    return (
      <MyCard
        headStyle={{ backgroundColor: "transparent" }}
        style={{
          marginTop: 84
        }}
        extra={
          <NewRequestModal
            isBc={type === "business-cases"}
            fetchRequestList={fetchList}
          />
        }
      >
        <RequestTable />
        <Spin spinning={loading}>
          <Modal
            visible={visible}
            width="98%"
            title={<RequestHeader />}
            onCancel={() => {
              setVisible(false);
              history.push(`/${pathname.split("/")[1]}/${filter}`);
              this.updateValues(true, true);
            }}
            style={{ top: 10 }}
            footer={null}
          >
            <RequestDetail />
          </Modal>
        </Spin>
      </MyCard>
    );
  }
}

const getStatusId = (filter: string) => {
  switch (filter) {
    case "new":
      return 10;
    case "unfinished":
      return 20;
    case "completed":
      return 30;
    case "open":
      return 40;
    case "close-audit":
      return 120;
    case "closed":
      return 130;
    case "not-acceptable":
      return 140;
    case "cancelled":
      return 150;
    case "all":
      return 0;
    default:
      return -1;
  }
};

const mapStateToProps = ({
  requestDetail: { visible, loading }
}: AppState) => ({
  visible,
  loading
});

const mapDispatchToProps = {
  fetchList: requestListActions.fetchRequestList,
  setStatus: requestListActions.setStatus,
  setBc: requestListActions.setBc,
  setVisible: requestActions.setVisible,
  openRequest: requestActions.fetchRequest
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RequestPage)
);
