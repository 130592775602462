import * as React from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "../../../../store/appStore";

export interface Props extends RouteProps {
  component: React.ComponentType;
  loggedIn: boolean;
  isAdmin: boolean;
  adminRoute?: boolean;
  exact?: boolean;
}

const ProtectedRoute: React.SFC<Props> = ({
  component: Component,
  adminRoute,
  path,
  isAdmin,
  loggedIn,
  exact,
  ...rest
}) => {
  return (
    <Route
      path={path}
      render={props =>
        (adminRoute && isAdmin) || loggedIn ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
      exact={exact ? exact : true}
    />
  );
};

const mapStateToProps = ({
  user: {
    user: { isSuAdmin: isAdmin },
    loggedIn
  }
}: AppState) => ({
  isAdmin,
  loggedIn
});

export default connect(
  mapStateToProps,
  null,
  null,
  { pure: false }
)(ProtectedRoute);
