import * as React from "react";
import {
  Button,
  Modal,
  Form,
  Row,
  Select,
  DatePicker,
  Col,
  Tooltip,
  Icon,
  message
} from "antd";
import { Formik } from "formik";
import InputField from "../_common/InputField/InputField";
import { OptionProps } from "antd/lib/select";
import useSelectVals from "../_hooks/useSelectVals";
import CountryService from "../../helpers/model_services/CountryService";
import VisaRequestPurposesService from "../../helpers/model_services/VisaRequestPurposeService";
import VisaEntryCountService from "../../helpers/model_services/VisaEntryCountService";
import ServicePlaceService from "../../helpers/model_services/ServicePlaceService";
import RequestService, {
  BulkEditModel
} from "../../helpers/model_services/RequestService";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import { requestActions } from "../../redux/request/requestSlice";

export interface Props {
  reqId: number;
  refresh: () => void;
}

const requestService = new RequestService();

const QuickEdit: React.FC<Props> = ({ reqId, refresh }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const { data: countries } = useSelectVals(new CountryService());
  const { data: visaPurpose } = useSelectVals(new VisaRequestPurposesService());
  const { data: visaEntry } = useSelectVals(new VisaEntryCountService());
  const { data: servicePlaces } = useSelectVals(new ServicePlaceService());
  return (
    <React.Fragment>
      <Button
        className="dark"
        type="primary"
        size="small"
        style={{ marginRight: 12 }}
        onClick={() => setVisible(true)}
      >
        Quick edit
      </Button>
      <Formik<BulkEditModel>
        enableReinitialize
        initialValues={{} as BulkEditModel}
        onSubmit={values =>
          requestService.bulkEdit(reqId, values).then(() => {
            message.success("Records were edited.");
            refresh();
          })
        }
        render={({ handleSubmit, resetForm }) => {
          return (
            <Modal
              title={
                <span>
                  Quick edit&nbsp;
                  <Tooltip title="Only filled values will be updated.">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
              visible={visible}
              onCancel={() => {
                setVisible(false);
                resetForm();
              }}
              onOk={() => handleSubmit()}
            >
              <Form
                layout="horizontal"
                hideRequiredMark
                onSubmit={handleSubmit}
              >
                <Row>
                  <InputField
                    name="requestedCountryId"
                    label="Requested country"
                    type="select"
                    node={
                      <Select
                        style={{ width: 200 }}
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={(
                          inputValue: string,
                          option: React.ReactElement<OptionProps>
                        ) =>
                          option.props
                            .children!.toString()
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      >
                        {countries.map(({ name, id }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                  />
                </Row>
                <Row>
                  <InputField
                    name="confirmedCountryId"
                    label="Confirmed country"
                    type="select"
                    node={
                      <Select
                        style={{ width: 200 }}
                        showSearch
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={(
                          inputValue: string,
                          option: React.ReactElement<OptionProps>
                        ) =>
                          option.props
                            .children!.toString()
                            .toLowerCase()
                            .includes(inputValue.toLowerCase())
                        }
                      >
                        {countries.map(({ name, id }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                  />
                </Row>
                <Row>
                  <InputField
                    name="visaRequestPurposesId"
                    label="Purpose"
                    type="select"
                    node={
                      <Select style={{ width: 200 }}>
                        {visaPurpose.map(({ name, id }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                  />
                </Row>
                <Row>
                  <InputField
                    name="visaEntryCountId"
                    label="Entries"
                    type="select"
                    node={
                      <Select style={{ width: 200 }}>
                        {visaEntry.map(({ name, id }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                  />
                </Row>
                <Row>
                  <Col span={12}>
                    <InputField
                      name="confirmedTripDateFrom"
                      label="Confirmed date from"
                      node={<DatePicker />}
                      type="date"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      name="confirmedTripDateTo"
                      label="Confirmed date to"
                      node={<DatePicker />}
                      type="date"
                    />
                  </Col>
                </Row>
                <Row>
                  <InputField
                    name="servicePlaceId"
                    label="Service place"
                    type="select"
                    node={
                      <Select style={{ width: 200 }}>
                        {servicePlaces.map(({ name, id }) => (
                          <Select.Option key={id} value={id}>
                            {name}
                          </Select.Option>
                        ))}
                      </Select>
                    }
                  />
                </Row>
                <Row>
                  <InputField
                    name="servicePlaceAppointmentDateTime"
                    label="Appointment date"
                    node={<DatePicker />}
                    type="date"
                  />
                </Row>
              </Form>
            </Modal>
          );
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { id }
  }
}: AppState) => ({
  reqId: id
});

const mapDispatchToProps = {
  refresh: requestActions.fetchRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuickEdit);
