import * as React from "react";
import { ExtraFee } from "../../helpers/model_services/VisaRequestService";
import MyCard from "../_common/MyCard";
import { Row, Col, Checkbox } from "antd";
import InputField from "../_common/InputField/InputField";

export interface Props {
  extraFees: ExtraFee[];
  disabled: boolean;
}

const ExtraFees: React.SFC<Props> = ({ extraFees, disabled }) => {
  return (
    <MyCard
      title="Extra services"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        {extraFees.map((val, index) => (
          <Col key={val.typeOfPaymentFeeId} span={6}>
            <InputField
              label={val.name}
              type="check"
              name={`visa.extraFees.${index}.isChecked`}
              node={<Checkbox />}
              disabled={disabled}
              wrapperColSpan={8}
              labelColSpan={16}
            />
          </Col>
        ))}
      </Row>
    </MyCard>
  );
};

export default ExtraFees;
