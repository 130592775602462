import { createSlice } from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { Mode } from "../../types";
import VisaRequestService, {
  VisaList,
  VisaRequestListItem
} from "../../helpers/model_services/VisaRequestService";
import VisaStatus from "../../model/VisaStatus";
import {
  PaginationConfig,
  SorterResult,
  TableCurrentDataSource
} from "antd/lib/table";

const visaService = new VisaRequestService();

export interface VisaListState {
  statusList: VisaStatus[];
  status: number;
  pagination: PaginationConfig;
  visaList: VisaList;
  loading: boolean;
  error: string;
  mode: Mode;
  filters: Record<keyof VisaRequestListItem, string[]>;
  sorter: SorterResult<VisaRequestListItem>;
}

export interface VisaListActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: VisaList;
  setStatus: number;
  setTableValues: {
    pagination: PaginationConfig;
    filters: Record<keyof VisaRequestListItem, string[]>;
    sorter: SorterResult<VisaRequestListItem>;
  };
}

export const visaListSlice = createSlice<
  VisaListActions,
  VisaListState,
  AppState
>({
  slice: "visaList",
  initialState: {
    statusList: [],
    status: 0,
    visaList: {
      count: 0,
      visaRequestExtract: []
    },
    pagination: {
      current: 1,
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "30"],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    },
    loading: false,
    error: "Error!",
    mode: "edit",
    filters: {} as Record<keyof VisaRequestListItem, string[]>,
    sorter: {} as SorterResult<VisaRequestListItem>
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      loading: false,
      visaList: payload,
      pagination: { ...state.pagination, total: payload.count }
    }),
    setStatus: (state, payload) => ({
      ...state,
      loading: false,
      status: payload,
      pagination: { ...state.pagination, current: 1 }
    }),
    setTableValues: (state, payload) => ({
      ...state,
      loading: false,
      ...payload
    })
  }
});

const {
  fetchError,
  fetchStart,
  fetchSuccess,
  setTableValues
} = visaListSlice.actions;

const fetchVisaList = (): ThunkAction<
  void,
  AppState,
  null,
  AnyAction
> => async (dispatch, getState) => {
  const {
    visaList: {
      status: statusId,
      pagination: { current, pageSize },
      filters,
      sorter
    }
  } = getState();
  dispatch(fetchStart());
  /* for (const key in filters) {
    if (filters.hasOwnProperty(key)) {
      console.log(key, filters[key]);
    }
  } */
  try {
    const { data } = await visaService.getList({
      statusId,
      curPage: current ? current : 1,
      perPage: pageSize ? pageSize : 10,
      sortField: sorter.field,
      sortOrder: sorter.order
        ? sorter.order === "ascend"
          ? "asc"
          : "desc"
        : undefined,
      ...filters
    });
    dispatch(fetchSuccess(data));
  } catch (error) {
    dispatch(fetchError(error.response));
  }
};

const onTableChange = (
  pagination: PaginationConfig,
  filters: Record<keyof VisaRequestListItem, string[]>,
  sorter: SorterResult<VisaRequestListItem>,
  extra: TableCurrentDataSource<VisaRequestListItem>
): ThunkAction<void, AppState, null, AnyAction> => async dispatch => {
  dispatch(setTableValues({ pagination, filters, sorter }));
  dispatch(fetchVisaList());
};

const { actions } = visaListSlice;

export const visaListActions = {
  ...actions,
  fetchVisaList,
  onTableChange
};
export const visaListReducer = visaListSlice.reducer;
