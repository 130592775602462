import * as React from "react";
import { Button, Modal, Row, Select, Form, Col, message, Spin } from "antd";
import CustomerService, {
  CustomerNames
} from "../../helpers/model_services/CustomerService";
import RequestService, {
  ShortRequest
} from "../../helpers/model_services/RequestService";
import RequestItemService from "../../helpers/model_services/RequestItemService";
import RequestItem from "../../model/RequestItem";
import { Formik, Field, FieldProps } from "formik";
import InputField from "../_common/InputField/InputField";
import CountryService from "../../helpers/model_services/CountryService";
import useSelectVals from "../_hooks/useSelectVals";
import { OptionProps } from "antd/lib/select";
import { AppState } from "../../store/appStore";
import getName from "../../helpers/MyFunctions";
import { connect } from "react-redux";
import User from "../../model/User";
import NewCustomer from "./NewCustomer";
import CustomerInput from "../customer/CustomerInput";

interface Props {
  fetchRequestList: () => void;
  user: User;
  isBc: boolean;
}
const requestService = new RequestService();
const requestItemService = new RequestItemService();

const NewRequest: React.FC<Props> = ({ fetchRequestList, user, isBc }) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const { data: countries } = useSelectVals(new CountryService());

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
        }}
      >
        New
      </Button>
      <Formik<{
        customerId: number;
        requestedCountryId: number;
        confirmedCountryId: number;
      }>
        enableReinitialize
        initialValues={{} as any}
        onSubmit={(
          { customerId, confirmedCountryId, requestedCountryId },
          { resetForm }
        ) => {
          const { name, lastName, email, phone } = user;
          const req = {
            customerId,
            requestStatusesId: isBc ? 40 : 10,
            businnessPartner: "Dunia Alrehlat",
            businnessPartnerBy: getName(name, lastName),
            businnessPartnerTel: phone,
            businnessPartnerMail: email
          } as ShortRequest;

          requestService.post(req).then(({ data }) => {
            const reqItem = {} as RequestItem;
            reqItem.requestId = data;
            reqItem.requestItemTypeId = 1;
            reqItem.requestedCountryId = requestedCountryId;
            reqItem.confirmedCountryId = confirmedCountryId;
            requestItemService.create(reqItem).then(() => {
              message.success(
                `${
                  isBc ? "Business case" : "Request"
                } was succesfully created. ID: ${data}`
              );
              fetchRequestList();
            });
          });
          setVisible(false);
          resetForm();
        }}
        render={({ handleSubmit, setFieldValue, resetForm }) => {
          return (
            <Modal
              title={`New ${isBc ? "business case" : "request"}`}
              visible={visible}
              okButtonProps={{ onClick: () => handleSubmit() }}
              onCancel={() => {
                setVisible(false);
                resetForm();
              }}
            >
              <CustomerInput
                setFieldValue={setFieldValue}
                name="customerId"
                label="Customer"
              />
              <Row>
                <Col span={20}>
                  <Field
                    name="requestedCountryId"
                    render={({ field }: FieldProps) => (
                      <Form.Item
                        labelCol={{ span: 10 }}
                        wrapperCol={{ span: 14 }}
                        label="Country"
                        style={{ marginBottom: 12 }}
                      >
                        <Select
                          style={{ width: 200 }}
                          showSearch
                          defaultActiveFirstOption={false}
                          showArrow={false}
                          filterOption={(
                            inputValue: string,
                            option: React.ReactElement<OptionProps>
                          ) =>
                            option.props
                              .children!.toString()
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          }
                          onChange={(value: any) => {
                            setFieldValue(field.name, value);
                            setFieldValue("confirmedCountryId", value);
                          }}
                        >
                          {countries.map(({ name, id }) => (
                            <Select.Option key={id} value={id}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  />
                </Col>
              </Row>
            </Modal>
          );
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ user: { user } }: AppState) => ({
  user
});

export default connect(mapStateToProps)(NewRequest);
