import * as React from "react";
import CallMeService from "../../../helpers/model_services/CallMeService";
import CallMeStatusService from "../../../helpers/model_services/CallMeStatusService";
import CallMeStatus from "../../../model/CallMeStatus";
import CallMe from "../../../model/CallMe";
import BasicPage from "../../_common/BasicPage/BasicPage";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CustomerService, {
  CustomerNames
} from "../../../helpers/model_services/CustomerService";
import { isNullOrUndefined } from "util";
import { useSearchProps } from "../../_hooks/useColumnProps";
import useSelectVals from "../../_hooks/useSelectVals";
import { Spin } from "antd";
import getName from "../../../helpers/MyFunctions";

export interface Props extends RouteComponentProps {}

export interface State {
  callMeStatusVals: CallMeStatus[];
  customerVals: { id: number; name: string; lastName: string }[];
  filteredData: CallMe[];
  data: CallMe[];
}

const callMeService = new CallMeService();
const callMeStatusService = new CallMeStatusService();
const customerService = new CustomerService();

const ClientsPage: React.FC<Props> = ({ location: { pathname } }) => {
  const { data: callMeStatusVals } = useSelectVals(callMeStatusService);
  const [customerVals, setCustomerVals] = React.useState<CustomerNames[]>([]);
  const [filteredData, setFilteredData] = React.useState<CallMe[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  React.useEffect(() => {
    customerService.getNames().then(({ data }) => {
      setCustomerVals(data);
    });
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [pathname]);

  const fetchData = async () => {
    setLoading(true);
    const { data } = await callMeService.getAll();
    const pathArray = pathname.split("/");

    if (pathArray.length >= 3) {
      let _callMeStatusId: number;
      switch (pathArray[2]) {
        case "new":
          _callMeStatusId = 1;
          break;
        case "pending":
          _callMeStatusId = 2;
          break;
        case "finished":
          _callMeStatusId = 3;
          break;
        case "cancelled":
          _callMeStatusId = 4;
          break;
        case "all-clients":
          _callMeStatusId = 0;
          break;
        default:
          _callMeStatusId = -1;
          break;
      }

      setFilteredData(
        _callMeStatusId > 0
          ? data.filter(({ callMeStatusId }) => {
              return callMeStatusId === _callMeStatusId;
            })
          : _callMeStatusId === 0
          ? data
          : []
      );
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <BasicPage<CallMe>
        restService={new CallMeService()}
        tableProps={{ dataSource: filteredData }}
        inputsConfig={[
          {
            label: "Status",
            inputProps: { type: "select" },
            selectOptions: callMeStatusVals.map(({ name, id }) => {
              return { displayValue: name, dataValue: id };
            }),
            ...useSearchProps("callMeStatusId", {
              select: { values: callMeStatusVals }
            })
          },
          {
            label: "Customer",
            inputProps: { type: "select" },
            selectOptions: customerVals.map(({ name, lastName, id }) => {
              return { displayValue: `${name} ${lastName}`, dataValue: id };
            }),
            ...useSearchProps("customerId"),
            render: (text, { customer }) =>
              isNullOrUndefined(customer)
                ? ""
                : getName(customer.name, customer.lastName),
            onFilter: (value, { customer }) =>
              isNullOrUndefined(customer)
                ? false
                : getName(customer.name, customer.lastName)
                    .toLowerCase()
                    .includes(value.toLowerCase()),
            sorter: ({ customer: customerA }, { customer: customerB }) => {
              if (isNullOrUndefined(customerA)) {
                return -1;
              } else if (isNullOrUndefined(customerB)) {
                return 1;
              } else {
                return getName(
                  customerA.name,
                  customerA.lastName
                ).localeCompare(getName(customerB.name, customerB.lastName));
              }
            }
          },
          {
            label: "Phone number",
            ...useSearchProps("callMePhone")
          },
          {
            label: "Text",
            ...useSearchProps("text")
          }
        ]}
      />
    </Spin>
  );
};

export default withRouter(ClientsPage);
