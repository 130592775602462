export default function getVisaStatusParam(id: number): string {
  switch (id) {
    case 10:
      return "sales";
    case 20:
      return "pending";
    case 30:
      return "data-entry";
    case 40:
      return "apply-online";
    case 50:
      return "ready-to-submit";
    case 60:
      return "submitted";
    case 70:
      return "ready-to-collect";
    case 80:
      return "collected";
    case 0:
      return "all";
    default:
      return "all";
  }
}
