import * as React from "react";
import MyCard from "../_common/MyCard";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import { Row, Checkbox, Col, message, Tooltip, Button } from "antd";
import { visaActions } from "../../redux/visa/visaSlice";
import DocumentsCheckListItem from "../../model/DocumentsCheckListItem";
import { Mode } from "../../types";

interface Props {
  checklist: DocumentsCheckListItem[];
  updateChecklist: (check: DocumentsCheckListItem) => Promise<boolean>;
  regenerateChecklist: () => void;
  mode: Mode;
}

interface Checklist {
  checkName: string;
  checkOrder: number;
  checkList: DocumentsCheckListItem[];
}

const TravelerCheckList: React.FC<Props> = ({
  checklist,
  updateChecklist,
  regenerateChecklist,
  mode
}) => {
  /* const isAdmin = roleName === "Admin";
  const filteredChecklist =
    showAll || isAdmin
      ? checklist
      : checklist.filter(({ responsibilityPerson }) =>
          responsibilityPerson.includes(roleName)
        ); */
  const disabled = mode === "view";
  const [loading, setLoading] = React.useState<boolean>(false);
  const _checklist: Checklist[] = [];
  checklist.forEach(c => {
    const index = _checklist.findIndex(fc => fc.checkName === c.checkName);
    if (index === -1) {
      _checklist.push({
        checkName: c.checkName,
        checkOrder: c.checkOrder,
        checkList: [c]
      });
    } else {
      _checklist[index].checkList.push(c);
    }
  });

  return (
    <MyCard
      title="Workflow & checklist"
      extra={
        <Button
          disabled={disabled}
          loading={loading}
          className="dark"
          type="primary"
          onClick={() => {
            setLoading(true);
            regenerateChecklist();
            setLoading(false);
          }}
        >
          Regenerate
        </Button>
      }
    >
      <Row>
        {_checklist
          .sort((a, b) => a.checkOrder - b.checkOrder)
          .map(({ checkName, checkList }) => (
            <Col key={checkName} span={6}>
              <MyCard title={checkName}>
                {checkList
                  .sort((a, b) => a.order - b.order)
                  .map(checkItem => {
                    const {
                      documentChecked,
                      requestedDocuments,
                      id,
                      checklistTravelerDocumentType
                    } = checkItem;
                    return (
                      <Row key={id + requestedDocuments + documentChecked}>
                        <Tooltip placement="right" title={checkItem.note}>
                          <Checkbox
                            disabled={disabled}
                            defaultChecked={documentChecked}
                            onChange={async ({ target: { checked } }) => {
                              try {
                                const res = await updateChecklist({
                                  ...checkItem,
                                  documentChecked: checked
                                });
                                if (res) {
                                  message.success("Saved.", 1);
                                }
                              } catch (error) {}
                            }}
                          >
                            {checklistTravelerDocumentType === null ||
                            checklistTravelerDocumentType.iconName === "" ||
                            checklistTravelerDocumentType.iconName === null ? (
                              <i className="fa fa-fw">&nbsp;</i>
                            ) : (
                              <i
                                className={`${checklistTravelerDocumentType.iconName} fa-fw`}
                              ></i>
                            )}
                            {requestedDocuments}
                          </Checkbox>
                        </Tooltip>
                      </Row>
                    );
                  })}
              </MyCard>
            </Col>
          ))}
      </Row>
    </MyCard>
  );
};

const mapStateToProps = ({ visa: { checklist, mode } }: AppState) => ({
  checklist,
  mode
});

const mapDispatchToProps = {
  updateChecklist: visaActions.updateChecklist,
  regenerateChecklist: visaActions.regenerateChecklist
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(TravelerCheckList);
