import AbstractRestService from "./AbstractRestService";
import VisaRequest from "../../model/VisaRequest";
import { AxiosPromise } from "axios";
import ServicePlace from "../../model/ServicePlace";
import VisaEntryCount from "../../model/VisaEntryCount";
import $ from "jquery";

export interface VisaRequestListItem {
  alphaId: string;
  id: number;
  traveler: string;
  travelerId: number;
  purpose: string;
  dateFrom: Date;
  dateTo: Date;
  price: number;
  servicePlaceAppointmentDateTime: Date;
  status: string;
  statusId: number;
  workFlowId: number;
  rowcount: number;
  countryName: string;
  regionName: string;
  payStatus: string;
  [key: string]: string | number | Date;
}

export interface VisaList {
  count: number;
  visaRequestExtract: VisaRequestListItem[];
}

export interface VisaOptions {
  porpouses: {
    countryId: number;
    purposeId: number;
    purposeName: string;
  }[];
  servicePlace: ServicePlace[];
  entryCount: VisaEntryCount[];
  tier: {
    countryId: number;
    porpuseTypeId: number;
    tier: string;
  }[];
}

export interface ExtraFee {
  typeOfPaymentFeeId: number;
  visaRequestId: number;
  name: string;
  netPrice: number;
  sellPrice: number;
  feeId: number;
  isChecked: boolean;
}

export interface VisaListProps {
  statusId: number;
  perPage: number;
  curPage: number;
  sortField?: string;
  sortOrder?: "asc" | "desc";
  requeststatuses?: string[];
  id?: string[];
  traveler?: string[];
  purpose?: string[];
  datefrom?: string[];
  dateto?: string[];
  price?: string[];
  serviceplaceappointmentdatetime?: string[];
}

export default class VisaRequestService extends AbstractRestService<
  VisaRequest
> {
  constructor() {
    super("Codelists/VisaRequest");
  }

  getList({
    perPage,
    curPage,
    ...rest
  }: VisaListProps): AxiosPromise<VisaList> {
    return this._http.get(`${this.apiUrl}extractpagination`, {
      params: {
        results: perPage,
        page: curPage,
        ...rest
      },
      paramsSerializer: params => $.param(params, true)
    });
  }

  generateDocuments(
    travelerId: number,
    creator: string,
    type?: number,
    ids?: number[]
  ): AxiosPromise {
    return this._http.put(
      `${this.apiUrl}${travelerId}/travelerdocument`,
      null,
      {
        params: type ? { creator, type, ids } : { creator },
        paramsSerializer: params => $.param(params, true)
      }
    );
  }

  getReqId(visaId: number): AxiosPromise<number> {
    return this._http.get(`${this.apiUrl}${visaId}/requestid`);
  }

  swap(t1: number, t2: number) {
    return this._http.put(`${this.apiUrl}change`, null, {
      params: { travelerA: t1, travelerB: t2 }
    });
  }

  getOptions(reqItemId: number): AxiosPromise<VisaOptions> {
    return this._http.get(`${this.apiUrl}${reqItemId}/countrypriceall_v1`);
  }

  getWorkflowId(visaId: number): AxiosPromise<number> {
    return this._http.get(`${this.apiUrl}${visaId}/workflowid`);
  }

  getExtraFees(visaId: number): AxiosPromise<ExtraFee[]> {
    return this._http.get(`${this.apiUrl}${visaId}/getextrafee`);
  }

  setExtraFees(extraFees: ExtraFee[]): AxiosPromise<ExtraFee[]> {
    return this._http.put(`${this.apiUrl}setextrafee`, extraFees);
  }

  manageInsurance(visaRequestId: number, isCancelled: boolean) {
    return this._http.post(`${this.apiUrl}insurance`, null, {
      params: { visaRequestId, isCancelled }
    });
  }

  downloadInsurance(
    visaId: number,
    type: number,
    creator: string
  ): AxiosPromise<number> {
    return this._http.put(`${this.apiUrl}${visaId}/insurancedocument`, null, {
      params: {
        type,
        creator
      }
    });
  }
}
