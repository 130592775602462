import AbstractRestService from "./AbstractRestService";
import CallMeStatus from "../../model/CallMeStatus";

export default class CallMeStatusService extends AbstractRestService<
  CallMeStatus
> {
  constructor() {
    super("Codelists/CallMeStatus");
  }
}
