import * as React from "react";
import DocumentsTypesService from "../../../helpers/model_services/DocumentsTypesService";
import BasicPage from "../../_common/BasicPage/BasicPage";
import { useSearchProps } from "../../_hooks/useColumnProps";

export interface Props {}

const DocumentsTypesPage: React.SFC<Props> = () => {
  return (
    <BasicPage
      restService={new DocumentsTypesService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        }
      ]}
    />
  );
};

export default DocumentsTypesPage;
