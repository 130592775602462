import * as React from "react";
import { Row, Form, Select, Checkbox, Col } from "antd";
import { Field, FieldProps } from "formik";
import VisaRequest from "../../model/VisaRequest";
import InputField from "../_common/InputField/InputField";
import { Mode } from "../../types";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import MyCard from "../_common/MyCard";
import Traveler from "../../model/Traveler";
import getName from "../../helpers/MyFunctions";

const supportSponsor = [
  "Cash",
  "Accommodation provided",
  "All expenses covered during the stay",
  "Prepaid transport",
  "Other"
];
const supportApplicant = [
  "Cash",
  "Traveller’s cheques",
  "Credit card",
  "Prepaid accommodation",
  "Prepaid transport",
  "Other"
];

export interface Props {
  mode: Mode;
  travelers: Traveler[];
}

const Sponsor: React.FC<Props> = ({ mode, travelers }) => {
  const disabled = mode === "view";
  const [disabledPayer, setDisabledPayer] = React.useState<boolean>(true);
  const [coveredBySponsor, setCoveredBySponsor] = React.useState<boolean>(
    false
  );
  const [meansOfSupport, setMeansOfSupport] = React.useState<string[]>([]);
  React.useEffect(() => {
    setMeansOfSupport(coveredBySponsor ? supportSponsor : supportApplicant);
  }, [coveredBySponsor]);
  return (
    <MyCard
      title="Sponsor"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        <Col span={6}>
          <Field
            name="isCoveredBySponzorHelper"
            render={({
              form: {
                values: { visa, traveler },
                setValues
              }
            }: FieldProps<{ visa: VisaRequest; traveler: Traveler }>) => (
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                label="Covered by"
                style={{ marginBottom: 12 }}
              >
                <Select
                  onChange={(value: number) => {
                    const isSponsor = value === -1;
                    setCoveredBySponsor(isSponsor);
                    setDisabledPayer(!isSponsor);
                    if (isSponsor) {
                      setValues({
                        traveler,
                        visa: {
                          ...visa,
                          isCoveredBySponzor: isSponsor,
                          paysId: 0,
                          paysLastName: "",
                          paysName: ""
                        }
                      });
                    } else {
                      const index = travelers.findIndex(t => t.id === value);
                      const selectedTraveler = travelers[index];
                      const { name, lastName } = selectedTraveler;
                      setValues({
                        traveler,
                        visa: {
                          ...visa,
                          isCoveredBySponzor: isSponsor,
                          paysId: value,
                          paysLastName: lastName,
                          paysName: name
                        }
                      });
                    }
                  }}
                  disabled={disabled}
                >
                  {[
                    ...travelers.map(t => ({
                      value: t.id,
                      name: getName(t.name, t.lastName)
                    })),
                    { name: "Somebody else", value: -1 }
                  ].map(v => (
                    <Select.Option key={v.value} value={v.value}>
                      {v.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.costByMeansOfSupport"
            label="Means of support"
            type="select"
            node={
              <Select>
                {meansOfSupport.map(value => (
                  <Select.Option key={value} value={value}>
                    {value}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabledPayer}
            name="visa.paysName"
            label="Payer name"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabledPayer}
            name="visa.paysLastName"
            label="Payer last name"
          />
        </Col>
      </Row>
    </MyCard>
  );
};

const mapStateToProps = ({
  visa: { mode },
  requestDetail: {
    request: { travelers }
  }
}: AppState) => ({
  mode,
  travelers
});

export default connect(mapStateToProps)(Sponsor);
