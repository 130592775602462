import * as React from "react";
import CountryService from "../../../helpers/model_services/CountryService";
import ServicePlaceTypeService from "../../../helpers/model_services/ServicePlaceTypeService";
import ServicePlaceType from "../../../model/ServicePlaceType";
import RegionService from "../../../helpers/model_services/RegionService";
import Region from "../../../model/Region";
import BasicPage from "../../_common/BasicPage/BasicPage";
import Country from "../../../model/Country";
import { useSearchProps, useIntervalProps } from "../../_hooks/useColumnProps";
import useSelectVals from "../../_hooks/useSelectVals";

export interface Props {}

export interface State {
  regionVals: Region[];
  servicePlaceTypeVals: ServicePlaceType[];
}

const CountryPage: React.FC<Props> = () => {
  const { data: regionVals } = useSelectVals(new RegionService());
  const { data: servicePlaceTypeVals } = useSelectVals(
    new ServicePlaceTypeService()
  );

  return (
    <BasicPage<Country>
      restService={new CountryService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        },
        {
          label: "Alpha-2",
          ...useSearchProps("alpha2")
        },
        {
          label: "Alpha-3",
          ...useSearchProps("alpha3")
        },
        {
          label: "Number code",
          inputProps: { type: "number" },
          ...useIntervalProps("numCode")
        },
        {
          label: "Fingerprint days",
          inputProps: { type: "number" },
          ...useIntervalProps("fingerPrintDays")
        },
        {
          label: "Service place",
          inputProps: { type: "select" },
          selectOptions: servicePlaceTypeVals.map(({ name, id }) => {
            return { displayValue: name, dataValue: id };
          }),
          ...useSearchProps("servicePlaceTypeId", {
            select: { values: servicePlaceTypeVals }
          })
        },
        {
          label: "Region",
          inputProps: { type: "select" },
          selectOptions: regionVals.map(({ name, id }) => {
            return { displayValue: name, dataValue: id };
          }),
          ...useSearchProps("regionId", {
            select: { values: regionVals }
          })
        },
        {
          label: "Family members",
          inputProps: { type: "switch" },
          ...useSearchProps("requestFamilyMembers", { isBoolean: true })
        },
        {
          label: "Deactivated",
          inputProps: { type: "switch" },
          ...useSearchProps("isDeactivated", { isBoolean: true })
        }
      ]}
    />
  );
};

export default CountryPage;
