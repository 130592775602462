import * as React from "react";
import { Row, DatePicker, Checkbox, Col } from "antd";
import InputField from "../_common/InputField/InputField";
import { Mode } from "../../types";
import MyCard from "../_common/MyCard";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";

export interface Props {
  mode: Mode;
}

const FinalCountry: React.SFC<Props> = ({ mode }) => {
  const disabled = mode === "view";
  return (
    <MyCard
      title="Final country"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        <Col span={6}>
          <InputField
            label="Permission"
            type="check"
            name="visa.permitForFinalCountry"
            node={<Checkbox />}
            disabled={disabled}
          />
        </Col>
        <Col span={6}>
          <InputField
            label="Issued by"
            name="visa.permitForFinalCountryBy"
            disabled={disabled}
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.permitForFinalCountryFrom"
            label="From"
            node={<DatePicker />}
            type="date"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.permitForFinalCountryTo"
            label="Until"
            node={<DatePicker />}
            type="date"
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.intendedStayOfArrival"
            label="Stay of arrival"
            node={<DatePicker />}
            type="date"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.intendedStayOfDeparture"
            label="Stay of departure"
            node={<DatePicker />}
            type="date"
          />
        </Col>
      </Row>
    </MyCard>
  );
};

const mapStateToProps = ({ visa: { mode } }: AppState) => ({
  mode
});

export default connect(mapStateToProps)(FinalCountry);
