import { createSlice } from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import RequestService, {
  RequestListItem,
  RequestList
} from "../../helpers/model_services/RequestService";
import { Mode } from "../../types";
import {
  SorterResult,
  PaginationConfig,
  TableCurrentDataSource
} from "antd/lib/table";

const requestService = new RequestService();

export interface RequestListState {
  requestList: RequestList;
  loading: boolean;
  error: string;
  mode: Mode;
  pagination: PaginationConfig;
  filters: Record<keyof RequestListItem, string[]>;
  sorter: SorterResult<RequestListItem>;
  isBc: boolean;
  status: number;
}

export interface RequestListActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: RequestList;
  setStatus: number;
  setTableValues: {
    pagination: PaginationConfig;
    filters: Record<keyof RequestListItem, string[]>;
    sorter: SorterResult<RequestListItem>;
  };
  setBc: boolean;
}

export const requestListSlice = createSlice<
  RequestListActions,
  RequestListState,
  AppState
>({
  slice: "requestList",
  initialState: {
    requestList: { count: 0, requestModel: [] },
    loading: false,
    isBc: false,
    status: 0,
    error: "Error!",
    mode: "edit",
    pagination: {
      current: 1,
      defaultPageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ["10", "20", "30"],
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    },
    filters: {} as Record<keyof RequestListItem, string[]>,
    sorter: {} as SorterResult<RequestListItem>
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      loading: false,
      requestList: payload,
      pagination: { ...state.pagination, total: payload.count }
    }),
    setStatus: (state, payload) => ({
      ...state,
      loading: false,
      status: payload,
      pagination: { ...state.pagination, current: 1 }
    }),
    setTableValues: (state, payload) => ({
      ...state,
      loading: false,
      ...payload
    }),
    setBc: (state, payload) => ({
      ...state,
      loading: false,
      isBc: payload
    })
  }
});

const {
  fetchError,
  fetchStart,
  fetchSuccess,
  setTableValues
} = requestListSlice.actions;

const fetchRequestList = (): ThunkAction<
  void,
  AppState,
  null,
  AnyAction
> => async (dispatch, getState) => {
  dispatch(fetchStart());
  try {
    const {
      requestList: {
        pagination: { current, pageSize },
        filters,
        sorter,
        status,
        isBc
      }
    } = getState();
    const { data } = await requestService.getPagination({
      isBc,
      statusId: status,
      curPage: current ? current : 1,
      perPage: pageSize ? pageSize : 10,
      sortField: sorter.field,
      sortOrder: sorter.order
        ? sorter.order === "ascend"
          ? "asc"
          : "desc"
        : undefined,
      ...filters
    });
    dispatch(fetchSuccess(data));
  } catch (error) {
    dispatch(fetchError(error));
  }
};

const onTableChange = (
  pagination: PaginationConfig,
  filters: Record<keyof RequestListItem, string[]>,
  sorter: SorterResult<RequestListItem>,
  extra: TableCurrentDataSource<RequestListItem>
): ThunkAction<void, AppState, null, AnyAction> => async dispatch => {
  dispatch(setTableValues({ pagination, filters, sorter }));
  dispatch(fetchRequestList());
};

const { actions } = requestListSlice;

export const requestListActions = {
  ...actions,
  fetchRequestList,
  onTableChange
};
export const requestListReducer = requestListSlice.reducer;
