import AbstractRestService from "./AbstractRestService";
import MaritalStatus from "../../model/MaritalStatus";

export default class MaritalStatusService extends AbstractRestService<
  MaritalStatus
> {
  constructor() {
    super("Codelists/MaritalStatus");
  }
}
