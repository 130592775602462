import * as React from "react";
import BasicPage from "../../_common/BasicPage/BasicPage";
import VisaStatusService from "../../../helpers/model_services/VisaStatusService";
import { useSearchProps } from "../../_hooks/useColumnProps";
import VisaStatus from "../../../model/VisaStatus";

export interface Props {}

const VisaStatusPage: React.SFC<Props> = () => {
  return (
    <BasicPage<VisaStatus>
      restService={new VisaStatusService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        }
      ]}
    />
  );
};

export default VisaStatusPage;
