import React from "react";
import { FieldProps } from "formik";
import {
  Form,
  Input,
  Select,
  DatePicker,
  TimePicker,
  InputNumber,
  Switch
} from "antd";
import { InputProps } from "antd/lib/input";
import { SelectProps } from "antd/lib/select";
import { InputNumberProps } from "antd/lib/input-number";
import { TimePickerProps } from "antd/lib/time-picker";
import { DatePickerProps } from "antd/lib/date-picker/interface";
import { SelectPair } from "../../../types";
import BaseClass from "../../../model/_BaseClass";
import moment from "moment/moment.js";
import TextArea from "antd/lib/input/TextArea";

interface Props<T> {
  label?: string;
  selectOptions?: SelectPair[];
  fieldProps: FieldProps<T>;
  inputProps?: InputProps;
  selectProps?: SelectProps;
  inputNumberProps?: InputNumberProps;
  timePickerProps?: TimePickerProps;
  datePickerProps?: DatePickerProps;
}

function AntDInputField<T extends BaseClass>({
  label,
  fieldProps: {
    field,
    form: { setFieldValue }
  },
  selectOptions,
  inputProps,
  selectProps,
  inputNumberProps,
  timePickerProps,
  datePickerProps
}: Props<T>) {
  var finalInput: JSX.Element = <Input {...field} {...inputProps} />;
  if (inputProps) {
    switch (inputProps.type) {
      case "select":
        finalInput = (
          <Select
            {...field}
            {...selectProps}
            onChange={value => {
              setFieldValue(field.name, value);
            }}
          >
            {selectOptions &&
              selectOptions.map(({ displayValue, dataValue }) => (
                <Select.Option key={field.name} value={dataValue}>
                  {displayValue}
                </Select.Option>
              ))}
          </Select>
        );
        break;
      case "password":
        finalInput = <Input.Password {...field} {...inputProps} />;
        break;
      case "date":
        finalInput = (
          <DatePicker
            format="D.M.YYYY"
            {...field}
            {...datePickerProps}
            onChange={value => {
              setFieldValue(field.name, value);
            }}
            value={moment(field.value)}
          />
        );
        break;
      case "datetime":
        finalInput = (
          <DatePicker
            showTime
            {...datePickerProps}
            {...field}
            onChange={value => {
              setFieldValue(field.name, value);
            }}
          />
        );
        break;
      case "time":
        finalInput = (
          <TimePicker
            {...field}
            {...timePickerProps}
            onChange={value => {
              setFieldValue(field.name, value);
            }}
          />
        );
        break;
      case "number":
        finalInput = (
          <InputNumber
            {...field}
            {...inputNumberProps}
            onChange={value => {
              setFieldValue(field.name, value);
            }}
          />
        );
        break;
      case "switch":
        finalInput = (
          <Switch
            {...field}
            onChange={value => {
              setFieldValue(field.name, value);
            }}
            defaultChecked={field.value}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        );
        break;
      case "textarea":
        finalInput = (
          <TextArea
            {...field}
            onChange={value => {
              setFieldValue(field.name, value.currentTarget.value);
            }}
            autoSize
          />
        );
        break;
      default:
        break;
    }
  }
  return (
    <Form.Item labelCol={{ span: 8 }} wrapperCol={{ span: 14 }} label={label}>
      {finalInput}
    </Form.Item>
  );
}
export default AntDInputField;
