import React from "react";
import { FieldProps, Field } from "formik";
import { Form, Input } from "antd";
import BaseClass from "../../../model/_BaseClass";
import moment from "moment/moment.js";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RadioChangeEvent } from "antd/lib/radio";

interface Props {
  label?: React.ReactNode;
  name: string;
  node?: JSX.Element;
  disabled?: boolean;
  type?: string;
  labelColSpan?: number;
  wrapperColSpan?: number;
}

const InputField: React.SFC<Props> = ({
  label,
  name,
  node: Node = <Input />,
  disabled = false,
  type = "input",
  labelColSpan = 10,
  wrapperColSpan = 14
}) => {
  return (
    <Field
      name={name}
      render={(fieldProps: FieldProps<BaseClass>) => {
        const {
          field,
          form: { setFieldValue }
        } = fieldProps;
        let defaultProps: any = {
          name: field.name,
          value: field.value,
          disabled
        };
        let newProps: any = {};
        const { onChange, ...rest } = Node.props;
        switch (type.toLowerCase()) {
          case "select":
            newProps = {
              ...defaultProps,
              onChange: (value: any) => {
                setFieldValue(field.name, value);
              },
              ...Node.props
            };
            break;
          case "switch":
            newProps = {
              ...defaultProps,
              onChange: (value: any) => {
                setFieldValue(field.name, value);
              },
              defaultChecked: field.value,
              checkedChildren: "Yes",
              unCheckedChildren: "No",
              ...Node.props
            };
            break;
          case "date":
            newProps = {
              ...defaultProps,
              onChange: (value: any) => {
                setFieldValue(field.name, moment.utc(value));
              },
              value:
                field.value === null || field.value === undefined
                  ? undefined
                  : moment.utc(field.value),
              format: "D.M.YYYY",
              ...rest
            };
            break;
          case "datetime":
            newProps = {
              ...defaultProps,
              onChange: (value: any) => {
                setFieldValue(field.name, moment(value));
              },
              value:
                field.value === null || field.value === undefined
                  ? undefined
                  : moment(moment.utc(field.value).toDate()),
              showTime: true,
              format: "D.M.YYYY HH:mm",
              ...rest
            };
            break;
          case "number":
            newProps = {
              ...defaultProps,
              onChange: (value: number) => {
                setFieldValue(field.name, value);
              },
              ...Node.props
            };
            break;
          case "check":
            newProps = {
              ...defaultProps,
              onChange: (e: CheckboxChangeEvent) => {
                setFieldValue(field.name, e.target.checked);
              },
              checked: field.value,
              ...Node.props
            };
            break;

          case "radio":
            newProps = {
              ...defaultProps,
              onChange: (e: RadioChangeEvent) => {
                setFieldValue(field.name, e.target.checked);
              },
              ...Node.props
            };
            break;
          default:
            newProps = {
              ...field,
              disabled,
              ...Node.props
            };
            break;
        }

        return (
          <Form.Item
            labelCol={{ span: labelColSpan }}
            wrapperCol={{ span: wrapperColSpan }}
            label={label}
            style={{ marginBottom: 12 }}
          >
            {React.cloneElement(Node, newProps)}
          </Form.Item>
        );
      }}
    />
  );
};
export default InputField;
