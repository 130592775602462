import * as React from "react";
import { Button, Modal, Form, Select, message } from "antd";
import Discount from "../../../model/Discount";
import DiscountService from "../../../helpers/model_services/DiscountService";
import { AppState } from "../../../store/appStore";
import { connect } from "react-redux";
import { requestActions } from "../../../redux/request/requestSlice";

export interface Props {
  addDiscount: (discountId: number) => Promise<boolean>;
}

const discountService = new DiscountService();

const AddDiscount: React.SFC<Props> = ({ addDiscount }) => {
  const [discountId, setDiscountId] = React.useState<number>(0);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [discounts, setDiscounts] = React.useState<Discount[]>([]);
  React.useEffect(() => {
    discountService.getAll().then(({ data }) => setDiscounts(data));
  }, []);
  const handleClose = () => {
    setVisible(false);
    setDiscountId(0);
  };
  return (
    <React.Fragment>
      <Button
        type="primary"
        className="green"
        style={{ margin: 12 }}
        size="small"
        onClick={() => {
          setVisible(true);
        }}
      >
        + Discount
      </Button>
      <Modal
        visible={visible}
        onCancel={handleClose}
        onOk={async () => {
          const res = await addDiscount(discountId);
          if (res) {
            message.success("Discount was succesfully added.");
            handleClose();
          }
        }}
        okButtonProps={{ disabled: discountId === 0 }}
      >
        <Form.Item label="Select a discount" style={{ marginBottom: 12 }}>
          <Select<number>
            style={{ width: "100%" }}
            autoFocus
            onChange={value => setDiscountId(value)}
          >
            {discounts.map(({ id, discontType, discont, code }) => (
              <Select.Option key={id} value={id}>
                {`[${code}] -${discont === null ? 0 : discont} ${
                  discontType === 1 ? "%" : "SAR"
                }`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Modal>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  addDiscount: requestActions.addDiscount
};

export default connect(
  null,
  mapDispatchToProps
)(AddDiscount);
