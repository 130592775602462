import AbstractRestService from "./AbstractRestService";
import { VisaRequestNote } from "../../model/Note";

export default class VisaNoteService extends AbstractRestService<
  VisaRequestNote
> {
  constructor() {
    super("Codelists/VisaRequestNote");
  }
}
