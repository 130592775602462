import { Mode } from "../../types";
import {
  createSlice,
  ThunkAction,
  AnyAction
} from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import RequestService, {
  Finance
} from "../../helpers/model_services/RequestService";
import InvoiceService from "../../helpers/model_services/InvoiceService";
import Invoice from "../../model/Invoice";

export interface FinanceState {
  loading: boolean;
  finance: Finance;
  error: string;
  visible: boolean;
  mode: Mode;
  deletedInvoices: Invoice[];
}

export interface FinanceActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: Finance;
  setVisible: boolean;
  setMode: Mode;
  setDeletedInvoices: Invoice[];
  setLoading: boolean;
}

const service = new RequestService();
const invoiceService = new InvoiceService();

export const financeSlice = createSlice<FinanceActions, FinanceState, AppState>(
  {
    slice: "finance",
    initialState: {
      loading: false,
      finance: { fees: [], payments: [], invoices: [] },
      error: "Error!",
      visible: false,
      mode: "view",
      deletedInvoices: []
    },
    cases: {
      fetchStart: state => ({
        ...state,
        loading: true
      }),
      fetchError: (state, payload) => ({
        ...state,
        loading: false,
        error: payload
      }),
      fetchSuccess: (state, payload) => ({
        ...state,
        loading: false,
        finance: payload
      }),
      setVisible: (state, payload) => ({
        ...state,
        visible: payload
      }),
      setMode: (state, payload) => ({
        ...state,
        mode: payload
      }),
      setDeletedInvoices: (state, payload) => ({
        ...state,
        deletedInvoices: payload
      }),
      setLoading: (state, payload) => ({
        ...state,
        loading: payload
      })
    }
  }
);

const {
  fetchStart,
  fetchError,
  fetchSuccess,
  setLoading,
  setDeletedInvoices
} = financeSlice.actions;

const fetchFinance = (
  reqId?: number,
  reqItemId?: number
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    requestDetail: {
      request: { id, itemId }
    }
  } = getState();
  const _reqId = reqId ? reqId : id;
  const _reqItemId = reqItemId
    ? reqItemId
    : itemId && itemId.length > 0
    ? itemId[0]
    : 0;

  try {
    dispatch(fetchStart());
    const { data } = await service.getFinance(_reqId);
    await dispatch(fetchDeletedInvoices(_reqItemId));
    dispatch(fetchSuccess(data));
    return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
    return false;
  }
};

const fetchDeletedInvoices = (
  reqItemId?: number
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    requestDetail: {
      request: { itemId }
    }
  } = getState();
  const _reqItemId = reqItemId
    ? reqItemId
    : itemId && itemId.length > 0
    ? itemId[0]
    : 0;
  try {
    dispatch(fetchStart());
    if (_reqItemId !== 0) {
      const { data: deletedInvoices } = await invoiceService.getDeletedInvoices(
        _reqItemId
      );
      dispatch(setDeletedInvoices(deletedInvoices));
    }
    dispatch(setLoading(false));
    return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
    return false;
  }
};

export const financeActions = {
  ...financeSlice.actions,
  fetchFinance,
  fetchDeletedInvoices
};
export const financeReducer = financeSlice.reducer;
