import AbstractRestService from "./AbstractRestService";
import ServicePlace from "../../model/ServicePlace";

export default class ServicePlaceService extends AbstractRestService<
  ServicePlace
> {
  constructor() {
    super("Codelists/ServicePlace");
  }
}
