import * as React from "react";
import { Row, Col, Spin, Button, Modal } from "antd";
import GeneralInfo from "./GeneralInfo";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import TravelerTable from "../traveler/TravelerTable";
import MyCard from "../_common/MyCard";
import VisaTable from "../visa/VisaTable";
import TravelerDetail from "../traveler/TravelerDetail";
import VisaDetail from "../visa/VisaDetail";
import FinanceDetail from "./finance/FinanceDetail";
import Traveler from "../../model/Traveler";
import NotesTable from "./NotesTable";
import GroupedFeeTable from "./finance/GroupedFeeTable";
import NewVisa from "../visa/NewVisa";
import NewTraveler from "../traveler/NewTraveler";
import SwapVisas from "../visa/SwapVisas";
import QuickEdit from "./QuickEdit";
import DocumentList from "../DocumentList";
import GeneratedDocuments from "./GeneratedDocuments";
import NewDocument from "./NewDocument";

interface Props {
  loading: boolean;
  travelers: Traveler[];
}

const RequestDetail: React.FC<Props> = ({
  loading,
  travelers
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={16}>
          <Row>
            <GeneralInfo />
          </Row>
          <Row>
            <MyCard
              title="Travelers"
              extra={
                <span>
                  <SwapVisas />
                  <NewTraveler />
                </span>
              }
            >
              <TravelerTable />
              <TravelerDetail />
            </MyCard>
          </Row>
          <Row>
            <MyCard
              title="Products"
              extra={
                <span>
                  <QuickEdit />
                  <NewVisa />
                </span>
              }
            >
              <VisaTable />
              <VisaDetail />
            </MyCard>
          </Row>
          <Row>
            <MyCard title="Attached documents" extra={<NewDocument />}>
              <DocumentList travelers={travelers} />
            </MyCard>
          </Row>
        </Col>
        <Col span={8}>
          {false && (
            <MyCard
              title="Reminders"
              extra={
                <Button type="primary" className="dark">
                  + Add reminder
                </Button>
              }
            />
          )}

          {/* <Row>
              <Col span={12}>
                <Form.Item
                  label="Status"
                  wrapperCol={{ span: 18 }}
                  labelCol={{ span: 6 }}
                >
                  <Select style={{ width: "100%" }}>
                    {["Paid", "Not paid"].map(val => (
                      <Select.Option key={val} value={val}>
                        {val}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Button type="primary" className="orange">
                  Save
                </Button>
              </Col>
            </Row> */}
          <GroupedFeeTable onDetailClick={() => setVisible(true)} />
          <Modal
            title="Finance"
            width="95%"
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={() => setVisible(false)}
            style={{ top: 75 }}
          >
            <FinanceDetail />
          </Modal>
          <NotesTable />
          <GeneratedDocuments />
        </Col>
      </Row>
    </Spin>
  );
};

const mapStateToProps = ({
  requestDetail: {
    loading,
    request: { travelers }
  }
}: AppState) => {
  return {
    loading,
    travelers
  };
};

export default connect(mapStateToProps)(RequestDetail);
