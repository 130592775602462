import * as React from "react";
import { Button, Divider, Popconfirm, Icon, Tooltip } from "antd";

export interface Props {
  onDelete: () => void;
  onOpen: () => void;
  noDivider?: boolean;
  openTooltip?: string;
}

const ActionButtons: React.SFC<Props> = ({
  onDelete,
  onOpen,
  noDivider = false,
  openTooltip = "Open"
}) => {
  return (
    <span>
      <Tooltip placement="top" title={openTooltip}>
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={e => {
            e.stopPropagation();
            onOpen();
          }}
        >
          <Icon
            type="edit"
            theme="twoTone"
            twoToneColor="#fcad1c"
            style={{ fontSize: 20 }}
          />
        </Button>
      </Tooltip>
      {noDivider ? null : <Divider type="vertical" />}
      <Popconfirm
        placement="top"
        title="Are you sure you want to delete this record?"
        onConfirm={e => {
          if (e) {
            onDelete();
            e.stopPropagation();
          }
        }}
        onCancel={e => {
          if (e) {
            e.stopPropagation();
          }
        }}
        okText="Yes"
        cancelText="No"
      >
        <Tooltip placement="top" title="Delete">
          <Button
            type="link"
            style={{ padding: 0 }}
            onClick={e => e.stopPropagation()}
          >
            <Icon
              type="delete"
              theme="twoTone"
              twoToneColor="#ed1b24"
              style={{ fontSize: 20 }}
            />
          </Button>
        </Tooltip>
      </Popconfirm>
    </span>
  );
};

export default ActionButtons;
