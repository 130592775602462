import * as React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import LoginPage from "../../_pages/LoginPage/LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import ProfilePage from "../../_pages/ProfilePage/ProfilePage";
import PasswordChangePage from "../../_pages/PasswordChangePage/PasswordChangePage";
import CountryPage from "../../_pages/Settings/CountryPage";
import RegionsPage from "../../_pages/Settings/RegionsPage";
import AgeCategoriesPage from "../../_pages/Settings/AgeCategoriesPage";
import ServicePlacePage from "../../_pages/Settings/ServicePlacePage";
import ServicePlaceTypesPage from "../../_pages/Settings/ServicePlaceTypePage";
import RolesPage from "../../_pages/Settings/RolesPage";
import RequestStatusessPage from "../../_pages/Settings/RequestStatusesPage";
import CallMeStatusPage from "../../_pages/Settings/CallMeStatusPage";
import UserPage from "../../_pages/Settings/UsersPage";
import TagsPage from "../../_pages/Settings/TagsPage";
import DocumentsTypesPage from "../../_pages/Settings/DocumentsTypes";
import CustomerPage from "../../_pages/Settings/CustomersPage";
import ClientsPage from "../../_pages/Clients/ClientsPage";
import WorkflowPage from "../../_pages/Settings/WorkflowPage";
import RequestsPage from "../../_pages/Requests/RequestsPage";
import VisaRequestPage from "../../_pages/VisaRequests/VisaRequestPage";
import VisaStatusPage from "../../_pages/Settings/VisaStatusPage";
import { AppState } from "../../../store/appStore";
import { connect } from "react-redux";
import { userActions } from "../../../redux/user/userSlice";
import VoucherPage from "../../_pages/Settings/VoucherPage";
import DiscountPage from "../../_pages/Settings/DiscountPage";
import SearchPage from "../../_pages/SearchPage/SearchPage";
import CompaniesPage from "../../_pages/Settings/CompaniesPage";
import FamilyMembersTypePage from "../../_pages/Settings/FamilyMembersTypePage";
import EmailVerificationPage from "../../_pages/EmailVerificationPage";

export interface Props {
  loggedIn: boolean;
  logout: () => void;
}

const Routes: React.SFC<Props> = ({ loggedIn, logout }) => {
  return (
    <Switch>
      <Redirect push exact from="/" to={loggedIn ? "requests/all" : "login"} />
      {/* PUBLIC routes */}
      <Route component={LoginPage} path="/login" exact />
      <Route
        path="/logout"
        render={() => {
          logout();
          return null;
        }}
      />
      <Route
        path={["/user-password-reset/:token", "/password-reset/:token"]}
        component={PasswordChangePage}
      />
      <Route
        path={["/email-verification/:token"]}
        component={EmailVerificationPage}
      />
      {/* USER routes */}
      <ProtectedRoute component={ProfilePage} path="/profile" />
      {/* Settings */}
      <ProtectedRoute component={CountryPage} path="/settings/countries" />

      <ProtectedRoute
        component={AgeCategoriesPage}
        path="/settings/age-categories"
      />
      <ProtectedRoute component={RegionsPage} path="/settings/regions" />
      <ProtectedRoute
        component={DocumentsTypesPage}
        path="/settings/attachment-types"
      />
      <ProtectedRoute component={RolesPage} path="/settings/roles" />
      <ProtectedRoute
        component={ServicePlacePage}
        path="/settings/service-places"
      />
      <ProtectedRoute
        component={ServicePlaceTypesPage}
        path="/settings/service-place-types"
      />
      <ProtectedRoute
        component={RequestStatusessPage}
        path="/settings/business-case-status"
      />
      <ProtectedRoute
        component={VisaStatusPage}
        path="/settings/visa-application-status"
      />
      <ProtectedRoute component={WorkflowPage} path="/settings/workflow" />
      <ProtectedRoute component={UserPage} path="/settings/users" />
      <ProtectedRoute component={CustomerPage} path="/settings/customers" />
      <ProtectedRoute component={TagsPage} path="/settings/tags" />
      <ProtectedRoute
        component={CallMeStatusPage}
        path="/settings/call-me-status"
      />
      <ProtectedRoute component={VoucherPage} path="/settings/vouchers" />
      <ProtectedRoute component={DiscountPage} path="/settings/discounts" />
      <ProtectedRoute component={CompaniesPage} path="/settings/companies" />
      <ProtectedRoute
        component={FamilyMembersTypePage}
        path="/settings/family-members-type"
      />
      <ProtectedRoute
        path={"/:type(requests|business-cases)/:filter/:id?"}
        component={RequestsPage}
      />
      <ProtectedRoute component={VisaRequestPage} path="/visa/:filter/:id?" />
      <ProtectedRoute component={ClientsPage} path="/clients/:filter/" />
      <ProtectedRoute component={SearchPage} path="/search/:searchValue/" />
      <Redirect to="/" push={true} />
    </Switch>
  );
};

const mapStateToProps = ({ user: { loggedIn } }: AppState) => ({
  loggedIn
});

const mapDispatchToProps = {
  logout: userActions.logout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(Routes);
