import React, { Component } from "react";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import CustomerService from "../../helpers/model_services/CustomerService";
import { Spin } from "antd";

interface State {
  loading: boolean;
  redirect: boolean;
}

class EmailVerificationPage extends Component<
  RouteComponentProps<{ token: string }>,
  State
> {
  state: State = {
    loading: true,
    redirect: false
  };
  private service = new CustomerService();
  async componentDidMount() {
    try {
      const { status } = await this.service.verifyEmail(
        this.props.match.params.token
      );
      if (status === 200) {
        this.setState({ loading: false });
      } else {
        this.setState({ redirect: true });
      }
    } catch (error) {
      this.setState({ redirect: true });
    }
  }

  render() {
    const { redirect, loading } = this.state;
    if (redirect) {
      return <Redirect to="/" />;
    }
    return (
      <Spin spinning={loading}>
        <div style={{ marginTop: 84, padding: 0 }}>
          Your email has been succesfully verified. Please refresh this page.
        </div>
      </Spin>
    );
  }
}

export default withRouter(EmailVerificationPage);
