import * as React from "react";
import ServicePlaceService from "../../../helpers/model_services/ServicePlaceService";
import ServicePlaceTypeService from "../../../helpers/model_services/ServicePlaceTypeService";
import BasicPage from "../../_common/BasicPage/BasicPage";
import ServicePlace from "../../../model/ServicePlace";
import useSelectVals from "../../_hooks/useSelectVals";
import { useSearchProps } from "../../_hooks/useColumnProps";

export interface Props {}

const ServicePlacePage: React.FC<Props> = () => {
  const { data: servicePlaceTypeVals } = useSelectVals(
    new ServicePlaceTypeService()
  );

  return (
    <BasicPage<ServicePlace>
      restService={new ServicePlaceService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        },
        {
          label: "Shortcut",
          ...useSearchProps("shortcut")
        },
        {
          label: "City",
          ...useSearchProps("city")
        },
        {
          label: "Type",
          inputProps: { type: "select" },
          selectOptions: servicePlaceTypeVals.map(({ name, id }) => {
            return { displayValue: name, dataValue: id };
          }),
          ...useSearchProps("servicePlaceTypeId", {
            select: { values: servicePlaceTypeVals }
          })
        }
      ]}
    />
  );
};

export default ServicePlacePage;
