import * as React from "react";
import { Table, Tooltip, Button, Icon } from "antd";
import { VisaRequestListItem } from "../../../helpers/model_services/VisaRequestService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  PaginationConfig,
  SorterResult,
  TableCurrentDataSource,
  ColumnProps
} from "antd/lib/table";
import { filter } from "lodash";
import { Mode } from "../../../types";
import { AppState } from "../../../store/appStore";
import { visaActions } from "../../../redux/visa/visaSlice";
import { travelerActions } from "../../../redux/traveler/travelerSlice";
import { connect } from "react-redux";
import {
  useIntervalProps,
  useSearchProps,
  useIntervalDateProps
} from "../../_hooks/useColumnProps";
import moment from "moment/moment.js";
import { visaListActions } from "../../../redux/visa/visaListSlice";

export interface Props
  extends RouteComponentProps<{ filter: string; id?: string }> {
  data: VisaRequestListItem[];
  loading: boolean;
  pagination: PaginationConfig;
  onTableChange: (
    pagination: PaginationConfig,
    filters: Record<keyof VisaRequestListItem, string[]>,
    sorter: SorterResult<VisaRequestListItem>,
    extra: TableCurrentDataSource<VisaRequestListItem>
  ) => void;
  filters: Record<keyof VisaRequestListItem, string[]>;
  sorter: SorterResult<VisaRequestListItem>;
  setTravelerMode: (mode: Mode) => void;
  setVisaMode: (mode: Mode) => void;
}

const VisaRequestTable: React.FC<Props> = ({
  loading,
  data,
  pagination,
  onTableChange,
  history,
  setTravelerMode,
  setVisaMode,
  filters,
  sorter,
  match: {
    params: { filter }
  }
}) => {
  const setMode = (newMode: Mode) => {
    setTravelerMode(newMode);
    setVisaMode(newMode);
  };

  const columns: ColumnProps<VisaRequestListItem>[] = [
    {
      title: "ID",
      dataIndex: "id",
      render: record => {
        return `#${record}`;
      },
      ...useIntervalProps("id", true),
      filteredValue: filters["id"],
      sortOrder: sorter.field === "id" && sorter.order
    },
    {
      title: "Traveler",
      dataIndex: "traveler",
      ...useSearchProps("traveler", { apiFilter: true }),
      filteredValue: filters["traveler"],
      sortOrder: sorter.field === "traveler" && sorter.order
    },
    {
      title: "Purpose",
      dataIndex: "purpose",
      ...useSearchProps("purpose", { apiFilter: true }),
      filteredValue: filters["purpose"],
      sortOrder: sorter.field === "purpose" && sorter.order
    },
    {
      title: "Date from",
      dataIndex: "dateFrom",
      ...useIntervalDateProps("dateFrom", true),
      filteredValue: filters["dateFrom"],
      sortOrder: sorter.field === "dateFrom" && sorter.order
    },
    {
      title: "Date to",
      dataIndex: "dateTo",
      ...useIntervalDateProps("dateTo", true),
      filteredValue: filters["dateTo"],
      sortOrder: sorter.field === "dateTo" && sorter.order
    },
    {
      title: "Price",
      dataIndex: "price",
      ...useIntervalProps("price", true),
      filteredValue: filters["price"],
      sortOrder: sorter.field === "price" && sorter.order
    },
    {
      title: "App. date",
      dataIndex: "servicePlaceAppointmentDateTime",
      ...useIntervalDateProps("servicePlaceAppointmentDateTime", true),
      render: text =>
        text === null
          ? null
          : moment(moment.utc(text).toDate()).format("D.M.YYYY HH:mm"),
      filteredValue: filters["servicePlaceAppointmentDateTime"],
      sortOrder:
        sorter.field === "servicePlaceAppointmentDateTime" && sorter.order
    },
    {
      title: "Actions",
      className: "table-center",
      width: "10%",
      render: (text, { id, travelerId }, index) => (
        <Tooltip placement="top" title="Edit">
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={e => {
              e.stopPropagation();
              setMode("edit");
              history.push(`/visa/${filter}/${id}`);
            }}
          >
            <Icon
              type="edit"
              theme="twoTone"
              twoToneColor="#fcad1c"
              style={{ fontSize: 20 }}
            />
          </Button>
        </Tooltip>
      )
    }
  ];
  return (
    <Table<VisaRequestListItem>
      loading={loading}
      columns={columns}
      dataSource={data}
      size="middle"
      rowKey={({ id }) => id.toString()}
      pagination={pagination}
      onChange={onTableChange}
      onRow={(record, rowIndex) => ({
        onClick: () => {
          setMode("view");
          history.push(`/visa/${filter}/${record.id}`);
        }
      })}
    />
  );
};

const mapStateToProps = ({
  visaList: {
    visaList: { visaRequestExtract: data },
    filters,
    sorter,
    loading,
    pagination
  }
}: AppState) => ({
  filters,
  sorter,
  loading,
  pagination,
  data
});

const mapDispatchToProps = {
  setVisaMode: visaActions.changeMode,
  setTravelerMode: travelerActions.changeMode,
  onTableChange: visaListActions.onTableChange
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VisaRequestTable)
);
