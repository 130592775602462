import * as React from "react";
import BasicPage from "../../_common/BasicPage/BasicPage";
import DiscountService from "../../../helpers/model_services/DiscountService";
import moment from "moment/moment.js";
import { Input, Button, Icon } from "antd";
import { CompareFn } from "antd/lib/table";
import Discount from "../../../model/Discount";
import Highlighter from "react-highlight-words";
import {
  useSearchProps,
  useIntervalProps,
  useIntervalDateProps
} from "../../_hooks/useColumnProps";

export interface Props {}

const DiscountPage: React.FC<Props> = () => {
  return (
    <BasicPage<Discount>
      restService={new DiscountService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        },
        {
          label: "Code",
          ...useSearchProps("code")
        },
        {
          label: "Tag",
          ...useSearchProps("tag")
        },
        {
          label: "Priority",
          inputProps: { type: "number" },
          ...useSearchProps("priority", { isNumber: true })
        },
        {
          label: "Active",
          inputProps: { type: "switch" },
          ...useSearchProps("isActive", { isBoolean: true })
        },
        {
          label: "Res. from",
          inputProps: { type: "date" },
          ...useIntervalDateProps("reservationFrom")
        },
        {
          label: "Res. to",
          inputProps: { type: "date" },
          ...useIntervalDateProps("reservationTo")
        },
        {
          label: "Use. from",
          inputProps: { type: "date" },
          ...useIntervalDateProps("uSeFrom")
        },
        {
          label: "Use. to",
          inputProps: { type: "date" },
          ...useIntervalDateProps("uSeTo")
        },
        {
          label: "Type",
          inputProps: { type: "select" },
          selectOptions: [
            { displayValue: "Percentual", dataValue: 1 },
            { displayValue: "Fix", dataValue: 2 }
          ],
          ...useSearchProps("discontType"),
          render: (text: number) => (1 ? "Percentual" : "Fix")
        },
        {
          label: "Value",
          inputProps: { type: "number" },
          ...useIntervalProps("discont")
        },
        {
          label: "Min",
          inputProps: { type: "number" },
          ...useIntervalProps("discontMinValue"),
          render: (text: number) => `${text === null ? 0 : text} SAR`
        },
        {
          label: "Max",
          inputProps: { type: "number" },
          ...useIntervalProps("discontMaxValue"),
          render: (text: number) => `${text === null ? 0 : text} SAR`
        },
        {
          label: "Manual",
          inputProps: { type: "switch" },
          ...useSearchProps("discontUseManual", { isBoolean: true })
        },
        {
          label: "Voucher",
          inputProps: { type: "switch" },
          ...useSearchProps("discontUseVoucher", { isBoolean: true })
        },
        {
          label: "Web",
          inputProps: { type: "switch" },
          ...useSearchProps("isForWeb", { isBoolean: true })
        },
        {
          label: "Mobile",
          dataIndex: "isForMobileApp",
          inputProps: { type: "switch" },
          ...useSearchProps("isForMobileApp", { isBoolean: true })
        }
      ]}
    />
  );
};

export default DiscountPage;
