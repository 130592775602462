import * as React from "react";
import { Formik } from "formik";
import { Form } from "antd";
import BaseClass from "../../../model/_BaseClass";
export interface Props {
  initVals: BaseClass;
  onSubmit: (values: BaseClass) => void;
}

const MyForm: React.SFC<Props> = ({ initVals, onSubmit, children }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={initVals}
      onSubmit={values => onSubmit(values)}
      render={formikProps => {
        return (
          <Form
            layout="horizontal"
            hideRequiredMark
            onSubmit={formikProps.handleSubmit}
          >
            {children}
          </Form>
        );
      }}
    />
  );
};

export default MyForm;
