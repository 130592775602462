import * as React from "react";
import { Icon, Menu, Badge } from "antd";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

export interface IMenuItem {
  to: string;
  title: string;
}

const SideBar: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {
  let links: { to: string; title: string }[] = [];
  const cur = pathname.split("/")[1];
  switch (cur) {
    case "clients":
      links = [
        { to: "/new", title: "New" },
        { to: "/pending", title: "Pending" },
        { to: "/finished", title: "Finished" },
        { to: "/cancelled", title: "Cancelled" },
        { to: "/all-clients", title: "All clients" }
      ];
      break;

    case "settings":
      links = [
        { to: "/countries", title: "Countries" },
        { to: "/age-categories", title: "Age categories" },
        { to: "/regions", title: "Regions" },
        { to: "/attachment-types", title: "Attachment types" },
        { to: "/roles", title: "Roles" },
        { to: "/service-places", title: "Service places" },
        { to: "/service-place-types", title: "Service place types" },
        { to: "/business-case-status", title: "BC status" },
        { to: "/visa-application-status", title: "VA status" },
        { to: "/users", title: "Users" },
        { to: "/customers", title: "Customers" },
        { to: "/call-me-status", title: "Call me status" },
        /* { to: "/tags", title: "Tags" },
        { to: "/workflow", title: "Workflow" }, */
        { to: "/discounts", title: "Discounts" },
        { to: "/vouchers", title: "Vouchers" },
        { to: "/companies", title: "Companies" },
        { to: "/family-members-type", title: "Family members type" }
      ];
      break;

    case "requests":
      links = [
        { to: "/new", title: "New" },
        { to: "/unfinished", title: "Unfinished" },
        { to: "/completed", title: "Completed" },
        { to: "/all", title: "All" }
      ];
      break;
    case "business-cases":
      links = [
        { to: "/open", title: "Open" },
        { to: "/close-audit", title: "Close audit" },
        { to: "/closed", title: "Closed" },
        { to: "/not-acceptable", title: "Not acceptable" },
        { to: "/cancelled", title: "Cancelled" },
        { to: "/all", title: "All" }
      ];
      break;
    case "visa":
      links = [
        { to: "/sales", title: "Sales" },
        { to: "/pending", title: "Pending" },
        { to: "/data-entry", title: "DataEntry" },
        { to: "/apply-online", title: "ApplyOnline" },
        { to: "/ready-to-submit", title: "ReadyToSubmit" },
        { to: "/submitted", title: "Submitted" },
        { to: "/ready-to-collect", title: "ReadyToCollect" },
        { to: "/collected", title: "Collected" }
      ];
      break;
    default:
      break;
  }
  return (
    <Menu
      selectedKeys={[pathname]}
      theme="dark"
      mode="inline"
      style={{ lineHeight: "64px" }}
    >
      {links.map(({ to, title }) => (
        <Menu.Item key={`/${cur}${to}`}>
          <Link to={`/${cur}${to}`}>
            <Icon type="pie-chart" />
            <span>{title}</span>
            <Badge count={0} style={{ marginLeft: 5, boxShadow: "none" }} />
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default withRouter(SideBar);
