import * as React from "react";
import { Formik } from "formik";
import { Form, Button, Row, Switch } from "antd";
import InputField from "../_common/InputField/InputField";
import { ShortCustomer } from "../../helpers/model_services/CustomerService";

export interface Props {
  values: ShortCustomer;
  onSubmit: (values: ShortCustomer) => void;
  onCancel?: () => void;
}

const CustomerForm: React.SFC<Props> = ({ values, onSubmit, onCancel }) => {
  return (
    <Formik<ShortCustomer>
      enableReinitialize
      initialValues={values}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <Form layout="horizontal" hideRequiredMark onSubmit={handleSubmit}>
            <Row>
              <InputField name="name" label="First name" />
            </Row>
            <Row>
              <InputField name="lastName" label="Last name" />
            </Row>
            <Row>
              <InputField name="email" label="Email address" />
            </Row>
            <Row>
              <InputField name="phone" label="Phone" />
            </Row>
            <div
              style={{
                left: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right"
              }}
            >
              {onCancel ? (
                <Button onClick={onCancel} style={{ marginRight: 8 }}>
                  Cancel
                </Button>
              ) : null}
              <Button type="primary" htmlType="submit" onClick={onCancel}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default CustomerForm;
