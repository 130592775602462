import * as React from "react";
import MyCard from "./MyCard";
import { Button, Drawer, Table, Row, Col, Divider } from "antd";
import NotesForm from "../request/NotesForm";
import { Mode } from "../../types";
import ActionButtons from "./ActionButtons";
import moment from "moment/moment.js";
import "../../css/Notes.css";
import { TravelerNote, VisaRequestNote } from "../../model/Note";

type Note = TravelerNote | VisaRequestNote;

export interface Props {
  setMode: (newMode: Mode) => void;
  notes: Note[];
  travelerId?: number;
  visaRequestId?: number;
  onSubmit: (values: any) => Promise<boolean>;
  loading: boolean;
  disabled: boolean;
}

function Notes({
  notes,
  setMode,
  travelerId,
  visaRequestId,
  onSubmit,
  loading,
  disabled
}: Props) {
  const [selected, setSelected] = React.useState<Note>({} as Note);
  const [visible, setVisible] = React.useState<boolean>(false);
  return (
    <MyCard
      title="Notes"
      extra={
        <Button
          className="dark"
          type="primary"
          onClick={() => {
            const newObj = travelerId
              ? ({ travelerId } as TravelerNote)
              : ({ visaRequestId } as VisaRequestNote);
            setSelected(newObj);
            setMode("new");
            setVisible(true);
          }}
          disabled={disabled}
        >
          + ADD
        </Button>
      }
    >
      <Table<Note>
        showHeader={false}
        loading={loading}
        columns={[
          {
            render: (asdf, note, index) => {
              const { text, updatedAt, createdAt, user } = note;
              return (
                <React.Fragment>
                  <Row className="note">{text}</Row>
                  <Row className="bottom-row">
                    <span className="bottom-note-text">{`Created by: ${user}`}</span>
                    <Divider
                      type="vertical"
                      className="traveler-note-divider"
                    />
                    <span className="bottom-note-text">{`Date: ${moment(
                      moment
                        .utc(updatedAt === null ? createdAt : updatedAt)
                        .toDate()
                    ).format("D.M.YYYY | HH:mm")}`}</span>
                    {!disabled ? (
                      <div style={{ float: "right" }}>
                        <ActionButtons
                          openTooltip="Edit"
                          onOpen={() => {
                            setSelected(note);
                            setMode("edit");
                            setVisible(true);
                          }}
                          onDelete={() => {
                            setMode("delete");
                            onSubmit(note);
                          }}
                        />
                      </div>
                    ) : null}
                  </Row>
                </React.Fragment>
              );
            }
          }
        ]}
        dataSource={notes}
        size="middle"
        rowKey={({ id }) => id.toString()}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
      />
      <Drawer width={500} onClose={() => setVisible(false)} visible={visible}>
        <NotesForm
          onSubmit={values =>
            onSubmit(values).then(res => {
              if (res) {
                setMode("view");
                setVisible(false);
                setSelected({} as Note);
              }
            })
          }
          note={selected}
          onCancel={() => {
            setMode("view");
            setVisible(false);
            setSelected({} as Note);
          }}
        />
      </Drawer>
    </MyCard>
  );
}

export default Notes;
