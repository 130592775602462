import * as React from "react";
import { Formik } from "formik";
import { Form, Button, message, Row, Collapse } from "antd";
import MyCard from "../_common/MyCard";
import VisaRequest from "../../model/VisaRequest";
import { Mode } from "../../types";
import { ExtraFee } from "../../helpers/model_services/VisaRequestService";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import { requestActions } from "../../redux/request/requestSlice";
import PreviousVisa from "./PreviousVisa";
import InvitedByPerson from "./InvitedByPerson";
import InvitedByCompany from "./InvitedByCompany";
import FinalCountry from "./FinalCountry";
import { visaActions } from "../../redux/visa/visaSlice";
import MainFormSection from "./MainFormSection";
import { travelerActions } from "../../redux/traveler/travelerSlice";
import ExtraFees from "./ExtraFees";
import Traveler from "../../model/Traveler";
import TravelerForm from "../traveler/TravelerForm";
import getName from "../../helpers/MyFunctions";
import PriceListService from "../../helpers/model_services/PriceListService";
import Sponsor from "./Sponsor";
import PersonalData from "./PersonalData";

const priceService = new PriceListService();

export interface TravelerVisa {
  traveler: Traveler;
  visa: VisaRequest & { extraFees: ExtraFee[] };
}

export interface Props {
  traveler: Traveler;
  visaRequest: VisaRequest;
  mode: Mode;
  refreshRequestDetail: () => Promise<boolean>;
  refreshVisa: () => void;
  verifyChecklist: (travelerId: number, visaId: number) => Promise<boolean>;
  visaSection?: boolean;
  loading: boolean;
  extraFees: ExtraFee[];
  updateVisa: (values: VisaRequest) => Promise<boolean>;
  updateExtraFees: (vals: ExtraFee[]) => Promise<ExtraFee[]>;
  travelerMode: Mode;
  submitTraveler: (val: Traveler) => Promise<boolean>;
  countryId: number;
}

const VisaForm: React.FC<Props> = ({
  visaRequest,
  extraFees,
  mode,
  updateVisa,
  refreshRequestDetail,
  refreshVisa,
  verifyChecklist,
  visaSection = false,
  loading,
  updateExtraFees,
  traveler,
  travelerMode,
  submitTraveler,
  countryId
}) => {
  const [servicePlaceDisabled, setServicePlaceDisabled] = React.useState<
    boolean
  >(false);
  React.useEffect(() => {
    priceService
      .checkServicePlace(countryId, traveler.isSaudi)
      .then(({ data }) => {
        setServicePlaceDisabled(data === -1);
      });
  }, [countryId, traveler.isSaudi, traveler.id]);
  const { name, lastName } = traveler;
  const travelerName = getName(name, lastName);
  const submitVisa = async ({
    extraFees,
    ...values
  }: VisaRequest & { extraFees: ExtraFee[] }) => {
    if (extraFees.length > 0) {
      await updateExtraFees(extraFees);
    }
    const res = await updateVisa(values);

    if (res) {
      refreshVisa();
      if (!visaSection) {
        refreshRequestDetail();
      }

      message.success("Visa updated.");
    } else {
      message.error("Error");
    }
  };
  const disabled = mode === "view";
  return (
    <Formik<TravelerVisa>
      enableReinitialize
      initialValues={{
        visa: { ...visaRequest, extraFees },
        traveler: travelerMode === "new" ? ({} as Traveler) : traveler
      }}
      onSubmit={async ({ visa, traveler }) => {
        submitTraveler(traveler).then(res => {
          if (res) {
            message.success("Traveler was succesfully updated");
          }
        });
        if (
          visaRequest.visaRequestStatusesId &&
          visa.visaRequestStatusesId &&
          visaRequest.visaRequestStatusesId < visa.visaRequestStatusesId
        ) {
          const resp = await verifyChecklist(
            visaRequest.travelerId,
            visaRequest.id
          );
          if (resp) {
            submitVisa(visa);
          }
        } else {
          submitVisa(visa);
        }
      }}
      render={({
        handleSubmit,
        values: {
          traveler: { recognizedData, identityRecognizedData },
          visa
        }
      }) => {
        return (
          <Form
            id="visa-form"
            layout="horizontal"
            hideRequiredMark
            onSubmit={handleSubmit}
          >
            <Row>
              <Collapse
                accordion
                style={{
                  margin: 12,
                  backgroundColor: "#bcbcbc"
                }}
              >
                <Collapse.Panel
                  header={
                    <span style={{ fontSize: 16, fontWeight: 700 }}>
                      Details of client {travelerName}
                    </span>
                  }
                  key="1"
                >
                  <TravelerForm
                    recognizedData={recognizedData}
                    identityRecognizedData={identityRecognizedData}
                  />
                </Collapse.Panel>
              </Collapse>
            </Row>
            <Row>
              <MyCard
                title="Product details"
                extra={
                  mode !== "view" && (
                    <Button
                      loading={loading}
                      className="dark"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  )
                }
              >
                <MainFormSection servicePlaceDisabled={servicePlaceDisabled} />
                <PreviousVisa recognizedData={visa.recognizedData} />
                <FinalCountry />
                <InvitedByPerson />
                <InvitedByCompany />
                <Sponsor />
                <PersonalData />
                <ExtraFees disabled={disabled} extraFees={visa.extraFees} />
              </MyCard>
            </Row>
          </Form>
        );
      }}
    />
  );
};

const mapStateToProps = ({
  visa: { visa: visaRequest, mode, loading, extraFees },
  traveler: { mode: travelerMode },
  requestDetail: {
    request: { confirmedCountryId, requestedCountryId }
  }
}: AppState) => ({
  travelerMode,
  visaRequest,
  mode,
  loading,
  extraFees,
  countryId:
    confirmedCountryId === null ? requestedCountryId : confirmedCountryId
});

const mapDispatchToProps = {
  refreshRequestDetail: requestActions.fetchRequest,
  refreshVisa: visaActions.fetchVisaRequest,
  verifyChecklist: travelerActions.verifyChecklist,
  updateVisa: visaActions.updateVisaRequest,
  updateExtraFees: visaActions.updateExtraFees,
  submitTraveler: travelerActions.submitTraveler
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisaForm);
