import * as React from "react";
import { Table, message, Button } from "antd";
import { connect } from "react-redux";
import { AppState } from "../../../store/appStore";
import Payment from "../../../model/Payment";
import moment from "moment/moment.js";
import ActionButtons from "../../_common/ActionButtons";
import MyCard from "../../_common/MyCard";
import { Mode } from "../../../types";
import PaymentForm from "./PaymentForm";
import PaymentService from "../../../helpers/model_services/PaymentService";
import { requestActions } from "../../../redux/request/requestSlice";

export interface Props {
  data: Payment[];
  paid: boolean;
  onDetailClick: () => void;
  itemId: number;
  refresh: () => void;
}

const paymentService = new PaymentService();

const GroupedFeeTable: React.FC<Props> = ({
  data,
  paid,
  onDetailClick,
  itemId,
  refresh
}) => {
  const [action, setAction] = React.useState<Mode>("view");
  const initPayment = {
    itemId
  } as Payment;
  const [selected, setSelected] = React.useState<Payment>(initPayment);
  const [visible, setVisible] = React.useState<boolean>(false);
  const closeForm = () => {
    setVisible(false);
    setAction("view");
    setSelected({} as Payment);
  };
  return (
    <MyCard
      title={`Finance [${paid ? "PAID" : "NOT PAID"}]`}
      extra={
        <span>
          <Button
            type="primary"
            className="dark"
            onClick={onDetailClick}
            size="small"
            style={{ marginRight: 12 }}
          >
            Detail
          </Button>
          <PaymentForm
            visible={visible}
            onSubmit={async values => {
              switch (action) {
                case "new":
                  await paymentService.create(values as Payment);
                  break;
                case "edit":
                  await paymentService.update(values as Payment);
                  break;
                default:
                  break;
              }
              refresh();
              closeForm();
            }}
            onClose={closeForm}
            onNew={() => {
              setSelected(initPayment);
              setAction("new");
              setVisible(true);
            }}
            payment={selected}
          />
        </span>
      }
    >
      <Table<Payment>
        columns={[
          {
            dataIndex: "date",
            title: "Tran. date",
            className: "table-center",
            render: text =>
              text !== null && text ? moment(text).format("D.M.YYYY") : null
          },
          {
            dataIndex: "amount",
            title: "Amount",
            className: "table-center",
            render: text => `${text} SAR`
          },
          {
            className: "table-center",
            width: "20%",
            render: (text, record, index) => (
              <ActionButtons
                onOpen={() => {
                  setAction("edit");
                  setSelected(record);
                  setVisible(true);
                }}
                openTooltip="Edit"
                onDelete={() => {
                  paymentService.delete(record).then(() => refresh());
                }}
              />
            )
          }
        ]}
        dataSource={data}
        size="middle"
        onRow={row => ({
          onClick: () => {}
        })}
        rowKey={({ item }) => item}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
      />
    </MyCard>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelers, itemId }
  },
  finance: {
    finance: { payments, fees }
  }
}: AppState) => {
  const total = fees.reduce(
    (sum, val) => sum + (val.finalPrice === 0 ? val.sellPrice : val.finalPrice),
    0
  );
  const paidAmount = payments.reduce((sum, val) => sum + val.amount, 0);
  return {
    data: payments,
    travelers,
    paid: total - paidAmount <= 0,
    itemId: itemId.length === 0 ? 0 : itemId[0]
  };
};

const mapDispatchToProps = {
  refresh: requestActions.fetchRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupedFeeTable);
