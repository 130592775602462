import * as React from "react";
import MyCard from "../../_common/MyCard";
import { connect } from "react-redux";
import { AppState } from "../../../store/appStore";
import { Row } from "antd";

export interface Props {
  total: number;
  paid: number;
  unpaid: number;
  netPrice: number;
  margin: number;
  discountPrice: number;
}

const FinanceOverview: React.SFC<Props> = ({
  total,
  paid,
  unpaid,
  netPrice,
  margin,
  discountPrice
}) => {
  return (
    <MyCard
      title={
        <span>
          Total amount of BC:{" "}
          <span style={{ float: "right", fontSize: 20 }}>{total} SAR</span>
        </span>
      }
    >
      <Row className="finance-row green">
        Already paid: <span>{paid} SAR</span>
      </Row>
      <Row className="finance-row red">
        {unpaid >= 0 ? "To be paid" : "Overpaid"}:{" "}
        <span>{unpaid < 0 ? -unpaid : unpaid} SAR</span>
      </Row>
      <Row className="bottom-section">
        <Row>
          Discount: <span>{discountPrice} SAR</span>
        </Row>
        <Row>
          Total net price: <span>{netPrice} SAR</span>
        </Row>
        <Row>
          Total margin: <span>{margin} SAR</span>
        </Row>
      </Row>
    </MyCard>
  );
};

const mapStateToProps = ({
  finance: {
    finance: { fees, payments }
  }
}: AppState) => {
  const total = fees.reduce(
    (sum, val) => sum + (val.finalPrice === 0 ? val.sellPrice : val.finalPrice),
    0
  );
  const paid = payments.reduce((sum, val) => sum + val.amount, 0);
  const netPrice = fees.reduce((sum, val) => sum + val.netPrice, 0);
  return {
    total,
    paid,
    unpaid: total - paid,
    netPrice: netPrice,
    margin: total - netPrice,
    discountPrice: fees
      .filter(f => f.typeOfPaymentFeeId === 14)
      .reduce((sum, val) => sum + val.sellPrice, 0)
  };
};

export default connect(mapStateToProps)(FinanceOverview);
