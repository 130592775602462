import * as React from "react";
import TextWithLabel from "../_common/TextWithLabel";
import { Button, Icon, Input, Form, Tooltip, message } from "antd";
import { Formik, Field, FieldProps } from "formik";
import { RequestDetail } from "../../helpers/model_services/RequestService";
import { connect } from "react-redux";
import { requestActions } from "../../redux/request/requestSlice";

export interface Props {
  req: RequestDetail;
  onSubmit: (values: RequestDetail) => Promise<boolean>;
}

const BusinessPartner: React.FC<Props> = ({ req, onSubmit }) => {
  const {
    businnessPartner,
    businnessPartnerBy,
    businnessPartnerMail,
    businnessPartnerTel
  } = req;
  const [editing, setEditing] = React.useState<boolean>(false);
  return (
    <Formik<RequestDetail>
      enableReinitialize
      initialValues={req}
      onSubmit={async values => {
        const resp = await onSubmit(values);
        if (resp) {
          message.success("Saved.");
          setEditing(false);
        }
      }}
      render={({ handleSubmit, handleReset }) => {
        return (
          <Form onSubmit={handleSubmit} onReset={handleReset}>
            <TextWithLabel
              label="Business partner"
              text={
                <span>
                  {editing ? (
                    <Field
                      name="businnessPartner"
                      render={({ field }: FieldProps<RequestDetail>) => (
                        <Input {...field} style={{ width: "60%" }} />
                      )}
                    />
                  ) : (
                    businnessPartner
                  )}
                  <span style={{ float: "right" }}>
                    {editing ? (
                      <Button type="link" htmlType="submit">
                        <Icon type="save" style={{ fontSize: 28 }} />
                      </Button>
                    ) : null}
                    <Tooltip placement="top" title="Edit">
                      <Button
                        htmlType={editing ? "reset" : undefined}
                        type="link"
                        onClick={() => {
                          setEditing(!editing);
                        }}
                        style={{ padding: 0, marginRight: 16 }}
                      >
                        <Icon
                          type={editing ? "close-circle" : "edit"}
                          theme="twoTone"
                          twoToneColor="#fcad1c"
                          style={{ fontSize: 28 }}
                        />
                      </Button>
                    </Tooltip>
                  </span>
                </span>
              }
            />

            <TextWithLabel
              label="By"
              text={
                editing ? (
                  <Field
                    name="businnessPartnerBy"
                    render={({ field }: FieldProps<RequestDetail>) => (
                      <Input {...field} style={{ width: "60%" }} />
                    )}
                  />
                ) : (
                  businnessPartnerBy
                )
              }
            />

            <TextWithLabel
              label="Tel"
              text={
                editing ? (
                  <Field
                    name="businnessPartnerTel"
                    render={({ field }: FieldProps<RequestDetail>) => (
                      <Input {...field} style={{ width: "60%" }} />
                    )}
                  />
                ) : (
                  businnessPartnerTel
                )
              }
            />

            <TextWithLabel
              label="Email"
              text={
                editing ? (
                  <Field
                    name="businnessPartnerMail"
                    render={({ field }: FieldProps<RequestDetail>) => (
                      <Input {...field} style={{ width: "60%" }} />
                    )}
                  />
                ) : (
                  businnessPartnerMail
                )
              }
            />
          </Form>
        );
      }}
    />
  );
};

const mapDispatchToProps = {
  onSubmit: requestActions.updateBusinessPartner
};
export default connect(
  null,
  mapDispatchToProps
)(BusinessPartner);
