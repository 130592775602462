import { ColumnProps } from "antd/lib/table";
import { RequestListItem } from "../../../helpers/model_services/RequestService";
import { VisaRequestListItem } from "../../../helpers/model_services/VisaRequestService";
import moment from "moment/moment.js";

export const reqCols: ColumnProps<RequestListItem>[] = [
  {
    title: "ID",
    render: record => {
      return `#${record}`;
    },
    dataIndex: "id"
  },
  {
    title: "Creation date",
    dataIndex: "createdAt",
    render: (text: Date) =>
      moment(moment.utc(text).toDate()).format("D.M.YYYY HH:mm")
  },
  {
    title: "Customer",
    dataIndex: "customerName"
  },
  {
    title: "Contact Phone",
    dataIndex: "contactPhone"
  },
  {
    title: "Requested country",
    dataIndex: "requestedCountry"
  },
  {
    title: "Requested region",
    dataIndex: "requestedRegion"
  },
  {
    title: "Confirmed country",
    dataIndex: "confirmedCountry"
  },
  {
    title: "Confirmed region",
    dataIndex: "confirmedRegion"
  },
  {
    title: "# of Travelers",
    dataIndex: "numberOfTravelers"
  },
  {
    title: "Price",
    dataIndex: "price"
  },
  {
    title: "Paid",
    dataIndex: "paid"
  }
];

export const bcCols: ColumnProps<RequestListItem>[] = [
  {
    title: "BC_ID",
    render: record => {
      return `#${record}`;
    },
    dataIndex: "bc_Id"
  },
  {
    title: "Creation date",
    dataIndex: "createdAt",
    render: (text: Date) =>
      moment(moment.utc(text).toDate()).format("D.M.YYYY HH:mm")
  },
  {
    title: "Customer",
    dataIndex: "customerName"
  },
  {
    title: "Contact Phone",
    dataIndex: "contactPhone"
  },
  {
    title: "Requested country",
    dataIndex: "requestedCountry"
  },
  {
    title: "Requested region",
    dataIndex: "requestedRegion"
  },
  {
    title: "Confirmed country",
    dataIndex: "confirmedCountry"
  },
  {
    title: "Confirmed region",
    dataIndex: "confirmedRegion"
  },
  {
    title: "# of Travelers",
    dataIndex: "numberOfTravelers"
  },
  {
    title: "Price",
    dataIndex: "price"
  },
  {
    title: "Paid",
    dataIndex: "paid"
  }
];

export const visaCols: ColumnProps<VisaRequestListItem>[] = [
  {
    title: "ID",
    dataIndex: "id",
    render: record => {
      return `#${record}`;
    }
  },
  {
    title: "Traveler",
    dataIndex: "traveler"
  },
  {
    title: "Purpose",
    dataIndex: "purpose"
  },
  {
    title: "Date from",
    dataIndex: "dateFrom",
    render: (text: Date) =>
      text === null ? null : moment(text).format("D.M.YYYY")
  },
  {
    title: "Date to",
    dataIndex: "dateTo",
    render: (text: Date) =>
      text === null ? null : moment(text).format("D.M.YYYY")
  },
  {
    title: "Price",
    dataIndex: "price"
  },
  {
    title: "App. date",
    dataIndex: "servicePlaceAppointmentDateTime",
    render: text =>
      text === null
        ? null
        : moment(moment.utc(text).toDate()).format("D.M.YYYY HH:mm")
  }
];
