import AbstractRestService from "./AbstractRestService";
import FamilyMemberType from "../../model/FamilyMemberType";

export default class FamilyMemberTypeService extends AbstractRestService<
  FamilyMemberType
> {
  constructor() {
    super("Codelists/FamilyMembersType");
  }
}
