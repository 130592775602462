import * as React from "react";
import {
  Table,
  Tooltip,
  Icon,
  Divider,
  Popconfirm,
  Button,
  message
} from "antd";
import TravelerDocument from "../../model/TravelerDocument";
import moment from "moment/moment.js";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";
import { AppState } from "../../store/appStore";
import { requestActions } from "../../redux/request/requestSlice";
import { connect } from "react-redux";
import MyCard from "../_common/MyCard";
import VisaRequestService from "../../helpers/model_services/VisaRequestService";
import getName from "../../helpers/MyFunctions";
import { visaActions } from "../../redux/visa/visaSlice";
import { Mode } from "../../types";

export interface Props {
  docs: TravelerDocument[];
  fetchRequest: () => Promise<boolean>;
  fetchChecklist: () => void;
  travelerId: number;
  loggedUser: string;
  mode: Mode;
}

const service = new TravelerDocumentsService();
const visaService = new VisaRequestService();

const VisaGeneratedDocs: React.FC<Props> = ({
  docs,
  fetchRequest,
  travelerId,
  loggedUser,
  fetchChecklist,
  mode
}) => {
  const isEdit = mode === "edit";
  const [loading, setLoading] = React.useState<boolean>(false);
  return (
    <MyCard
      title="Generated documents"
      extra={
        <span>
          <Button
            disabled={!isEdit}
            type="primary"
            className="dark"
            loading={loading}
            style={{ marginRight: 6 }}
            onClick={async () => {
              setLoading(true);
              try {
                const { status } = await visaService.generateDocuments(
                  travelerId,
                  loggedUser
                );
                if (status === 200) {
                  fetchRequest();
                  fetchChecklist();
                  message.success("Documents were generated.");
                } else {
                  message.error(`Error code ${status}`);
                }
              } catch (error) {}

              setLoading(false);
            }}
          >
            {loading ? "Generating..." : "Generate documents"}
          </Button>
          <Tooltip
            placement="top"
            title="Changes in the visa form above have to be saved before generating documents. Otherwise documents will be generated based on last saved visa form."
          >
            <i className="fas fa-info-circle"></i>
          </Tooltip>
        </span>
      }
    >
      <Table<TravelerDocument>
        size="middle"
        dataSource={docs}
        pagination={{
          defaultPageSize: 5,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "15"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
        columns={[
          {
            width: 30,
            render: (text, { note, documentsTypes: { iconName } }) =>
              note && note !== null ? (
                <Tooltip placement="top" title={note}>
                  <i style={{ fontSize: 20 }} className={iconName} />
                </Tooltip>
              ) : (
                <i style={{ fontSize: 20 }} className={iconName} />
              )
          },
          {
            title: "Document type",
            dataIndex: "documentsTypes.name"
          },
          {
            title: "Creation date",
            dataIndex: "createdAt",
            render: (text: Date) => moment(text).format("D.M.YYYY")
          },
          {
            title: "Created by",
            dataIndex: "created"
          },
          {
            render: (text, record, index) => (
              <span>
                <Tooltip placement="top" title="View">
                  <a
                    className="no-color"
                    onClick={() => {
                      service
                        .getDocumentURL(record.id)
                        .then(({ data: { documentUrl } }) => {
                          const link = document.createElement("a");
                          link.href = documentUrl;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        });
                    }}
                  >
                    <Icon
                      type="eye"
                      theme="twoTone"
                      twoToneColor="#fcad1c"
                      style={{ fontSize: 20 }}
                    />
                  </a>
                </Tooltip>
                {isEdit ? (
                  <React.Fragment>
                    <Divider type="vertical" />
                    <Popconfirm
                      placement="top"
                      title="Are you sure you want to delete this record?"
                      onConfirm={() =>
                        service.delete(record).then(({ status }) => {
                          if (status === 200) {
                            fetchRequest();
                            message.success("Success");
                          }
                        })
                      }
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip placement="top" title="Delete">
                        <Button type="link" style={{ padding: 0 }}>
                          <Icon
                            type="delete"
                            theme="twoTone"
                            twoToneColor="#ed1b24"
                            style={{ fontSize: 20 }}
                          />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  </React.Fragment>
                ) : null}
              </span>
            )
          }
        ]}
      />
    </MyCard>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelersDocuments }
  },
  traveler: {
    traveler: { id: travelerId }
  },
  user: {
    user: { name, lastName }
  },
  visa: { mode }
}: AppState) => ({
  travelerId,
  docs: travelersDocuments
    .filter(
      doc =>
        doc.travelersId === travelerId &&
        doc.documentUrl !== null &&
        doc.documentsTypes !== null &&
        doc.documentsTypes.area === "SystemGenerated"
    )
    .sort(({ documentsTypes: { name: a } }, { documentsTypes: { name: b } }) =>
      a.localeCompare(b)
    ),
  loggedUser: getName(name, lastName),
  mode
});

const mapDispatchToProps = {
  fetchRequest: requestActions.fetchRequest,
  fetchChecklist: visaActions.fetchChecklist
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisaGeneratedDocs);
