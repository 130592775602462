import * as React from "react";
import BasicPage from "../../_common/BasicPage/BasicPage";
import VoucherService from "../../../helpers/model_services/VoucherService";
import moment from "moment/moment.js";
import DiscountService from "../../../helpers/model_services/DiscountService";
import Voucher from "../../../model/Voucher";
import useSelectVals from "../../_hooks/useSelectVals";
import {
  useSearchProps,
  useIntervalProps,
  useIntervalDateProps
} from "../../_hooks/useColumnProps";

export interface Props {}

const VoucherPage: React.FC<Props> = () => {
  const { data: discounts } = useSelectVals(new DiscountService());
  return (
    <BasicPage<Voucher>
      restService={new VoucherService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        },
        {
          label: "Code",
          ...useSearchProps("code")
        },
        {
          label: "Tag",
          ...useSearchProps("tag")
        },
        {
          label: "Priority",
          inputProps: { type: "number" },
          ...useIntervalProps("priority")
        },
        {
          label: "Res. from",
          inputProps: { type: "date" },
          ...useIntervalDateProps("reservationFrom")
        },
        {
          label: "Res. to",
          inputProps: { type: "date" },
          ...useIntervalDateProps("reservationTo")
        },
        {
          label: "Web",
          inputProps: { type: "switch" },
          ...useSearchProps("isForWeb", { isBoolean: true })
        },
        {
          label: "Mobile",
          inputProps: { type: "switch" },
          ...useSearchProps("isForMobileApp", { isBoolean: true })
        },
        {
          label: "Max per customer",
          inputProps: { type: "number" },
          ...useIntervalProps("customerUseCount")
        },
        {
          label: "Max overall",
          inputProps: { type: "number" },
          ...useIntervalProps("allUseCount")
        },
        {
          label: "Discount",
          inputProps: { type: "select" },
          selectOptions: discounts.map(({ name, id }) => {
            return { displayValue: name, dataValue: id };
          }),
          ...useSearchProps("discontId", {
            select: { values: discounts }
          })
        }
      ]}
    />
  );
};

export default VoucherPage;
