import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  FormHelperText
} from "@material-ui/core";
import UserService from "../../../helpers/model_services/UserService";

interface Props extends RouteComponentProps<{}> {}

interface State {
  email: string;
  open: boolean;
}

const userService = new UserService();

class PasswordRecovery extends React.Component<Props, State> {
  state = {
    email: "",
    open: false
  };

  handleClickOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    this.setState({ email: value });
  };

  handleSubmit = async (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    event.preventDefault();
    const { email } = this.state;
    userService.sendResetLink(email).then(({ status }) => {
      if (status === 200) {
        this.handleClose();
      }
    });
  };

  render() {
    const { email, open } = this.state;
    return (
      <React.Fragment>
        <FormHelperText className="uk-text-right">
          <a
            className="uk-link-muted uk-text-meta"
            onClick={this.handleClickOpen}
          >
            Forgot your password?
          </a>
        </FormHelperText>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Password recovery</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter an email address and we will send you instructions to
              recover your password.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Email address"
              type="email"
              value={email}
              fullWidth
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default withRouter(PasswordRecovery);
