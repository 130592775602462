import * as React from "react";
import { Button, Drawer, DatePicker, Select, InputNumber, Form } from "antd";
import Payment from "../../../model/Payment";
import InputField from "../../_common/InputField/InputField";
import Invoice from "../../../model/Invoice";
import { connect } from "react-redux";
import { requestActions } from "../../../redux/request/requestSlice";
import { AppState } from "../../../store/appStore";
import { Formik } from "formik";

export interface Props {
  onClose: () => void;
  onNew: () => void;
  visible: boolean;
  payment: Payment;
  invoices: Invoice[];
  onSubmit: (vals: Payment) => void
}


const PaymentForm: React.FC<Props> = ({
  visible,
  payment,
  onNew,
  onClose,
  invoices,
  onSubmit
}) => {
  return (
    <React.Fragment>
      <Button type="primary" size="small" className="dark" onClick={onNew}>
        New payment
      </Button>
      <Drawer
        width={500}
        onClose={onClose}
        visible={visible}
        style={{
          overflow: "auto",
          height: "calc(100% - 108px)",
          paddingBottom: "108px"
        }}
      >
          <Formik
      enableReinitialize
      initialValues={payment}
      onSubmit={onSubmit}
      render={formikProps => {
        return (
          <Form
            layout="horizontal"
            hideRequiredMark
            onSubmit={formikProps.handleSubmit}
          >
        
          <InputField
            type="date"
            label="Date"
            name="date"
            node={<DatePicker />}
          />
          <InputField
            type="select"
            label="Invoice ID"
            name="invoiceId"
            node={
              <Select<number> style={{ width: "100%" }}>
                {invoices.map(({ id, invoiceNumber }) => (
                  <Select.Option key={id} value={invoiceNumber}>
                    {invoiceNumber}
                  </Select.Option>
                ))}
              </Select>
            }
          />
          <InputField
            type="number"
            label="Amount"
            name="amount"
            node={<InputNumber />}
          />
          <InputField
            type="select"
            label="Method"
            name="method"
            node={
              <Select<string> style={{ width: "100%" }}>
                {["Cash", "Online", "Bank transfer"].map(val => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
              </Select>
            }
          />
          <InputField label="Bank account" name="bankAccount" />
          <InputField label="Variable symbol" name="variableSymbol" />

          <InputField label="Note" name="text" />
          <div
            style={{
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right"
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
          </Form>
        );
      }}
    />
      </Drawer>
    </React.Fragment>
  );
};

const mapStateToProps = ({
    requestDetail: {
      request: { itemId }
    },
    finance: {
      finance: { invoices }
    }
  }: AppState) => ({
    invoices,
    itemId: itemId.length === 0 ? 0 : itemId[0]
  });
  
  const mapDispatchToProps = {
    refresh: requestActions.fetchRequest
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PaymentForm);
  