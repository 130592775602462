import * as React from "react";
import { Table, Button, Input, Icon, Tooltip } from "antd";
import moment from "moment/moment.js";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import { VisaRequestListItem } from "../../helpers/model_services/VisaRequestService";
import { visaActions } from "../../redux/visa/visaSlice";
import { Mode } from "../../types";
import { travelerActions } from "../../redux/traveler/travelerSlice";
import ActionButtons from "../_common/ActionButtons";
import { CompareFn } from "antd/lib/table";
import Highlighter from "react-highlight-words";
import { Dispatch } from "redux";
import {
  useSearchProps,
  useIntervalDateProps,
  useIntervalProps
} from "../_hooks/useColumnProps";

export interface Props {
  data: VisaRequestListItem[];
  openVisa: (visaId: number) => void;
  setVisaMode: (newMode: Mode) => void;
  setTravelerMode: (newMode: Mode) => void;
  updateTraveler: (travelerId: number) => void;
  deleteVisa: (visaId: number) => void;
  visaText: string;
}

const VisaTable: React.FC<Props> = ({
  data,
  openVisa,
  setVisaMode,
  setTravelerMode,
  updateTraveler,
  deleteVisa,
  visaText
}) => {
  const setMode = (newMode: Mode) => {
    setVisaMode(newMode);
    setTravelerMode(newMode);
  };
  return (
    <Table<VisaRequestListItem>
      columns={[
        { dataIndex: "alphaId", className: "table-center", sorter: true },
        {
          title: "Traveler",
          width: "25%",
          ...useSearchProps("traveler")
        },
        {
          title: "Product",
          render: (text, record) => (
            <span>
              <Tooltip placement="top" title="Visa">
                <i className="far fa-id-card"></i>
              </Tooltip>{" "}
              {visaText} / {record.purpose}
            </span>
          ),
          className: "table-center",
          sorter: true
        },
        {
          title: "Date from",
          className: "table-center",
          ...useIntervalDateProps("dateFrom")
        },
        {
          title: "Date to",
          className: "table-center",
          ...useIntervalDateProps("dateTo")
        },
        {
          title: "Price",
          className: "table-center",
          ...useIntervalProps("price"),
          render: (text, { payStatus }) => (
            <React.Fragment>
              {`${text} SAR`}
              <Tooltip placement="top" title={payStatus}>
                <i
                  className={`fas fa-${
                    payStatus === "paid" ? "check" : "times"
                  }`}
                  style={{
                    color: payStatus === "paid" ? "limegreen" : "red",
                    marginLeft: 6
                  }}
                ></i>
              </Tooltip>
            </React.Fragment>
          )
        },
        {
          title: "Workflow",
          ...useSearchProps("status")
        },
        {
          title: "Actions",
          className: "table-center",
          width: "10%",
          render: (text, { id, travelerId }, index) => (
            <ActionButtons
              onOpen={() => {
                setMode("edit");
                updateTraveler(travelerId);
                openVisa(id);
              }}
              onDelete={() => deleteVisa(id)}
            />
          )
        }
      ]}
      dataSource={data}
      size="middle"
      rowKey={({ id }) => id.toString()}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
      }}
      onRow={(record, rowIndex) => ({
        onClick: event => {
          setMode("view");
          updateTraveler(record.travelerId);
          openVisa(record.id);
        }
      })}
    />
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { visaRequest, confirmedCountry, requestedCountry }
  }
}: AppState) => ({
  data: visaRequest.sort((a, b) => a.id - b.id),
  visaText: `${
    confirmedCountry
      ? `${
          confirmedCountry.regions !== null
            ? `${confirmedCountry.regions.name} / ${confirmedCountry.name}`
            : ""
        }`
      : requestedCountry
      ? `${
          requestedCountry!.regions !== null
            ? `${requestedCountry!.regions.name} / ${requestedCountry!.name}`
            : ""
        }`
      : ""
  }`
});

const mapDispatchToProps = {
  openVisa: visaActions.fetchVisaRequest,
  setVisaMode: visaActions.changeMode,
  setTravelerMode: travelerActions.changeMode,
  updateTraveler: travelerActions.fetchTraveler,
  deleteVisa: visaActions.deleteVisa
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisaTable);
