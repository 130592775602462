import * as React from "react";
import ServicePlaceTypeService from "../../../helpers/model_services/ServicePlaceTypeService";
import BasicPage from "../../_common/BasicPage/BasicPage";
import { useSearchProps } from "../../_hooks/useColumnProps";
import ServicePlaceType from "../../../model/ServicePlaceType";

export interface Props {}

const ServicePlaceTypesPage: React.FC<Props> = () => {
  return (
    <BasicPage<ServicePlaceType>
      restService={new ServicePlaceTypeService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        }
      ]}
    />
  );
};

export default ServicePlaceTypesPage;
