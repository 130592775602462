import * as React from "react";
import RoleService from "../../../helpers/model_services/RoleService";
import BasicPage from "../../_common/BasicPage/BasicPage";
import Role from "../../../model/Role";
import { useSearchProps } from "../../_hooks/useColumnProps";

export interface Props {}

const RolesPage: React.SFC<Props> = () => {
  return (
    <BasicPage<Role>
      restService={new RoleService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        }
      ]}
    />
  );
};

export default RolesPage;
