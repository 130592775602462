import axios from "axios";
import { message } from "antd";
axios.interceptors.response.use(undefined, error => {
  const expectedError = error.response && error.response.status === 200;

  if (!expectedError) {
    message.error(
      `Error! ${
        error.response && error.response.data
          ? error.response.data
          : error.toString()
      }`
    );
  }

  return Promise.reject(error);
});

function setJwt(jwt: string) {
  sessionStorage.setItem("token", jwt);
  /* tslint:disable:no-string-literal */
  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;
  /* tslint:enable:no-string-literal */
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  setJwt
};
