import * as React from "react";
import { RequestNote } from "../../model/Note";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import { Table, Button, Drawer, Row } from "antd";
import User from "../../model/User";
import MyCard from "../_common/MyCard";
import { Mode } from "../../types";
import NotesForm from "./NotesForm";
import moment from "moment/moment.js";
import ActionButtons from "../_common/ActionButtons";
import "../../css/NotesTable.css";
import { requestNoteActions } from "../../redux/request/requestNoteSlice";

export interface Props {
  data: RequestNote[];
  submitNote: (values: RequestNote) => Promise<boolean>;
  setMode: (mode: Mode) => void;
  loading: boolean;
}

const NotesTable: React.FC<Props> = ({
  data,
  submitNote,
  setMode,
  loading
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<RequestNote>(
    {} as RequestNote
  );
  return (
    <MyCard
      title="Notes"
      extra={
        <Button
          type="primary"
          className="dark"
          size="small"
          onClick={() => {
            setMode("new");
            setSelected({} as RequestNote);
            setVisible(true);
          }}
        >
          + Add note
        </Button>
      }
    >
      <Table<RequestNote>
        loading={loading}
        columns={[
          {
            title: "Note",
            render: (text, { text: note, updatedAt, createdAt }, index) => (
              <div className="note-container">
                <Row className="note">{note}</Row>
                <Row className="timestamp">
                  {`Last update: ${moment(
                    moment
                      .utc(updatedAt === null ? createdAt : updatedAt)
                      .toDate()
                  ).format("D.M.YYYY | HH:mm")}`}
                </Row>
              </div>
            )
          },
          {
            width: "10%",
            className: "table-center",
            dataIndex: "user",
            title: "Owner",
            render: (text: User) =>
              text !== null
                ? `${text.name !== null ? text.name : ""} ${
                    text.lastName !== null ? text.lastName : ""
                  }`
                : ""
          },
          {
            width: "10%",
            render: (text, record) => (
              <ActionButtons
                noDivider={true}
                onOpen={() => {
                  setMode("edit");
                  setSelected(record);
                  setVisible(true);
                }}
                onDelete={() => {
                  setMode("delete");
                  submitNote(record);
                }}
              />
            )
          }
        ]}
        dataSource={data}
        size="middle"
        rowKey={({ id }) => id.toString()}
        pagination={false}
      />
      <Drawer width={500} onClose={() => setVisible(false)} visible={visible}>
        <NotesForm<RequestNote>
          note={selected}
          onSubmit={values =>
            submitNote(values).then(res => {
              if (res) {
                setMode("view");
                setVisible(false);
                setSelected({ id: 0 } as RequestNote);
              }
            })
          }
          onCancel={() => {
            setMode("view");
            setVisible(false);
            setSelected({ id: 0 } as RequestNote);
          }}
        />
      </Drawer>
    </MyCard>
  );
};

const mapStateToProps = ({
  requestNotes: { requestNotes, loading }
}: AppState) => ({
  data: requestNotes,
  loading
});

const mapDispatchToProps = {
  submitNote: requestNoteActions.submitNote,
  setMode: requestNoteActions.setMode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotesTable);
