import * as React from "react";
import { Row, Select, Col, DatePicker } from "antd";
import InputField from "../_common/InputField/InputField";
import { Mode } from "../../types";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import MyCard from "../_common/MyCard";
import { OptionProps } from "antd/lib/select";
import useSelectVals from "../_hooks/useSelectVals";
import CountryService from "../../helpers/model_services/CountryService";
import FamilyMemberTypeService from "../../helpers/model_services/FamilyMembersTypeService";

export interface Props {
  mode: Mode;
}

const PersonalData: React.FC<Props> = ({ mode }) => {
  const disabled = mode === "view";
  const { data: countries } = useSelectVals(new CountryService());
  const { data: familyType } = useSelectVals(new FamilyMemberTypeService());
  return (
    <MyCard
      title="Personal data of family member EU, EEA, CH"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.personalFamilyMembersDataName"
            label="First name"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.personalFamilyMembersDataLastName"
            label="Surname"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.personalFamilyMembersDataDateOfBirth"
            label="Date of birth"
            node={<DatePicker />}
            type="date"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.personalFamilyMembersDataNationality"
            label="Nationality"
            type="select"
            node={
              <Select
                showSearch
                defaultActiveFirstOption={false}
                filterOption={(
                  inputValue: string,
                  option: React.ReactElement<OptionProps>
                ) =>
                  option.props
                    .children!.toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {countries.map(({ name, id }) => (
                  <Select.Option key={id} value={name}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.personalFamilyMembersDataDocType"
            label="Document type"
            type="select"
            node={
              <Select>
                {["ID CARD", "TRAVEL DOCUMENT"].map(val => (
                  <Select.Option key={val} value={val}>
                    {val}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="visa.personalFamilyMembersDataDocNumber"
            label="Document number"
          />
        </Col>
        <Col span={12}>
          <InputField
            disabled={disabled}
            name="visa.personalFamilyMembersDataRelationShip"
            label="Family relationship with EU/EEA or CH citizen"
            type="select"
            wrapperColSpan={7}
            labelColSpan={17}
            node={
              <Select>
                {familyType.map(({ id, name }) => (
                  <Select.Option key={id} value={name}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
      </Row>
    </MyCard>
  );
};

const mapStateToProps = ({ visa: { mode } }: AppState) => ({
  mode
});

export default connect(mapStateToProps)(PersonalData);
