import AbstractRestService from "./AbstractRestService";
import VisaEntryCount from "../../model/VisaEntryCount";

export default class VisaEntryCountService extends AbstractRestService<
  VisaEntryCount
> {
  constructor() {
    super("Codelists/VisaEntryCount");
  }
}
