import * as React from "react";
import "./App.css";
import SideBar from "./SideBar";
import { ToastContainer, Bounce, toast } from "react-toastify";
import { RouteComponentProps, withRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import Routes from "./Routes/Routes";
import { Layout } from "antd";
import HeaderBar from "./HeaderBar/HeaderBar";

const { Content, Sider, Footer } = Layout;

const App: React.SFC<RouteComponentProps> = () => (
  <Layout style={{ width: "100%", height: "100%" }}>
    <Layout>
      <Sider>
        <div className="logo">DUNIA ALREHLAT</div>
        <SideBar />
      </Sider>
      <Layout>
        <HeaderBar />
        <Content>
          <Routes />
          <ToastContainer
            autoClose={2500}
            transition={Bounce}
            position={toast.POSITION.TOP_CENTER}
            pauseOnHover={false}
          />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Fox Media Czech Republic ©{new Date().getFullYear()} v3.7.1
        </Footer>
      </Layout>
    </Layout>
  </Layout>
);

export default withRouter(App);
