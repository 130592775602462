import * as React from "react";
import { Table, Card, Select, Icon, Button } from "antd";
import RoleService from "../../../helpers/model_services/RoleService";
import Role from "../../../model/Role";
import RequestItemStatusesService from "../../../helpers/model_services/RequestItemStatusesService";
import RequestItemStatus from "../../../model/RequestItemStatus";
const { Column } = Table;
const { Option } = Select;

export interface Props {}

export interface State {
  roles: Role[];
  statuses: RequestItemStatus[];
  data: any[];
}

class WorkflowPage extends React.Component<Props> {
  private roleService = new RoleService();
  private requestItemStatusesService = new RequestItemStatusesService();

  state: State = {
    roles: [],
    statuses: [],
    data: []
  };

  async componentDidMount() {
    const { data: roles } = await this.roleService.getAll();
    const { data: statuses } = await this.requestItemStatusesService.getAll();
    const data = roles.map(role => {
      const _roleStatuses: {
        statusId: number;
        right: string;
        roleId: number;
      }[] = [
        {
          statusId: 10,
          right: "edit",
          roleId: 8
        },
        {
          statusId: 20,
          right: "edit",
          roleId: 8
        },
        {
          statusId: 30,
          right: "edit",
          roleId: 8
        },
        {
          statusId: 40,
          right: "edit",
          roleId: 8
        },
        {
          statusId: 50,
          right: "edit",
          roleId: 8
        },
        {
          statusId: 60,
          right: "edit",
          roleId: 8
        },
        {
          statusId: 70,
          right: "edit",
          roleId: 8
        }
      ];
      const tmp = statuses.map(status => {
        const findStatus = _roleStatuses.find(
          ({ statusId, roleId }) => statusId === status.id && roleId === role.id
        );
        return findStatus ? { ...status, ...findStatus } : status;
      });
      return { ...role, statuses: tmp };
    });

    this.setState({ statuses, roles, data });
  }

  render() {
    return (
      <Card
        style={{
          background: "#fff",
          margin: 24,
          marginTop: 84,
          minHeight: 280,
          padding: 0
        }}
        title="Workflow - VISA"
      >
        <Table dataSource={this.state.data}>
          <Column
            title="Role"
            dataIndex="name"
            key="name"
            render={text => <strong>{text}</strong>}
          />
          {this.state.statuses.map(({ name }, index) => (
            <Column
              title={name}
              dataIndex={"statuses[" + index + "].right"}
              key={"statuses[" + index + "].right#" + name}
              render={(text, record, index) => {
                return (
                  <span>
                    <Select
                      defaultValue={text ? text : "no access"}
                      dropdownMatchSelectWidth
                      style={{ width: "110px" }}
                    >
                      <Option value="read-only">read-only</Option>
                      <Option value="edit">edit</Option>
                      <Option value="no access">no access</Option>
                    </Select>
                    <Button type="link">
                      <Icon type="edit" />
                    </Button>
                  </span>
                );
              }}
            />
          ))}
        </Table>
      </Card>
    );
  }
}

export default WorkflowPage;
