import * as React from "react";
import BasicPage from "../../_common/BasicPage/BasicPage";
import FamilyMemberType from "../../../model/FamilyMemberType";
import FamilyMemberTypeService from "../../../helpers/model_services/FamilyMembersTypeService";
import { useSearchProps } from "../../_hooks/useColumnProps";

export interface Props {}

const FamilyMembersTypePage: React.FC<Props> = () => {
  return (
    <BasicPage<FamilyMemberType>
      restService={new FamilyMemberTypeService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        },
        {
          label: "Arabic name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name_ARA")
        }
      ]}
    />
  );
};

export default FamilyMembersTypePage;
