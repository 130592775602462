import AbstractRestService from "./model_services/AbstractRestService";
import * as React from "react";
import BaseClass from "../model/_BaseClass";
import { Mode, SelectPair } from "../types";
import { Select, message } from "antd";

export function handleSubmit<T extends BaseClass>(
  service: AbstractRestService<T>,
  action: Mode,
  setAction: (value: React.SetStateAction<Mode>) => void
) {
  return async (values: T) => {
    let status = 0;
    switch (action) {
      case "new": {
        const { status: _status } = await service.create(values);
        status = _status;
        break;
      }
      case "edit": {
        const { status: _status } = await service.update(values);
        status = _status;
        break;
      }
      case "delete": {
        if (values.id) {
          const { status: _status } = await service.delete(values);
          status = _status;
        }
        break;
      }
      default:
        break;
    }
    if (status === 200) {
      message.success("Success.");
      setAction("view");
      return true;
    } else {
      message.error("Error!");
      return false;
    }
  };
}

export async function getSelectVals(
  service: AbstractRestService<BaseClass>,
  apiAddress?: string
) {
  if (apiAddress) {
    const { data } = await service.getCustomAll(apiAddress);
    return {
      data
    };
  } else {
    const { data } = await service.getAll();
    return {
      data
    };
  }
}

export function getSelectValues(data: BaseClass[]): SelectPair[] {
  return data.map(({ name, id }) => {
    return { displayValue: name, dataValue: id };
  });
}

export function getSelectOptions(data: BaseClass[]): React.ReactNode {
  return (
    <React.Fragment>
      {data.map(({ name, id }) => (
        <Select.Option key={id} value={id}>
          {name}
        </Select.Option>
      ))}
    </React.Fragment>
  );
}
