import * as React from "react";
import {
  Modal,
  Icon,
  Table,
  Select,
  Col,
  Row,
  Divider,
  Tooltip,
  Button,
  Popconfirm
} from "antd";
import TravelerDocument from "../../model/TravelerDocument";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";
import { Upload, message } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import useSelectVals from "../_hooks/useSelectVals";
import DocumentsTypesService from "../../helpers/model_services/DocumentsTypesService";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import moment from "moment/moment.js";
import MyCard from "../_common/MyCard";
import { travelerActions } from "../../redux/traveler/travelerSlice";
import { requestActions } from "../../redux/request/requestSlice";
import DocumentType from "../../model/DocumentType";
import DeletedDocuments from "./DeletedDocuments";
import { visaActions } from "../../redux/visa/visaSlice";

const Dragger = Upload.Dragger;

export interface Props {
  docs: TravelerDocument[];
  fetchTraveler: (travelerId?: number) => void;
  fetchRequest: () => Promise<boolean>;
  travelerId: number;
  isEditMode: boolean;
  docTypeFilter?: (
    value: DocumentType,
    index: number,
    array: DocumentType[]
  ) => boolean;
  title?: string;
  inProductDetail?: boolean;
  fetchVisaRequest: () => void;
}

const docService = new TravelerDocumentsService();

const TravelerAttachments: React.FC<Props> = ({
  docs,
  fetchTraveler,
  travelerId,
  isEditMode,
  fetchRequest,
  docTypeFilter = dt => [1, 2, 3, 4].includes(dt.id),
  title = "Attachments",
  inProductDetail = false,
  fetchVisaRequest
}) => {
  const { data: docTypes } = useSelectVals(new DocumentsTypesService());
  const [docType, setDocType] = React.useState<number>(11);
  const [historyDocTypeId, setHistoryDocTypeId] = React.useState<number>(0);
  const [visible, setVisible] = React.useState<boolean>(false);
  const defaultDocs: TravelerDocument[] = [];
  const defaultDocTypes = docTypes.filter(docTypeFilter);
  defaultDocTypes.forEach(dt => {
    if (
      docs.filter(({ documentsTypesId }) => documentsTypesId === dt.id)
        .length === 0
    ) {
      const newDt = {
        documentsTypesId: dt.id,
        documentsTypes: dt
      } as TravelerDocument;
      defaultDocs.push(newDt);
    }
  });

  const _docs = docs.concat(defaultDocs);

  return (
    <MyCard title={title}>
      <Row gutter={24} type="flex">
        <Col span={16}>
          <Table<TravelerDocument>
            size="middle"
            dataSource={_docs.sort(
              (
                { documentsTypes: { order: orderA } },
                { documentsTypes: { order: orderB } }
              ) => orderA - orderB
            )}
            rowKey={({ id, documentsTypes }) =>
              id ? id.toString() : documentsTypes.name
            }
            pagination={{
              defaultPageSize: 5,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "15"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
            }}
            columns={[
              {
                width: 30,
                render: (text, { note, documentsTypes: { iconName } }) =>
                  note && note !== null ? (
                    <Tooltip placement="top" title={note}>
                      <i style={{ fontSize: 20 }} className={iconName} />
                    </Tooltip>
                  ) : (
                    <i style={{ fontSize: 20 }} className={iconName} />
                  )
              },
              {
                title: "Requested",
                render: (text, { documentsTypes }) =>
                  documentsTypes ? documentsTypes.name : null
              },
              {
                dataIndex: "createdAt",
                title: "Created",
                className: "table-center",
                render: text =>
                  text !== null && text ? moment(text).format("D.M.YYYY") : null
              },
              {
                dataIndex: "responsibilityPerson",
                className: "table-center",
                title: "Responsible"
              },
              {
                title: "Time machine",
                className: "table-center",
                render: (text, { id, documentsTypesId }) =>
                  id ? (
                    <Tooltip placement="top" title="History">
                      <Button
                        type="link"
                        style={{ padding: 0 }}
                        onClick={e => {
                          setHistoryDocTypeId(documentsTypesId);
                          setVisible(true);
                        }}
                      >
                        <i
                          style={{ fontSize: 20, color: "#fcad1c" }}
                          className="fas fa-history fa-2x"
                        />
                      </Button>
                    </Tooltip>
                  ) : null
              },
              {
                render: (text, doc, index) => (
                  <span>
                    {doc.id ? (
                      <Tooltip placement="top" title="View">
                        <a
                          className="no-color"
                          onClick={() => {
                            docService
                              .getDocumentURL(doc.id)
                              .then(({ data: { documentUrl } }) => {
                                const link = document.createElement("a");
                                link.href = documentUrl;
                                link.target = "_blank";
                                link.rel = "noopener noreferrer";
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              });
                          }}
                        >
                          <Icon
                            type="eye"
                            theme="twoTone"
                            twoToneColor="#fcad1c"
                            style={{ fontSize: 20 }}
                          />
                        </a>
                      </Tooltip>
                    ) : (
                      <Button type="link" style={{ padding: 0 }} disabled>
                        <Icon
                          type="eye"
                          theme="twoTone"
                          twoToneColor="#cdcdcd"
                          style={{ fontSize: 20 }}
                        />
                      </Button>
                    )}
                    {isEditMode ? (
                      <React.Fragment>
                        <Divider type="vertical" />
                        <Upload
                          showUploadList={false}
                          name="files"
                          customRequest={({
                            onSuccess,
                            onError,
                            onProgress,
                            file
                          }: any) => {
                            docService
                              .uploadFile(
                                travelerId,
                                doc.documentsTypesId,
                                file
                              )
                              .then(response => {
                                onSuccess(response, file);
                                fetchRequest();
                                if (inProductDetail) {
                                  fetchVisaRequest();
                                }
                              });
                          }}
                          multiple={false}
                          onChange={(info: UploadChangeParam) => {
                            if (info.file.status !== "uploading") {
                              /* console.log(info.file, info.fileList); */
                            }
                            if (info.file.status === "done") {
                              fetchTraveler();
                              message.success(
                                `${info.file.name} file uploaded successfully`
                              );
                            } else if (info.file.status === "error") {
                              message.error(
                                `${info.file.name} file upload failed.`
                              );
                            }
                          }}
                        >
                          <Tooltip placement="top" title="Upload">
                            <Button style={{ padding: 0 }} type="link">
                              <Icon
                                type="edit"
                                theme="twoTone"
                                twoToneColor="#fcad1c"
                                style={{ fontSize: 20 }}
                              />
                            </Button>
                          </Tooltip>
                        </Upload>
                        <Divider type="vertical" />
                        <Popconfirm
                          placement="top"
                          title="Are you sure you want to delete this record?"
                          onConfirm={() =>
                            docService.delete(doc).then(({ status }) => {
                              if (status === 200) {
                                fetchRequest();
                                message.success("Success");
                              }
                            })
                          }
                          okText="Yes"
                          cancelText="No"
                        >
                          <Tooltip placement="top" title="Delete">
                            <Button type="link" style={{ padding: 0 }}>
                              <Icon
                                type="delete"
                                theme="twoTone"
                                twoToneColor="#ed1b24"
                                style={{ fontSize: 20 }}
                              />
                            </Button>
                          </Tooltip>
                        </Popconfirm>
                      </React.Fragment>
                    ) : null}
                  </span>
                )
              }
            ]}
          />
        </Col>
        {isEditMode && (
          <Col span={8}>
            <Dragger
              style={{ height: "100%" }}
              showUploadList={false}
              name="files"
              customRequest={({
                onSuccess,
                onError,
                onProgress,
                file
              }: any) => {
                docService
                  .uploadFile(travelerId, docType, file)
                  .then(response => {
                    onSuccess(response, file);
                    fetchRequest();
                    if (inProductDetail) {
                      fetchVisaRequest();
                    }
                  });
              }}
              beforeUpload={() =>
                new Promise<void>((res, rej) => {
                  Modal.confirm({
                    title: "Please select document type.",
                    okText: "Ok",
                    cancelText: "Cancel",
                    onOk: () => res(),
                    onCancel: () => rej(),
                    content: (
                      <Select<number>
                        defaultValue={11}
                        style={{ width: "100%" }}
                        onChange={value => setDocType(value)}
                      >
                        {docTypes
                          .sort(({ name: aName }, { name: bName }) =>
                            aName.localeCompare(bName)
                          )
                          .map(({ name, id }) => (
                            <Select.Option key={id} value={id}>
                              {name}
                            </Select.Option>
                          ))}
                      </Select>
                    )
                  });
                })
              }
              multiple={false}
              onChange={(info: UploadChangeParam) => {
                if (info.file.status !== "uploading") {
                  /* console.log(info.file, info.fileList); */
                }
                if (info.file.status === "done") {
                  fetchTraveler();
                  message.success(
                    `${info.file.name} file uploaded successfully`
                  );
                } else if (info.file.status === "error") {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
            >
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single upload only
              </p>
            </Dragger>
          </Col>
        )}
      </Row>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={() => setVisible(false)}
      >
        <DeletedDocuments
          travelerId={travelerId}
          docTypeId={historyDocTypeId}
          visible={visible}
        />
      </Modal>
    </MyCard>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelersDocuments }
  },
  traveler: {
    traveler: { id }
  }
}: AppState) => ({
  travelerId: id,
  docs: travelersDocuments
    .filter(doc => doc.travelersId === id && doc.documentUrl !== null)
    .sort(({ documentsTypes: { name: a } }, { documentsTypes: { name: b } }) =>
      a.localeCompare(b)
    )
});

const mapDispatchToProps = {
  fetchTraveler: travelerActions.fetchTraveler,
  fetchRequest: requestActions.fetchRequest,
  fetchVisaRequest: visaActions.fetchVisaRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TravelerAttachments);
