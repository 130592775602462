import * as React from "react";
import Customer from "../../model/Customer";
import {
  Divider,
  Row,
  Col,
  Icon,
  Tooltip,
  Button,
  Modal,
  Select,
  message,
  Form
} from "antd";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import { Typography } from "antd";
import "../../css/ModalHeader.css";
import getName from "../../helpers/MyFunctions";
import CustomerService, {
  CustomerNames
} from "../../helpers/model_services/CustomerService";
import { OptionProps } from "antd/lib/select";
import { requestActions } from "../../redux/request/requestSlice";
import { Formik } from "formik";
import CustomerInput from "../customer/CustomerInput";

const { Title, Text } = Typography;

export interface Props {
  reqId: number;
  bcId: number;
  contacter: Customer;
  price: number;
  changeCustomer: (customerId: number) => Promise<boolean>;
}

const service = new CustomerService();

const RequestHeader: React.FC<Props> = ({
  reqId,
  bcId,
  contacter: {
    name,
    lastName,
    email,
    customerContact,
    contactTelephone: _contactTelephone
  },
  price,
  changeCustomer
}) => {
  const contactTelephoneId = customerContact.findIndex(
    c => c.contactTypeId === 3
  );
  const contactTelephone =
    contactTelephoneId === -1
      ? _contactTelephone
      : customerContact[contactTelephoneId].contactValue;
  const [visible, setVisible] = React.useState<boolean>(false);
  const idTitle =
    bcId !== null ? `Business case no. ${bcId}` : `Request no. ${reqId}`;
  const contacterName = getName(name, lastName);

  return (
    <Row className="dunia-header" gutter={24}>
      <Col span={10}>
        <Title className="dunia-header" level={3}>
          {idTitle}
        </Title>
        <Divider className="dunia-header" type="vertical" />
        <Text className="dunia-header" strong={true}>
          Contacter:
        </Text>
        <Text className="dunia-header">{contacterName}</Text>
      </Col>
      <Col span={5}>
        <Text className="dunia-header" strong>
          Tel.:
        </Text>
        <Text className="dunia-header">
          {contactTelephone === null ? "-" : contactTelephone}
        </Text>
      </Col>
      <Col span={5}>
        <Text className="dunia-header" strong>
          Email:
        </Text>
        <Text className="dunia-header">
          {email === null || contacterName === "Anonymous Request"
            ? "-"
            : email}
        </Text>
      </Col>
      <span style={{ textAlign: "right" }}>
        <Tooltip placement="top" title="Edit">
          <Button
            type="link"
            onClick={() => setVisible(true)}
            style={{ padding: 0, marginLeft: 12, marginRight: 16 }}
          >
            <Icon
              type="edit"
              theme="twoTone"
              twoToneColor="#fcad1c"
              style={{ fontSize: 24 }}
            />
          </Button>
        </Tooltip>
        <Title className="dunia-header price" level={4}>
          TOTAL: {price} SAR
        </Title>
      </span>
      <Formik<{ contacterId: number }>
        enableReinitialize
        initialValues={{} as any}
        onSubmit={({ contacterId }, { resetForm }) => {
          changeCustomer(contacterId).then(res => {
            if (res) {
              message.success("Contacter was changed.");
              setVisible(false);
              resetForm();
            }
          });
        }}
        render={({ handleSubmit, setFieldValue, resetForm }) => (
          <Modal
            visible={visible}
            title="Changing contacter"
            onCancel={() => {
              setVisible(false);
              resetForm();
            }}
            onOk={e => handleSubmit()}
            style={{ top: 10 }}
          >
            <CustomerInput
              setFieldValue={setFieldValue}
              name="contacterId"
              label="Contacter"
            />
          </Modal>
        )}
      ></Formik>
    </Row>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { id: reqId, businessCaseId: bcId, customer: contacter }
  },
  finance: {
    finance: { fees }
  }
}: AppState) => ({
  reqId,
  bcId,
  contacter,
  price: fees.reduce(
    (sum, val) => sum + (val.finalPrice === 0 ? val.sellPrice : val.finalPrice),
    0
  )
});

export default connect(
  mapStateToProps,
  { changeCustomer: requestActions.changeCustomer }
)(RequestHeader);
