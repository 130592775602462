import React from "react";
import { Card } from "antd";
import LoginForm from "../../LoginForm";

const LoginPage: React.SFC = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Card style={{ width: 300 }}>
        <LoginForm />
      </Card>
    </div>
  );
};

export default LoginPage;
