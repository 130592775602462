import * as React from "react";
import { Row, Modal, Col, Form, message } from "antd";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import { travelerActions } from "../../redux/traveler/travelerSlice";
import TravelerForm from "./TravelerForm";
import TravelerAttachments from "./TravelerAttachments";
import { Dispatch } from "redux";
import TravelerHeader from "./TravelerHeader";
import Traveler from "../../model/Traveler";
import Notes from "../_common/Notes";
import { travelerNoteActions } from "../../redux/traveler/travelerNotesSlice";
import { Formik } from "formik";
import { Mode } from "../../types";

export interface Props {
  visible: boolean;
  changeMode: (newMode: Mode) => void;
  setVisible: (v: boolean) => void;
  traveler: Traveler;
  mode: Mode;
  submitTraveler: (val: Traveler) => Promise<boolean>;
}

const TravelerDetail: React.SFC<Props> = ({
  visible,
  changeMode,
  setVisible,
  traveler,
  mode,
  submitTraveler
}) => {
  const onClose = () => {
    setVisible(false);
    changeMode("view");
  };
  return (
    <Modal
      visible={visible}
      width="98%"
      title={<TravelerHeader />}
      onCancel={onClose}
      style={{ top: 10 }}
      footer={null}
    >
      <Row>
        <Formik<{ traveler: Traveler }>
          enableReinitialize
          initialValues={{
            traveler: mode === "new" ? ({} as Traveler) : traveler
          }}
          onSubmit={({ traveler }) =>
            submitTraveler(traveler).then(res => {
              if (res) {
                message.success("Traveler was succesfully updated");
              }
            })
          }
          render={({
            handleSubmit,
            values: {
              traveler: { recognizedData, identityRecognizedData }
            }
          }) => {
            return (
              <Row>
                <Form
                  layout="horizontal"
                  hideRequiredMark
                  onSubmit={handleSubmit}
                >
                  <TravelerForm
                    recognizedData={recognizedData}
                    travelerDetail
                    identityRecognizedData={identityRecognizedData}
                  />
                </Form>
              </Row>
            );
          }}
        />
      </Row>
      <Row>
        <TravelerNotes travelerId={traveler.id} />
      </Row>
      <Row>
        <TravelerAttachments isEditMode={mode === "edit"} />
      </Row>
    </Modal>
  );
};

const TravelerNotes = connect(
  ({
    travelerNotes: { travelerNotes, mode, loading },
    traveler: { mode: travelerMode }
  }: AppState) => ({
    notes: travelerNotes,
    mode,
    loading,
    disabled: travelerMode === "view"
  }),
  {
    setMode: travelerNoteActions.setMode,
    onSubmit: travelerNoteActions.submitNote
  }
)(Notes);

const mapStateToProps = ({
  traveler: { traveler, visible, mode }
}: AppState) => ({
  visible,
  traveler,
  mode
});

const mapDispatchToProps = {
  setVisible: travelerActions.setVisible,
  changeMode: travelerActions.changeMode,
  submitTraveler: travelerActions.submitTraveler
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TravelerDetail);
