import * as React from "react";
import MyCard from "../_common/MyCard";
import {
  FieldArray,
  FormikProps,
  ArrayHelpers,
  Field,
  FieldProps
} from "formik";
import InputField from "../_common/InputField/InputField";
import TravelerFamilyMember from "../../model/TravelerFamilyMember";
import {
  Button,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Icon,
  Modal,
  Form,
  Select
} from "antd";
import Traveler from "../../model/Traveler";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import getName from "../../helpers/MyFunctions";
import useSelectVals from "../_hooks/useSelectVals";
import FamilyMemberTypeService from "../../helpers/model_services/FamilyMembersTypeService";
import VisaRequest from "../../model/VisaRequest";
import { Mode } from "../../types";

export interface Props {
  disabled: boolean;
  travelers: Traveler[];
}

const Family: React.FC<Props> = ({ disabled, travelers }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [travelerId, setTravelerId] = React.useState<number>(0);
  const { data: memberTypes } = useSelectVals(new FamilyMemberTypeService());
  return (
    <FieldArray
      name="traveler.travelerFamilyMembers"
      render={({
        push,
        remove,
        form: {
          values: {
            traveler: { travelerFamilyMembers }
          },
          setFieldValue
        }
      }: ArrayHelpers & {
        form: FormikProps<{ traveler: Traveler; visa: VisaRequest }>;
        name: string;
      }) => (
        <MyCard
          title="Family"
          headStyle={{ backgroundColor: "#f2f2f2" }}
          extra={
            <span>
              <Button
                disabled={disabled}
                type="primary"
                className="dark"
                style={{ marginRight: 12 }}
                onClick={() => {
                  setVisible(true);
                }}
                size="small"
              >
                CHOOSE
              </Button>
              <Modal
                visible={visible}
                onCancel={() => {
                  setVisible(false);
                  setTravelerId(0);
                }}
                onOk={() => {
                  const index = travelers.findIndex(t => t.id === travelerId);
                  if (index !== -1) {
                    const {
                      name,
                      lastName,
                      dateOfBirth,
                      placeOfBirth
                    } = travelers[index];
                    push({
                      name,
                      lastName,
                      dateOfBirth,
                      placeOfBirth
                    } as TravelerFamilyMember);
                  }
                  setVisible(false);
                }}
                okButtonProps={{ disabled: travelerId === 0 }}
              >
                <Form.Item
                  label="Choose a traveler"
                  style={{ marginBottom: 12 }}
                >
                  <Select<number>
                    style={{ width: "100%" }}
                    autoFocus
                    onChange={value => setTravelerId(value)}
                  >
                    {travelers.map(({ id, name, lastName }) => (
                      <Select.Option key={id} value={id}>
                        {getName(name, lastName)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Modal>
              <Button
                type="primary"
                className="dark"
                onClick={() => push({} as TravelerFamilyMember)}
                size="small"
                disabled={disabled}
              >
                + ADD NEW
              </Button>
            </span>
          }
        >
          {travelerFamilyMembers
            .filter(t => !t.isDeleted)
            .map((member, index) => (
              <MyCard
                key={index}
                title={
                  <Field
                    disabled={disabled}
                    name={`traveler.travelerFamilyMembers.${index}.familyMembersType`}
                    render={({ field }: FieldProps<Traveler>) => (
                      <Tooltip placement="top" title="Family member type">
                        <Select<string>
                          {...field}
                          onChange={val =>
                            setFieldValue(
                              `traveler.travelerFamilyMembers.${index}.familyMembersType`,
                              val
                            )
                          }
                          disabled={disabled}
                          style={{ width: 200 }}
                        >
                          {memberTypes.map(({ id, name }) => (
                            <Select.Option key={id} value={name}>
                              {name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Tooltip>
                    )}
                  />
                }
                headStyle={{ backgroundColor: "#f2f2f2" }}
                extra={
                  <Tooltip placement="top" title={"Delete"}>
                    <Button
                      disabled={member.isDeleted || disabled}
                      type="link"
                      onClick={() => {
                        member.id
                          ? setFieldValue(
                              `traveler.travelerFamilyMembers.${travelerFamilyMembers.findIndex(
                                m => m.id === member.id
                              )}.isDeleted`,
                              true
                            )
                          : remove(index);
                      }}
                    >
                      <Icon
                        type="delete"
                        theme="twoTone"
                        twoToneColor="#ed1b24"
                        style={{ fontSize: 20 }}
                      />
                    </Button>
                  </Tooltip>
                }
              >
                <Row>
                  <Col span={6}>
                    <InputField
                      disabled={disabled}
                      name={`traveler.travelerFamilyMembers.${index}.name`}
                      label="First name"
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      disabled={disabled}
                      name={`traveler.travelerFamilyMembers.${index}.lastName`}
                      label="Surname"
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      disabled={disabled}
                      name={`traveler.travelerFamilyMembers.${index}.dateOfBirth`}
                      label="Date of birth"
                      node={<DatePicker />}
                      type="date"
                    />
                  </Col>
                  <Col span={6}>
                    <InputField
                      disabled={disabled}
                      name={`traveler.travelerFamilyMembers.${index}.placeOfBirth`}
                      label="Place of birth"
                    />
                  </Col>
                </Row>
              </MyCard>
            ))}
        </MyCard>
      )}
    />
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelers }
  },
  traveler: {
    traveler: { id }
  }
}: AppState) => ({
  travelers: travelers.filter(t => t.id !== id)
});

export default connect(mapStateToProps)(Family);
