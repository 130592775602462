import AbstractRestService from "./AbstractRestService";
import ServiceTypePlaceType from "../../model/ServicePlaceType";

export default class ServicePlaceTypeService extends AbstractRestService<
  ServiceTypePlaceType
> {
  constructor() {
    super("Codelists/ServicePlaceType");
  }
}
