import * as React from "react";
import {
  Collapse,
  Button,
  Row,
  Col,
  Form,
  message,
  Select,
  Radio,
  DatePicker
} from "antd";
import { Formik } from "formik";
import InputField from "../../_common/InputField/InputField";
import Invoice from "../../../model/Invoice";
import TextArea from "antd/lib/input/TextArea";
import FeeTable from "./FeeTable";
import CountryService from "../../../helpers/model_services/CountryService";
import { OptionProps } from "antd/lib/select";
import Country from "../../../model/Country";
import { connect } from "react-redux";
import { AppState } from "../../../store/appStore";
import InvoiceService from "../../../helpers/model_services/InvoiceService";
import { requestActions } from "../../../redux/request/requestSlice";
import User from "../../../model/User";
import Company from "../../../model/Company";
import CompanyService from "../../../helpers/model_services/CompanyService";
import Customer from "../../../model/Customer";
import Fee from "../../../model/Fee";
const { Panel } = Collapse;

export interface Props {
  reqItemId: number;
  refresh: () => void;
  user: User;
  contacter: Customer;
  businnessPartner: string;
  businnessPartnerMail: string;
  businnessPartnerTel: string;
  fees: Fee[];
}

export interface State {
  countries: Country[];
  opened: boolean;
  company: Company;
  disabled: boolean;
  selectedPrice: number;
  selectedFeeKeys: string[] | number[];
}

class NewInvoice extends React.Component<Props> {
  service = new InvoiceService();
  companyService = new CompanyService();
  countryService = new CountryService();

  state: State = {
    countries: [],
    opened: false,
    company: {} as Company,
    disabled: false,
    selectedFeeKeys: [],
    selectedPrice: 0
  };

  async componentDidMount() {
    try {
      const { data: countries } = await this.countryService.getAll();
      const { data: company } = await this.companyService.getById(1);
      this.setState({ countries, company });
    } catch (error) {}
  }

  render() {
    {
      const {
        reqItemId,
        refresh,
        user,
        contacter: {
          name,
          lastName,
          contactAddress,
          countryOfBirthId,
          contactMail,
          contactTelephone
        },
        businnessPartner,
        businnessPartnerMail,
        businnessPartnerTel,
        fees
      } = this.props;
      const { name: supplierName, lastName: supplierLastName } = user;
      const {
        company,
        opened,
        disabled,
        countries,
        selectedPrice,
        selectedFeeKeys
      } = this.state;

      const contacterVals = {
        subscriberName: name,
        subscriberLastName: lastName,
        subscriberAddress: contactAddress,
        subscriberCountryId: countryOfBirthId,
        subscriberEmail: contactMail,
        subscriberPhone: contactTelephone,
        subscriberCompany: null,
        subscriberCompanyVAT: null,
        isForOrganization: false
      };

      const businessPartnerVals = {
        subscriberCompany: businnessPartner,
        subscriberEmail: businnessPartnerMail,
        subscriberPhone: businnessPartnerTel,
        subscriberName: null,
        subscriberLastName: null,
        subscriberAddress: null,
        subscriberCountryId: null,
        subscriberCompanyVAT: null,
        isForOrganization: true
      };

      const somebodyElse = {
        subscriberCompany: null,
        subscriberEmail: null,
        subscriberPhone: null,
        subscriberName: null,
        subscriberLastName: null,
        subscriberAddress: null,
        subscriberCountryId: null,
        subscriberCompanyVAT: null,
        isForOrganization: false
      };

      const defVals: Invoice = {
        ...contacterVals,
        supplierName,
        supplierLastName,
        supplierCompany: company.name,
        supplierCompanyVAT: company.vat,
        supplierAddress: company.address,
        supplierCountryId: company.countryId,
        supplierEmail: company.email,
        supplierPhone: company.phone,
        supplierBankConnection: company.bankConnection,
        isInvoice: false,
        requestItemId: reqItemId,
        clientType: "contacter",
        isForOrganization: false,
        dateOfIssue: new Date(),
        dueDate: new Date()
      } as Invoice;

      return (
        <Formik<Invoice>
          initialValues={defVals}
          onSubmit={async ({ fees, ...rest }, { setFieldValue, resetForm }) => {
            try {
              /* const filteredFees: Fee[] = [];
              fees.forEach(f => {
                if (selectedRowKeys.includes(String(f.id))) {
                  filteredFees.push(f);
                }
              }); */
              if (fees && fees.length > 0) {
                const { data: newId } = await this.service.create(rest);
                await this.service.addFees(newId, fees);
                refresh();
                message.success(
                  `${
                    rest.isInvoice ? "Invoice" : "Payment order"
                  } was succesfully created.`
                );
                resetForm();
              } else {
                message.error("Error! No fees selected!");
              }
            } catch (error) {}
          }}
          onReset={(values, { setValues, setFieldValue }) => {
            setValues(defVals);
            setFieldValue("fees", undefined);
            this.setState({ selectedFeeKeys: [] });
          }}
          render={({
            handleSubmit,
            handleReset,
            setFieldValue,
            values,
            setValues
          }) => {
            return (
              <Collapse
                style={{ margin: 12 }}
                onChange={key => {
                  this.setState({ opened: key.length > 0 });
                }}
              >
                <Panel
                  header="New payment order / invoice"
                  key="1"
                  extra={
                    opened ? (
                      <span>
                        <Button
                          type="primary"
                          className="dark"
                          size="small"
                          onClick={e => {
                            e.stopPropagation();
                            handleSubmit();
                          }}
                          style={{ marginRight: 12 }}
                        >
                          Save
                        </Button>
                        <Button
                          type="primary"
                          className="orange"
                          size="small"
                          onClick={e => {
                            e.stopPropagation();
                            handleReset();
                          }}
                        >
                          Reset
                        </Button>
                      </span>
                    ) : (
                      undefined
                    )
                  }
                >
                  <Form
                    layout="horizontal"
                    hideRequiredMark
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      <Col span={8}>
                        <InputField
                          labelColSpan={6}
                          wrapperColSpan={18}
                          label="Type"
                          name="isInvoice"
                          type="radio"
                          node={
                            <Radio.Group>
                              {["Payment order", "Invoice"].map(val => (
                                <Radio key={val} value={val === "Invoice"}>
                                  {val}
                                </Radio>
                              ))}
                            </Radio.Group>
                          }
                        />
                      </Col>
                      <Col span={8}>
                        <InputField
                          labelColSpan={6}
                          wrapperColSpan={18}
                          name="dateOfIssue"
                          label="Date of issue"
                          node={<DatePicker />}
                          type="date"
                        />
                      </Col>
                      <Col span={8}>
                        <InputField
                          labelColSpan={6}
                          wrapperColSpan={18}
                          name="dueDate"
                          label="Expiry date"
                          node={<DatePicker />}
                          type="date"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Collapse
                        style={{ marginTop: 12, marginBottom: 12 }}
                        bordered={false}
                      >
                        <Panel header="Client information" key="2">
                          <Row style={{ margin: 12 }}>
                            <Col span={12}>
                              <Radio.Group
                                name="clientType"
                                onChange={e => {
                                  setFieldValue("clientType", e.target.value);
                                  const { clientType, ...rest } = values;
                                  switch (e.target.value) {
                                    case "contacter":
                                      this.setState({ disabled: false });
                                      setValues({
                                        ...rest,
                                        ...contacterVals
                                      });

                                      break;
                                    case "businessPartner":
                                      this.setState({ disabled: true });
                                      setValues({
                                        ...rest,
                                        ...businessPartnerVals
                                      });
                                      break;
                                    case "somebodyElse":
                                      this.setState({ disabled: false });
                                      setValues({ ...rest, ...somebodyElse });
                                      break;
                                    default:
                                      break;
                                  }
                                }}
                                defaultValue="contacter"
                              >
                                <Radio value="contacter">Contacter</Radio>
                                <Radio value="businessPartner">
                                  Business partner
                                </Radio>
                                <Radio value="somebodyElse">
                                  Somebody else
                                </Radio>
                              </Radio.Group>
                            </Col>
                            <Col span={12}>
                              <Radio.Group
                                disabled={disabled}
                                name="isForOrganization"
                                onChange={e => {
                                  setFieldValue(
                                    "isForOrganization",
                                    e.target.value
                                  );
                                }}
                                value={values.isForOrganization}
                              >
                                <Radio value={false}>Individual</Radio>
                                <Radio value={true}>Legal entity</Radio>
                              </Radio.Group>
                            </Col>
                          </Row>

                          <SubscriberFields countries={countries} />
                        </Panel>
                        <Panel header="Seller information" key="3">
                          <SupplierFields countries={countries} />
                        </Panel>
                      </Collapse>
                    </Row>
                    <Row>
                      <FeeTable
                        rowSelection={{
                          selectedRowKeys: selectedFeeKeys,
                          onChange: (selectedRowKeys, selectedRows) => {
                            this.setState({
                              selectedFeeKeys: selectedRowKeys,
                              selectedPrice: selectedRows.reduce(
                                (total, { finalPrice, sellPrice }) =>
                                  total +
                                  (finalPrice === 0 ? sellPrice : finalPrice),
                                0
                              )
                            });
                            setFieldValue("fees", selectedRows);
                          }
                        }}
                        selectedPrice={selectedPrice}
                      />
                    </Row>
                    <Row>
                      <InputField
                        name="note"
                        label="Note"
                        node={
                          <TextArea
                            rows={3}
                            autosize
                            style={{ width: "95%" }}
                          />
                        }
                        labelColSpan={2}
                        wrapperColSpan={22}
                      />
                    </Row>
                  </Form>
                </Panel>
              </Collapse>
            );
          }}
        />
      );
    }
  }
}

interface HelperProps {
  countries: Country[];
}

const SubscriberFields: React.SFC<HelperProps> = ({ countries }) => (
  <React.Fragment>
    <Row>
      <Col span={8}>
        <InputField name="subscriberName" label="First name" />
      </Col>
      <Col span={8}>
        <InputField name="subscriberLastName" label="Last name" />
      </Col>
      <Col span={8}>
        <InputField name="subscriberCompany" label="Company" />
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <InputField name="subscriberCompanyVAT" label="Company VAT" />
      </Col>
      <Col span={8}>
        <InputField name="subscriberAddress" label="Address" />
      </Col>
      <Col span={8}>
        <InputField
          name="subscriberCountryId"
          label="Country"
          type="select"
          node={
            <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={(
                inputValue: string,
                option: React.ReactElement<OptionProps>
              ) =>
                option.props
                  .children!.toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {countries.map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          }
        />
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <InputField name="subscriberEmail" label="E-mail" />
      </Col>
      <Col span={8}>
        <InputField name="subscriberPhone" label="Phone" />
      </Col>
    </Row>
  </React.Fragment>
);

const SupplierFields: React.SFC<HelperProps> = ({ countries }) => (
  <React.Fragment>
    <Row>
      <Col span={8}>
        <InputField name="supplierName" label="First name" />
      </Col>
      <Col span={8}>
        <InputField name="supplierLastName" label="Last name" />
      </Col>
      <Col span={8}>
        <InputField name="supplierCompany" label="Company" />
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <InputField name="supplierCompanyVAT" label="Company VAT" />
      </Col>
      <Col span={8}>
        <InputField name="supplierAddress" label="Address" />
      </Col>
      <Col span={8}>
        <InputField
          name="supplierCountryId"
          label="Country"
          type="select"
          node={
            <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={(
                inputValue: string,
                option: React.ReactElement<OptionProps>
              ) =>
                option.props
                  .children!.toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {countries.map(({ name, id }) => (
                <Select.Option key={id} value={id}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          }
        />
      </Col>
    </Row>
    <Row>
      <Col span={8}>
        <InputField name="supplierEmail" label="E-mail" />
      </Col>
      <Col span={8}>
        <InputField name="supplierPhone" label="Phone" />
      </Col>
      <Col span={8}>
        <InputField name="supplierBankConnection" label="Bank connection" />
      </Col>
    </Row>
  </React.Fragment>
);

const mapStateToProps = ({
  requestDetail: {
    request: {
      itemId,
      customer,
      businnessPartner,
      businnessPartnerMail,
      businnessPartnerTel
    }
  },
  user: { user },
  finance: {
    finance: { fees }
  }
}: AppState) => ({
  reqItemId: itemId.length === 0 ? 0 : itemId[0],
  user,
  contacter: customer,
  businnessPartner,
  businnessPartnerMail,
  businnessPartnerTel,
  fees: fees.filter(f => f.feeInvoceItem.length === 0)
});

const dispatchToProps = {
  refresh: requestActions.fetchRequest
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(NewInvoice);
