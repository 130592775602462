import * as React from "react";
import { List } from "antd";
import Traveler from "../model/Traveler";
import "../css/DocumentList.css";
import TravelerDocument from "../model/TravelerDocument";
import TravelerDocumentsService from "../helpers/model_services/TravelerDocumentsService";
import getName from "../helpers/MyFunctions";

interface ListProps {
  travelers: Traveler[];
}

interface ItemProps {
  doc: TravelerDocument;
}

const DocumentList: React.SFC<ListProps> = ({ travelers }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={travelers}
      renderItem={({ travelersDocuments, alphaId, name, lastName }) => (
        <React.Fragment>
          <div className="title">{`${alphaId} - ${getName(
            name,
            lastName
          )}`}</div>
          <div>
            {travelersDocuments

              .sort(
                (
                  { documentsTypes: { order: orderA } },
                  { documentsTypes: { order: orderB } }
                ) => orderA - orderB
              )
              .map(doc => (
                <DocumentItem doc={doc} key={doc.id} />
              ))}
          </div>
        </React.Fragment>
      )}
    />
  );
};

const DocumentItem: React.SFC<ItemProps> = ({
  doc: {
    id: docId,
    documentsTypes: { name, iconName }
  }
}) => {
  return (
    <a
      className="no-color"
      onClick={() => {
        const service = new TravelerDocumentsService();
        service.getDocumentURL(docId).then(({ data: { documentUrl } }) => {
          const link = document.createElement("a");
          link.href = documentUrl;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }}
    >
      <figure className="item">
        <i className={`${iconName} fa-2x`}></i>
        <figcaption className="caption">{name}</figcaption>
      </figure>
    </a>
  );
};

export default DocumentList;
