import * as React from "react";
import RequestStatusesService from "../../../helpers/model_services/RequestStatusesService";
import BasicPage from "../../_common/BasicPage/BasicPage";
import { useSearchProps } from "../../_hooks/useColumnProps";
import RequestStatus from "../../../model/RequestStatus";

export interface Props {}

const RequestStatusessPage: React.SFC<Props> = () => {
  return (
    <BasicPage<RequestStatus>
      restService={new RequestStatusesService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        }
      ]}
    />
  );
};

export default RequestStatusessPage;
