import * as React from "react";
import TextWithLabel from "../_common/TextWithLabel";
import { Select, Button, Row, Col, Icon, Tooltip } from "antd";
import moment from "moment/moment.js";
import MyCard from "../_common/MyCard";
import { connect } from "react-redux";
import { Mode } from "../../types";
import { AppState } from "../../store/appStore";
import { requestActions } from "../../redux/request/requestSlice";
import RequestStatus from "../../model/RequestStatus";
import RequestStatusesService from "../../helpers/model_services/RequestStatusesService";
import { RequestDetail } from "../../helpers/model_services/RequestService";
import BusinessPartner from "./BusinessPartner";
import useSelectVals from "../_hooks/useSelectVals";

export interface Props {
  requestDetail: RequestDetail;
  onStatusChange: (statusId: number) => void;
  mode: Mode;
  onAssign: () => void;
  requestedCountry: string;
  confirmedCountry: string;
}

const getStatusName = (statusId: number, statusList: RequestStatus[]) => {
  if (statusList.length > 0) {
    const index = statusList.findIndex(status => status.id === statusId);
    if (index !== -1) {
      return statusList[index].name;
    }
  }
  return "-";
};

const GeneralInfo: React.FC<Props> = ({
  requestDetail,
  onStatusChange,
  mode,
  onAssign,
  requestedCountry,
  confirmedCountry
}) => {
  const {
    requestStatusesId,
    createdAt,
    updatedAt,
    salesRequestUser,
    businessCaseId
  } = requestDetail;

  const [statusId, setStatusId] = React.useState<number>(requestStatusesId);
  const { data: statusList } = useSelectVals(new RequestStatusesService());

  React.useEffect(() => {
    setStatusId(requestStatusesId);
  }, [requestStatusesId]);
  const assigneeName =
    salesRequestUser !== null
      ? `${salesRequestUser.name !== null ? salesRequestUser.name : ""}${
          salesRequestUser.lastName !== null
            ? ` ${salesRequestUser.lastName}`
            : ""
        }`
      : "";
  return (
    <MyCard title="General information">
      <Row>
        <Col span={12}>
          <TextWithLabel
            label="Status"
            text={
              mode === "edit" ? (
                <React.Fragment>
                  <div style={{ float: "left" }}>
                    <Select
                      value={statusId}
                      style={{ width: 135 }}
                      onChange={value => {
                        setStatusId(value);
                      }}
                      size="small"
                    >
                      {statusList
                        .filter(s =>
                          businessCaseId === null
                            ? ["NotAcceptable", "Open"].includes(s.name) ||
                              s.id === requestStatusesId
                            : ["Close Audit", "Closed", "Cancelled"].includes(
                                s.name
                              ) || s.id === requestStatusesId
                        )
                        .map(({ name: displayValue, id: dataValue }) => (
                          <Select.Option key={dataValue} value={dataValue}>
                            {displayValue}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      float: "right",
                      marginRight: "30%"
                    }}
                  >
                    <Button
                      size="small"
                      className="orange"
                      disabled={statusId === requestStatusesId}
                      onClick={() => {
                        if (statusId) {
                          onStatusChange(statusId);
                        }
                      }}
                      type="primary"
                    >
                      Save
                    </Button>
                  </div>
                </React.Fragment>
              ) : (
                getStatusName(requestStatusesId, statusList)
              )
            }
          />
          <TextWithLabel label="Req. country" text={requestedCountry} />
          <TextWithLabel label="Conf. country" text={confirmedCountry} />
          <TextWithLabel
            label="Created at"
            text={
              createdAt === null
                ? "-"
                : moment(moment.utc(createdAt).toDate()).format(
                    "D.M.YYYY | HH:mm"
                  )
            }
          />
          <TextWithLabel
            label="Last change"
            text={
              updatedAt === null
                ? "-"
                : moment(moment.utc(updatedAt).toDate()).format(
                    "D.M.YYYY | HH:mm"
                  )
            }
          />
          <TextWithLabel
            label="Assignee"
            text={
              <React.Fragment>
                {assigneeName}
                {mode === "edit" ? (
                  <div style={{ float: "right", marginRight: "30%" }}>
                    <Button
                      className="orange"
                      onClick={onAssign}
                      type="primary"
                      size="small"
                    >
                      Take it!
                    </Button>
                  </div>
                ) : null}
              </React.Fragment>
            }
          />
        </Col>

        <Col span={12}>
          <BusinessPartner req={requestDetail} />
        </Col>
      </Row>
    </MyCard>
  );
};

const mapStateToProps = ({
  requestDetail: { request: requestDetail, mode }
}: AppState) => ({
  requestDetail,
  mode,
  requestedCountry:
    requestDetail.requestedCountry && requestDetail.requestedCountry !== null
      ? requestDetail.requestedCountry.name
      : "-",
  confirmedCountry:
    requestDetail.confirmedCountry && requestDetail.confirmedCountry !== null
      ? requestDetail.confirmedCountry.name
      : "-"
});

const mapDispatchToProps = {
  onStatusChange: requestActions.changeStatus,
  onAssign: requestActions.assignSales
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralInfo);
