import http from "../HttpService";
import { AxiosPromise } from "axios";
import BaseClass from "../../model/_BaseClass";

export default abstract class AbstractRestService<T extends BaseClass> {
  protected apiUrl: string = "https://dunia-api.azurewebsites.net/v1.0/";
  protected _http: typeof http = http;
  constructor(addon: string) {
    this.apiUrl += addon + "/";
    const jwt = sessionStorage.getItem("token");
    if (jwt) {
      this._http.setJwt(jwt);
    }
  }

  create(object: T): AxiosPromise<number> {
    return this._http.post(this.apiUrl, object);
  }

  getAll(): AxiosPromise<T[]> {
    return this._http.get(this.apiUrl);
  }

  getById(id: number): AxiosPromise<T> {
    return this._http.get(this.apiUrl + id);
  }

  update(object: T): AxiosPromise<T> {
    return this._http.put(this.apiUrl, object);
  }

  /* delete(id: number): AxiosPromise<T> {
    return this._http.delete(this.apiUrl + id);
  } */
  delete(object: T) {
    const _object = { ...object };
    _object.isDeleted = true;
    return this._http.put(this.apiUrl, _object);
  }
  getCustomAll(customAddon: string) {
    return this._http.get(this.apiUrl + customAddon);
  }
}
