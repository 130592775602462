import AbstractRestService from "./AbstractRestService";
import RequestItem from "../../model/RequestItem";

export default class RequestItemService extends AbstractRestService<
  RequestItem
> {
  constructor() {
    super("Codelists/RequestItem");
  }
}
