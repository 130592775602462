import * as React from "react";
import { Button, Modal, Form, Select, message, Icon } from "antd";
import Traveler from "../../model/Traveler";
import getName from "../../helpers/MyFunctions";
import Dragger from "antd/lib/upload/Dragger";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";
import useSelectVals from "../_hooks/useSelectVals";
import DocumentsTypesService from "../../helpers/model_services/DocumentsTypesService";
import { UploadChangeParam } from "antd/lib/upload";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import { requestActions } from "../../redux/request/requestSlice";

export interface Props {
  travelers: Traveler[];
  fetchRequest: () => void;
}

const docService = new TravelerDocumentsService();

const NewDocument: React.FC<Props> = ({ travelers, fetchRequest }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [travelerId, setTravelerId] = React.useState<number>(0);
  const [docType, setDocType] = React.useState<number>(11);
  const { data: docTypes } = useSelectVals(new DocumentsTypesService());
  const close = () => {
    setVisible(false);
    setTravelerId(0);
  };
  return (
    <React.Fragment>
      <Button
        type="primary"
        className="dark"
        onClick={() => setVisible(true)}
        size="small"
      >
        + Add document
      </Button>
      <Modal visible={visible} onCancel={close} onOk={close}>
        <Form.Item label="Select traveler" style={{ marginBottom: 12 }}>
          <Select<number>
            style={{ width: 200 }}
            autoFocus
            onChange={value => setTravelerId(value)}
          >
            {travelers.map(({ name, lastName, id }) => (
              <Select.Option key={id} value={id}>
                {getName(name, lastName)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Dragger
          style={{ height: "100%" }}
          showUploadList={false}
          name="files"
          customRequest={({ onSuccess, onError, onProgress, file }: any) => {
            docService.uploadFile(travelerId, docType, file).then(response => {
              onSuccess(response, file);
              fetchRequest();
              setVisible(false);
            });
          }}
          beforeUpload={() =>
            new Promise<void>((res, rej) => {
              Modal.confirm({
                title: "Please select document type.",
                okText: "Ok",
                cancelText: "Cancel",
                onOk: () => res(),
                onCancel: () => rej(),
                content: (
                  <Select<number>
                    defaultValue={11}
                    style={{ width: "100%" }}
                    onChange={value => setDocType(value)}
                  >
                    {docTypes
                      .sort(({ name: aName }, { name: bName }) =>
                        aName.localeCompare(bName)
                      )
                      .map(({ name, id }) => (
                        <Select.Option key={id} value={id}>
                          {name}
                        </Select.Option>
                      ))}
                  </Select>
                )
              });
            })
          }
          multiple={false}
          onChange={(info: UploadChangeParam) => {
            if (info.file.status !== "uploading") {
              /* console.log(info.file, info.fileList); */
            }
            if (info.file.status === "done") {
              message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === "error") {
              message.error(`${info.file.name} file upload failed.`);
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <Icon type="inbox" />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Support for a single upload only</p>
        </Dragger>
      </Modal>
    </React.Fragment>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelers }
  }
}: AppState) => ({
  travelers
});

const mapDispatchToProps = {
  fetchRequest: requestActions.fetchRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDocument);
