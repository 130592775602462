import * as React from "react";
import {
  InputAdornment,
  IconButton,
  Input,
  Typography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  margin?: "none" | "dense";
  name?: string;
  hideWarning?: boolean;
  autofocus?: boolean;
}

export interface State {
  showPassword: boolean;
}

class PasswordField extends React.Component<Props, State> {
  state = { showPassword: false };

  componentDidMount() {
    const { name, hideWarning } = this.props;
    if (!hideWarning) {
      document.addEventListener("keydown", function(event) {
        /* See https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/getModifierState
        This function is supported in Firefox & MS Edge, webkit browsers
        will be supported (soon) */
        /* document.getElementById(
          "password-caps-warning"
        )!.style.display = event.getModifierState("CapsLock") ? "block" : "none"; */
        const pwdWarning = document.getElementById(
          (name ? name : "password") + "-caps-warning"
        );
        if (pwdWarning) {
          event.getModifierState && event.getModifierState("CapsLock")
            ? document
                .getElementById((name ? name : "password") + "-caps-warning")!
                .classList.remove("uk-invisible")
            : document
                .getElementById((name ? name : "password") + "-caps-warning")!
                .classList.add("uk-invisible");
        }
      });
    }
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  render() {
    const { showPassword } = this.state;
    const {
      onChange,
      value,
      margin,
      name,
      hideWarning,
      autofocus
    } = this.props;
    return (
      <React.Fragment>
        <Input
          autoFocus={autofocus}
          name={name ? name : "password"}
          type={showPassword ? "text" : "password"}
          value={value}
          onChange={onChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          margin={margin}
        />
        {!hideWarning && (
          <Typography
            className="uk-invisible"
            id={(name ? name : "password") + "-caps-warning"}
            color="error"
          >
            Varování: Máte zapnutý Caps lock.
          </Typography>
        )}
      </React.Fragment>
    );
  }
}

export default PasswordField;
