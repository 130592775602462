import { TravelerNote } from "../../model/Note";
import { Mode } from "../../types";
import {
  createSlice,
  ThunkAction,
  AnyAction
} from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import TravelerNoteService from "../../helpers/model_services/TravelerNoteService";
import { travelerActions } from "./travelerSlice";
import getName from "../../helpers/MyFunctions";

export interface TravelerNoteState {
  loading: boolean;
  travelerNotes: TravelerNote[];
  error: string;
  visible: boolean;
  mode: Mode;
}

export interface TravelerNoteActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: TravelerNote[];
  setVisible: boolean;
  setMode: Mode;
}

const service = new TravelerNoteService();

export const travelerNoteSlice = createSlice<
  TravelerNoteActions,
  TravelerNoteState,
  AppState
>({
  slice: "travelerNotes",
  initialState: {
    loading: false,
    travelerNotes: [],
    error: "Error!",
    visible: false,
    mode: "view"
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      loading: false,
      travelerNotes: payload
    }),
    setVisible: (state, payload) => ({
      ...state,
      visible: payload
    }),
    setMode: (state, payload) => ({
      ...state,
      mode: payload
    })
  }
});

const submitNote = (
  values: TravelerNote
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const { fetchStart, fetchError, fetchSuccess } = travelerNoteActions;
  dispatch(fetchStart());
  try {
    const {
      travelerNotes: { mode },
      user: {
        user: { name, lastName }
      }
    } = getState();
    switch (mode) {
      case "new":
        await service.create({ ...values, user: getName(name, lastName) });
        break;

      case "edit":
        await service.update(values);
        break;

      case "delete":
        await service.delete(values);
        break;

      default:
        dispatch(fetchError("Invalid edit mode for this action"));
        return false;
    }
    await dispatch(travelerActions.fetchTraveler());
    return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
    return false;
  }
};

export const travelerNoteActions = {
  ...travelerNoteSlice.actions,
  submitNote
};
export const travelerNotesReducer = travelerNoteSlice.reducer;
