import AbstractRestService from "./AbstractRestService";
import AgeCategory from "../../model/AgeCategory";

export default class AgeCategoryService extends AbstractRestService<
  AgeCategory
> {
  constructor() {
    super("Codelists/AgeCategory");
  }
}
