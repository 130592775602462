import * as React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  Paper,
  Typography,
  FormControl,
  InputLabel,
  Button
} from "@material-ui/core";
import PasswordField from "../../_common/PasswordField";
import UserService from "../../../helpers/model_services/UserService";
import CustomerService from "../../../helpers/model_services/CustomerService";

const styles = (theme: Theme) =>
  createStyles({
    main: {
      width: "auto",
      display: "block", // Fix IE 11 issue.
      marginLeft: theme.spacing.unit * 3,
      marginRight: theme.spacing.unit * 3,
      [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
        width: 400,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    paper: {
      marginTop: theme.spacing.unit * 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
        .spacing.unit * 3}px`
    },
    avatar: {
      margin: theme.spacing.unit,
      backgroundColor: theme.palette.primary.main
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing.unit
    },
    submit: {
      marginTop: theme.spacing.unit * 3
    }
  });

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<{ token: string }> {}

interface State {
  newPassword: string;
  confirmNewPassword: string;
  redirect: boolean;
}

class PasswordChange extends React.Component<Props, State> {
  state = {
    newPassword: "",
    confirmNewPassword: "",
    redirect: false
  };

  handleChange = (prop: "newPassword" | "confirmNewPassword") => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ [prop]: event.target.value } as Pick<State, typeof prop>);
    this.setCustomValidity(event);
  };

  setCustomValidity(e: React.ChangeEvent<HTMLInputElement>) {
    const { newPassword } = this.state;
    const { name, value } = e.currentTarget;

    switch (name) {
      case "confirmNewPassword":
        if (newPassword !== value) {
          e.target.setCustomValidity("Passwords don't match.");
        } else {
          e.target.setCustomValidity("");
        }
        break;

      default:
        break;
    }
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { newPassword } = this.state;
    const {
      location: { pathname },
      match: {
        params: { token }
      }
    } = this.props;
    const cur = pathname.split("/")[1];

    const service =
      cur === "user-password-reset" ? new UserService() : new CustomerService();
    service.resetPassword(newPassword, token).then(response => {
      toast.success("Password was succesfully changed.");
      this.setState({ redirect: true });
    });
  };

  render() {
    const { newPassword, confirmNewPassword, redirect } = this.state;
    const { classes } = this.props;

    if (redirect) {
      return <Redirect to="/" />;
    }

    return (
      <main className={classes.main}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
            Reset password
          </Typography>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="newPassword">New password</InputLabel>
              <PasswordField
                name="newPassword"
                onChange={this.handleChange("newPassword")}
                value={newPassword}
                hideWarning={true}
              />
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="confirmNewPassword">
                Confirm new password
              </InputLabel>
              <PasswordField
                name="confirmNewPassword"
                onChange={this.handleChange("confirmNewPassword")}
                value={confirmNewPassword}
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Submit
            </Button>
          </form>
        </Paper>
      </main>
    );
  }
}

export default withStyles(styles)(PasswordChange);
