import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import MyCard from "../../_common/MyCard";
import VisaDetail from "../../visa/VisaDetail";
import { visaActions } from "../../../redux/visa/visaSlice";
import { connect } from "react-redux";
import { visaListActions } from "../../../redux/visa/visaListSlice";
import { AppState } from "../../../store/appStore";
import VisaRequestTable from "./VisaRequestTable";

export interface Props
  extends RouteComponentProps<{ filter: string; id?: string }> {
  openVisa: (visaId: number) => void;
  setStatus: (statusId: number) => void;
  setVisaSection: (payload: boolean) => void;
  fetchData: () => void;
}

export interface State {}

class VisaRequestPage extends React.Component<Props, State> {
  fetch = (changedFilter: boolean, close: boolean = false) => {
    const {
      fetchData,
      match: {
        params: { filter, id }
      },
      openVisa,
      setStatus
    } = this.props;
    if (changedFilter) {
      let statusId: number = -1;
      switch (filter) {
        case "sales":
          statusId = 10;
          break;
        case "pending":
          statusId = 20;
          break;
        case "data-entry":
          statusId = 30;
          break;
        case "apply-online":
          statusId = 40;
          break;
        case "ready-to-submit":
          statusId = 50;
          break;
        case "submitted":
          statusId = 60;
          break;
        case "ready-to-collect":
          statusId = 70;
          break;
        case "collected":
          statusId = 80;
          break;
        case "all":
          statusId = 0;
          break;
        default:
          break;
      }
      if (statusId !== -1 && !close) {
        setStatus(statusId);
      }

      fetchData();
    }

    if (id && !close) {
      openVisa(Number(id));
    }
  };

  componentDidMount() {
    this.props.setVisaSection(true);
    this.fetch(true);
  }

  componentWillUnmount() {
    this.props.setVisaSection(false);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const {
        match: {
          params: { filter: curFil }
        }
      } = this.props;
      const {
        match: {
          params: { filter: prevFil }
        }
      } = prevProps;
      this.fetch(curFil !== prevFil);
    }
  }

  render() {
    const {
      history,
      match: {
        params: { filter }
      }
    } = this.props;

    return (
      <MyCard style={{ marginTop: 84 }}>
        <VisaRequestTable></VisaRequestTable>
        <VisaDetail
          refreshVisaList={() => {
            history.push(`/visa/${filter}`);
            this.fetch(true, true);
          }}
        />
      </MyCard>
    );
  }
}

const mapStateToProps = ({ visaList: { filters, sorter } }: AppState) => ({
  filters,
  sorter
});

const mapDispatchToProps = {
  openVisa: (visaId: number) => visaActions.fetchVisaRequest(visaId, true),
  setVisaSection: visaActions.setVisaSection,
  setStatus: visaListActions.setStatus,
  fetchData: visaListActions.fetchVisaList
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(VisaRequestPage)
);
