import AbstractRestService from "./AbstractRestService";
import TravelerDocument from "../../model/TravelerDocument";
import { AxiosPromise } from "axios";

export interface PassportValues {
  firstName: string;
  lastName: string;
  passportNumber: string;
  nationalityId: number;
  countryOfBirthId: number;
  dateOfBirth: Date;
  sex: string;
  expirationDate: Date;
  ageCategoryId: number;
  passportTypeId: number;
  recognizedData: string[];
  recognizedText: string;
}

export default class TravelerDocumentsService extends AbstractRestService<
  TravelerDocument
> {
  constructor() {
    super("CodeLists/TravelersDocuments");
  }

  getData(id: number): AxiosPromise<TravelerDocument> {
    return this._http.get(this.apiUrl + `${id}/data`);
  }

  getDocumentURL(docId: number): AxiosPromise<TravelerDocument> {
    return this._http.get(this.apiUrl + `${docId}/savedata`);
  }

  uploadFile(travelerId: number, docTypeId: number, file: any) {
    const formData = new FormData();
    formData.append("files", file);
    return this._http.post(
      this.apiUrl + `${travelerId}/UploadFiles`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        params: { typeId: docTypeId }
      }
    );
  }

  getPassportValues(file: any): AxiosPromise<PassportValues> {
    const formData = new FormData();
    formData.append("file", file);
    return this._http.post(this.apiUrl + `RecognizePassport`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  getDeletedFiles(
    travelerId: number,
    docTypeId: number
  ): AxiosPromise<TravelerDocument[]> {
    return this._http.post(this.apiUrl + `${travelerId}/DeletedFiles`, null, {
      params: { typeDocId: docTypeId }
    });
  }
}
