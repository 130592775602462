import AbstractRestService from "./AbstractRestService";
import Customer from "../../model/Customer";
import { AxiosPromise } from "axios";

export class CustomerNames {
  id: number = 0;
  name: string = "";
  lastName: string = "";
}

export interface ShortCustomer {
  id: number;
  email: string;
  password: string;
  name: string;
  lastName: string;
  phone: string;
  contactCity: string;
}

export default class CustomerService extends AbstractRestService<Customer> {
  constructor() {
    super("Customer");
  }

  getNames(): AxiosPromise<CustomerNames[]> {
    return this._http.get(this.apiUrl + "name");
  }

  findNames(searchVal: string): AxiosPromise<CustomerNames[]> {
    return this._http.get(this.apiUrl + "namesearch", {
      params: {
        findParm: searchVal
      }
    });
  }

  sendResetLink(email: string) {
    return this._http.post(`${this.apiUrl}reset-password`, { email });
  }

  resetPassword(newPassword: string, resetString: string) {
    return this._http.patch(`${this.apiUrl}reset-password`, {
      resetString,
      newPassword
    });
  }

  register(object: ShortCustomer): AxiosPromise<number> {
    return this._http.post(this.apiUrl, object);
  }

  updateCustomer(object: ShortCustomer): AxiosPromise<number> {
    return this._http.post(`${this.apiUrl}updateprofile`, object);
  }

  verifyEmail(token: string) {
    return this._http.patch(`${this.apiUrl}email-verification`, {
      verifiString: token
    });
  }
}
