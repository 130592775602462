import * as React from "react";
import Traveler from "../../model/Traveler";
import { Table, Button, Divider, Popconfirm, Input, Icon } from "antd";
import moment from "moment/moment.js";
import TravelerDocument from "../../model/TravelerDocument";
import TravelerPhoto from "./TravelerPhoto";
import { Mode } from "../../types";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import { travelerActions } from "../../redux/traveler/travelerSlice";
import "../../css/TravelerTable.css";
import ActionButtons from "../_common/ActionButtons";
import Highlighter from "react-highlight-words";
import { CompareFn } from "antd/lib/table";

export interface Props {
  changeMode: (newMode: Mode) => void;
  travelerList: Traveler[];
  openTraveler: (travelerId: number) => void;
  deleteTraveler: (traveler: Traveler) => void;
}

const TravelerTable: React.FC<Props> = ({
  changeMode,
  travelerList,
  openTraveler,
  deleteTraveler
}) => {
  const [searchText, setSearchText] = React.useState<string>("");
  const [searchInput, setSearchInput] = React.useState<Input | null>(null);

  const handleSearch = (selectedKeys: any, confirm: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: string,
    onFilter?: (value: any, record: any) => boolean,
    sorter?: CompareFn<Traveler>
  ) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            setSearchInput(node);
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: onFilter
      ? onFilter
      : (value: any, record: any) =>
          record[dataIndex] &&
          record[dataIndex] !== null &&
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => searchInput !== null && searchInput.select());
      }
    },
    render: (text: any, record: any, index: number) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text !== null ? text.toString() : ""}
      />
    ),
    sorter: sorter
      ? sorter
      : (a: any, b: any) =>
          a[dataIndex] && b[dataIndex]
            ? a[dataIndex].toString().localeCompare(b[dataIndex].toString())
            : 0
  });

  return (
    <Table<Traveler>
      rowClassName={(record: Traveler, index: number) =>
        record.visaRequests.length === 0 ? "red-background" : ""
      }
      columns={[
        { dataIndex: "alphaId" },
        {
          title: "Name",
          width: "30%",
          dataIndex: "lastName",
          ...getColumnSearchProps(
            "lastName",
            (value: string, record: Traveler) => {
              const { name, lastName } = record;
              const nameContains =
                name && name !== null
                  ? name
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : false;
              const lastNameContains =
                lastName && lastName !== null
                  ? lastName
                      .toString()
                      .toLowerCase()
                      .includes(value.toLowerCase())
                  : false;
              return nameContains || lastNameContains;
            }
          ),
          render: (text, { name, lastName }, index) =>
            `${name === null ? "" : name} ${lastName === null ? "" : lastName}`
        },
        {
          title: "Date of birth",
          className: "table-center",
          dataIndex: "dateOfBirth",
          ...getColumnSearchProps("dateOfBirth"),
          render: text =>
            text === null ? null : moment(text).format("D.M.YYYY")
        },
        {
          title: "Nationality",
          className: "table-center",
          dataIndex: "nationality.name"
        },
        {
          title: "Passport",
          className: "table-center",
          dataIndex: "passportNumber",
          ...getColumnSearchProps("passportNumber")
        },
        {
          title: "ID/Iqama",
          className: "table-center",
          dataIndex: "nationalIdentityNumber",
          ...getColumnSearchProps("nationalIdentityNumber")
        },
        {
          title: "Personal photo",
          className: "table-center",
          dataIndex: "travelersDocuments",
          render: (docs: TravelerDocument[], { id: travelerId }, index) => {
            const doc = docs.find(
              doc =>
                doc.documentsTypesId === 4 &&
                doc.documentUrl !== null &&
                doc.travelersId === travelerId
            );
            return doc ? <TravelerPhoto id={doc.id!} /> : null;
          }
        },
        {
          title: "Actions",
          className: "table-center",
          width: "10%",
          render: (text, record, index) => (
            <ActionButtons
              onOpen={() => {
                changeMode("edit");
                openTraveler(record.id);
              }}
              onDelete={() => deleteTraveler(record)}
            />
          )
        }
      ]}
      onRow={(record, rowIndex) => {
        return {
          onClick: event => {
            changeMode("view");
            openTraveler(record.id);
          }
        };
      }}
      dataSource={travelerList}
      size="middle"
      rowKey={({ id }) => id.toString()}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30"],
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
      }}
    />
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelers }
  }
}: AppState) => ({
  travelerList: travelers.sort((a, b) => a.id - b.id)
});

const mapDispatchToProps = {
  openTraveler: travelerActions.openTraveler,
  changeMode: travelerActions.changeMode,
  deleteTraveler: travelerActions.deleteTraveler
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TravelerTable);
