import * as React from "react";
import { Table, Icon, Tooltip } from "antd";
import { uniqueId } from "lodash";
import { connect } from "react-redux";
import moment from "moment/moment.js";
import Payment from "../../../model/Payment";
import { Mode } from "../../../types";
import MyCard from "../../_common/MyCard";
import { AppState } from "../../../store/appStore";
import PaymentService from "../../../helpers/model_services/PaymentService";
import { requestActions } from "../../../redux/request/requestSlice";
import ActionButtons from "../../_common/ActionButtons";
import PaymentForm from "./PaymentForm";

export interface Props {
  payments: Payment[];
  itemId: number;
  refresh: () => void;
}
const paymentService = new PaymentService();
const PaymentsTable: React.FC<Props> = ({ payments, itemId, refresh }) => {
  const [action, setAction] = React.useState<Mode>("view");
  const initPayment = {
    itemId
  } as Payment;
  const [selected, setSelected] = React.useState<Payment>(initPayment);
  const [visible, setVisible] = React.useState<boolean>(false);
  const closeForm = () => {
    setVisible(false);
    setAction("view");
    setSelected({} as Payment);
  };
  return (
    <MyCard
      title="Transaction history"
      type="inner"
      extra={
        <PaymentForm
          visible={visible}
          onSubmit={async values => {
            switch (action) {
              case "new":
                await paymentService.create(values as Payment);
                break;
              case "edit":
                await paymentService.update(values as Payment);
                break;
              default:
                break;
            }
            refresh();
            closeForm();
          }}
          onClose={closeForm}
          onNew={() => {
            setSelected(initPayment);
            setAction("new");
            setVisible(true);
          }}
          payment={selected}
        />
      }
    >
      <Table<Payment>
        dataSource={payments}
        columns={[
          {
            dataIndex: "date",
            title: "Date",
            render: text =>
              text === null ? null : moment(text).format("D.M.YYYY")
          },
          {
            dataIndex: "amount",
            title: "Amount",
            className: "table-center",
            render: text => `${text} SAR`
          },
          {
            dataIndex: "method",
            title: "Method",
            className: "table-center"
          },
          {
            dataIndex: "invoiceId",
            title: "Invoice ID",
            className: "table-center"
          },
          {
            dataIndex: "text",
            title: "Note",
            className: "table-center",
            render: (note: string) =>
              note !== null && note.length > 0 ? (
                <Tooltip placement="top" title={note}>
                  <Icon
                    type="info-circle"
                    style={{ color: "rgb(24, 144, 255)", fontSize: 20 }}
                  />
                </Tooltip>
              ) : null
          },
          {
            title: "Actions",
            className: "table-center",
            key: "x",
            render: (text, record, index) => {
              return (
                <ActionButtons
                  onOpen={() => {
                    setAction("edit");
                    setSelected(record);
                    setVisible(true);
                  }}
                  openTooltip="Edit"
                  onDelete={() => {
                    paymentService.delete(record).then(() => refresh());
                  }}
                />
              );
            }
          }
        ]}
        rowKey={({ id }) => (id ? id.toString() : uniqueId("tableRowKey"))}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
      />
    </MyCard>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { itemId }
  },
  finance: {
    finance: { payments }
  }
}: AppState) => ({
  payments,
  itemId: itemId.length === 0 ? 0 : itemId[0]
});

const mapDispatchToProps = {
  refresh: requestActions.fetchRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentsTable);
