import * as React from "react";
import CustomerService, {
  ShortCustomer
} from "../../../helpers/model_services/CustomerService";
import { Button, Popconfirm, message, Table, Drawer, Input, Form } from "antd";
import Customer from "../../../model/Customer";
import { useSearchProps } from "../../_hooks/useColumnProps";
import { Mode } from "../../../types";
import MyCard from "../../_common/MyCard";
import ActionButtons from "../../_common/ActionButtons";
import { WrappedFormUtils } from "antd/lib/form/Form";

export interface Props {
  form: WrappedFormUtils;
}

const customerService = new CustomerService();

const CustomerPage: React.FC<Props> = ({
  form: {
    getFieldDecorator,
    validateFields,
    setFields,
    resetFields,
    getFieldValue,
    setFieldsValue
  }
}) => {
  const [action, setAction] = React.useState<Mode>("view");
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [id, setId] = React.useState<number>(0);
  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await customerService.getAll();
      setCustomers(data);
    } catch (error) {}
    setLoading(false);
  };
  React.useEffect(() => {
    fetchData();
  }, []);
  const onClose = () => {
    setVisible(false);
    setAction("view");
  };
  return (
    <MyCard
      style={{ marginTop: 84 }}
      headStyle={{ background: "#fff" }}
      extra={
        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
            setAction("new");
            resetFields();
          }}
        >
          + New
        </Button>
      }
    >
      <Table<Customer>
        loading={loading}
        rowKey={({ id }) => id.toString()}
        dataSource={customers}
        size="middle"
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
        }}
        columns={[
          {
            title: "First name",
            ...useSearchProps("name")
          },
          {
            title: "Last name",
            ...useSearchProps("lastName")
          },
          {
            title: "Email address",
            ...useSearchProps("email")
          },
          {
            title: "Phone",
            ...useSearchProps("contactTelephone")
          },
          {
            title: "City",
            ...useSearchProps("contactCity")
          },
          {
            title: "Reset password",
            key: "reset_password",
            render: (val, { email }) => (
              <Popconfirm
                placement="top"
                title="Are you sure?"
                onConfirm={e => {
                  if (e) {
                    customerService.sendResetLink(email).then(({ status }) => {
                      if (status === 200) {
                        message.success(`Reset link was sent to ${email}`);
                      }
                    });
                    e.stopPropagation();
                  }
                }}
                onCancel={e => {
                  if (e) {
                    e.stopPropagation();
                  }
                }}
                okText="Yes"
                cancelText="No"
              >
                <Button type="link">Reset</Button>
              </Popconfirm>
            )
          },
          {
            title: "Action",
            key: "x",
            render: (text, record, index) => {
              const {
                email,
                id,
                name,
                contactTelephone,
                lastName,
                contactCity
              } = record;
              return (
                <ActionButtons
                  onOpen={() => {
                    setAction("edit");
                    setFields({
                      email: { value: email },
                      name: { value: name },
                      phone: { value: contactTelephone },
                      contactCity: { value: contactCity },
                      lastName: { value: lastName }
                    });
                    setId(id);
                    setVisible(true);
                  }}
                  openTooltip="Edit"
                  onDelete={() => {
                    customerService
                      .delete({
                        email,
                        id,
                        name,
                        contactTelephone,
                        lastName,
                        contactCity
                      } as Customer)
                      .then(() => fetchData());
                  }}
                />
              );
            }
          }
        ]}
      />
      <Drawer title="Form" width={500} onClose={onClose} visible={visible}>
        <Form
          layout="horizontal"
          labelCol={{
            xs: { span: 24 },
            sm: { span: 8 }
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 16 }
          }}
          onSubmit={e => {
            e.preventDefault();
            validateFields(async (err, values) => {
              if (!err) {
                try {
                  switch (action) {
                    case "new":
                      await customerService.register(values);
                      message.success("Customer was succesfully created.");
                      break;
                    case "edit":
                      await customerService.updateCustomer({ id, ...values });
                      message.success("Customer was succesfully updated.");
                      break;
                    default:
                      break;
                  }
                  await fetchData();
                  onClose();
                } catch (error) {}
              }
            });
          }}
        >
          <Form.Item label="Name">
            {getFieldDecorator("name", {
              rules: [{ required: true, message: "This field is required." }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Last name">
            {getFieldDecorator("lastName", {
              rules: [{ required: true, message: "This field is required." }]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator("email", {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    validateFields(["phone"], { force: true });
                    if (getFieldValue("email") || getFieldValue("phone")) {
                      callback();
                    } else {
                      callback("Email or phone is required!");
                    }
                  }
                },
                {
                  type: "email",
                  message: "The input is not valid E-mail!"
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="Phone">
            {getFieldDecorator("phone", {
              rules: [
                {
                  validator: (rule, value, callback) => {
                    validateFields(["email"], { force: true });
                    if (getFieldValue("email") || getFieldValue("phone")) {
                      callback();
                    } else {
                      callback("Email or phone is required!");
                    }
                  }
                }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item label="City">
            {getFieldDecorator("contactCity", {})(<Input />)}
          </Form.Item>
          {action === "new" && (
            <Form.Item label="Password">
              {getFieldDecorator("password", {
                rules: [{ required: true, message: "This field is required." }]
              })(<Input.Password />)}
            </Form.Item>
          )}
          <div
            style={{
              left: 0,
              bottom: 0,
              width: "100%",
              borderTop: "1px solid #e9e9e9",
              padding: "10px 16px",
              background: "#fff",
              textAlign: "right"
            }}
          >
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Drawer>
    </MyCard>
  );
};

export default Form.create({})(CustomerPage);
