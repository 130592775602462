import * as React from "react";
import { Formik, Field, FieldProps, FieldArray } from "formik";
import { Form } from "antd";
import BaseClass from "../../../model/_BaseClass";
import { InputConfig } from "../../../types";
import AntDInputField from "../AntDInpuField/AntDInputField";
export interface Props<T extends BaseClass> {
  initVals: T;
  onSubmit: (values: T) => void;
  inputsConfig?: InputConfig<T>[];
  children: React.ReactNode;
}

function AntDForm<T extends BaseClass>({
  initVals,
  onSubmit,
  children,
  inputsConfig
}: Props<T>) {
  return (
    <Formik
      enableReinitialize
      initialValues={initVals}
      onSubmit={values => onSubmit(values)}
      render={formikProps => (
        <Form
          layout="horizontal"
          hideRequiredMark
          onSubmit={formikProps.handleSubmit}
        >
          {inputsConfig &&
            inputsConfig.map(({ dataIndex, renderArray, ...rest }) => {
              return renderArray ? (
                <FieldArray key={dataIndex} name={dataIndex!} render={renderArray} />
              ) : (
                <Field
                  key={dataIndex}
                  name={dataIndex}
                  render={(innerProps: FieldProps<T>) => {
                    return <AntDInputField fieldProps={innerProps} {...rest} />;
                  }}
                />
              );
            })}
          {children}
        </Form>
      )}
    />
  );
}

export default AntDForm;
