import AbstractRestService from "./AbstractRestService";
import Request from "../../model/Request";
import { AxiosPromise } from "axios";
import BaseClass from "../../model/_BaseClass";
import Customer from "../../model/Customer";
import User from "../../model/User";
import Traveler from "../../model/Traveler";
import { VisaRequestListItem } from "./VisaRequestService";
import TravelerDocument from "../../model/TravelerDocument";
import Fee from "../../model/Fee";
import { RequestNote } from "../../model/Note";
import Payment from "../../model/Payment";
import Invoice from "../../model/Invoice";
import $ from "jquery";
import Country from "../../model/Country";

export interface RequestListItem extends BaseClass {
  statusName: string;
  bc_Id: number;
  requestedCountry: string;
  requestedRegion: string;
  confirmedCountry: string;
  confirmedRegion: string;
  customerName: string;
  contactPhone: string;
  numberOfTravelers: number;
  price: number;
  paid: number;
}

export interface RequestList {
  count: number;
  requestModel: RequestListItem[];
}

export interface BulkEditModel {
  requestedCountryId: number;
  confirmedCountryId: number;
  visaRequestPurposesId: number;
  visaEntryCountId: number;
  confirmedTripDateFrom: Date;
  confirmedTripDateTo: Date;
  durationOfStay: number;
  servicePlaceId: number;
  servicePlaceAppointmentDateTime: Date;
  tier: string;
}

export interface ShortRequest extends BaseClass {
  customerId: number;
  requestStatusesId: number;
  businessCaseId: number;
  responsibleSalesId: number;
  tag: string;
  businnessPartner: string;
  businnessPartnerBy: string;
  businnessPartnerTel: string;
  businnessPartnerMail: string;
}

export interface GroupedFee {
  item: string;
  qty: number;
  netPrice: number;
  vat: number;
  currency: string;
  price: number;
}

export interface RequestDetail extends BaseClass {
  customerId: number;
  customer: Customer;
  requestStatusesId: number;
  businessCaseId: number;
  tag: string;
  responsibleSalesId: number;
  salesRequestUser: User;
  travelers: Traveler[];
  visaRequest: VisaRequestListItem[];
  travelersDocuments: TravelerDocument[];
  fee: GroupedFee[];
  requestNote: RequestNote[];
  itemId: number[];
  payments: Payment[];
  businnessPartner: string;
  businnessPartnerBy: string;
  businnessPartnerTel: string;
  businnessPartnerMail: string;
  requestedCountryId: number;
  confirmedCountryId: number;
  requestedCountry?: Country;
  confirmedCountry?: Country;
}

export interface Finance {
  fees: Fee[];
  payments: Payment[];
  invoices: Invoice[];
}

export interface Badges {
  reqCount: number;
  bcCount: number;
  callMeCount: number;
}

export interface RequestListProps {
  statusId: number;
  perPage: number;
  curPage: number;
  sortField?: string;
  sortOrder?: "asc" | "desc";
  isBc: boolean;
  statusName?: string[];
  bc_Id?: string[];
  requestedCountry?: string[];
  requestedRegion?: string[];
  confirmedCountry?: string[];
  confirmedRegion?: string[];
  customerName?: string[];
  contactPhone?: string[];
  numberOfTravelers?: string[];
  price?: string[];
  paid?: string[];
}

export interface ISearch {
  visas: VisaRequestListItem[];
  requests: RequestListItem[];
  bcs: RequestListItem[];
}

export default class RequestService extends AbstractRestService<Request> {
  constructor() {
    super("Codelists/Request");
  }

  getList(statusId: number): AxiosPromise<RequestListItem[]> {
    return this._http.get(`${this.apiUrl}${statusId}/extract`);
  }

  getBadges(): AxiosPromise<Badges> {
    return this._http.get(`${this.apiUrl}getbadges`);
  }

  getPagination({
    statusId,
    perPage,
    curPage,
    sortField,
    sortOrder,
    isBc,
    ...rest
  }: RequestListProps): AxiosPromise<RequestList> {
    return this._http.get(`${this.apiUrl}extractpagination`, {
      params: {
        requeststatuses: statusId,
        isbc: isBc,
        results: perPage,
        page: curPage,
        sortfield: sortField,
        sortorder: sortOrder,
        ...rest
      },
      paramsSerializer: params => $.param(params, true)
    });
  }

  post(object: ShortRequest): AxiosPromise<number> {
    return this._http.post(this.apiUrl, object);
  }

  getDetail(reqId: number): AxiosPromise<RequestDetail> {
    return this._http.get(`${this.apiUrl}${reqId}/wireframe`);
  }

  getFinance(reqId: number): AxiosPromise<Finance> {
    return this._http.get(`${this.apiUrl}${reqId}/finance`);
  }

  getPrice(reqId: number): AxiosPromise<number> {
    return this._http.get(`${this.apiUrl}${reqId}/customerprice`);
  }

  bulkEdit(reqId: number, values: BulkEditModel): AxiosPromise {
    return this._http.put(`${this.apiUrl}${reqId}/bulkedit`, values);
  }

  search(value: string): AxiosPromise<ISearch> {
    return this._http.get(`${this.apiUrl}searchtext_v1`, {
      params: {
        searchtext: value
      }
    });
  }
}
