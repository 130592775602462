import * as React from "react";
import { connect } from "react-redux";
import { Props, mapStateToProps, mapDispatchToProps } from "./ForwardButton";
import { Button, Icon } from "antd";

const BackButton: React.SFC<Props> = ({
  role,
  statusId,
  workflowId,
  onStatusChange,
  travelerId,
  visaId
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  let text = "";
  let nextStatus: number;
  switch (workflowId) {
    case 1:
      switch (statusId) {
        case 20: // Pending
          if (role === "sales") {
            text = "Sales";
            nextStatus = 10;
          }
          break;
        case 30: // Data entry
          if (role === "data-entry") {
            text = "Sales";
            nextStatus = 10;
          }
          break;
        case 50: // Ready to submit
          if (role === "runner") {
            text = "Sales";
            nextStatus = 10;
          }
          break;
        default:
          break;
      }
      break;
    case 2:
      switch (statusId) {
        case 20: // Pending
          if (role === "sales") {
            text = "Sales";
            nextStatus = 10;
          }
          break;
        case 30: // Data entry
          if (role === "data-entry") {
            text = "Sales";
            nextStatus = 10;
          }
          break;
        default:
          break;
      }
      break;
    case 3:
      switch (statusId) {
        case 20: // Pending
          if (role === "sales") {
            text = "Sales";
            nextStatus = 10;
          }
          break;
        case 30: // Data entry
          if (role === "data-entry") {
            text = "Sales";
            nextStatus = 10;
          }
          break;
        case 50: // Ready to submit
          if (role === "runner") {
            text = "Sales";
            nextStatus = 10;
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return text.length > 0 ? (
    <Button
      loading={loading}
      type="primary"
      className="orange"
      onClick={() => {
        setLoading(true);
        onStatusChange(visaId, travelerId, nextStatus, false).then(() => {
          setLoading(false);
        });
      }}
    >
      <span>
        <Icon type="caret-left" style={{ verticalAlign: "middle" }} /> Back to{" "}
        {text}
      </span>
    </Button>
  ) : null;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackButton);
