import AbstractRestService from "./AbstractRestService";
import RequestStatuses from "../../model/RequestStatus";

export default class RequestStatusesService extends AbstractRestService<
  RequestStatuses
> {
  constructor() {
    super("Codelists/RequestStatuses");
  }
}
