import RequestService, {
  Badges
} from "../helpers/model_services/RequestService";
import { AppState } from "../store/appStore";
import {
  createSlice,
  ThunkAction,
  AnyAction
} from "@redux-ts-starter-kit/core";

export interface BadgesState {
  loading: boolean;
  badges: Badges;
  error: string;
}

export interface BadgesActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: Badges;
}

const service = new RequestService();

export const badgesSlice = createSlice<BadgesActions, BadgesState, AppState>({
  slice: "badges",
  initialState: {
    loading: false,
    badges: {
      bcCount: 0,
      callMeCount: 0,
      reqCount: 0
    },
    error: "Error!"
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      loading: false,
      badges: payload
    })
  }
});

const fetchBadges = (): ThunkAction<
  void,
  AppState,
  null,
  AnyAction
> => async dispatch => {
  const { fetchStart, fetchError, fetchSuccess } = badgesActions;
  dispatch(fetchStart());
  try {
    const { data } = await service.getBadges();
    dispatch(fetchSuccess(data));
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
};

export const badgesActions = {
  ...badgesSlice.actions,
  fetchBadges
};
export const badgesReducer = badgesSlice.reducer;
