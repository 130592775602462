import * as React from "react";
import { Formik, Field, FieldProps } from "formik";
import { Form, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";

export interface Props<T> {
  note: T;
  onSubmit: (values: T) => void;
  onCancel: () => void;
}

function NotesForm<T>({ note, onSubmit, onCancel }: Props<T>) {
  return (
    <Formik<T>
      enableReinitialize
      initialValues={note}
      onSubmit={onSubmit}
      render={({ handleSubmit }) => {
        return (
          <Form layout="horizontal" hideRequiredMark onSubmit={handleSubmit}>
            <Field
              name="text"
              render={({ field }: FieldProps) => (
                <Form.Item label="Text">
                  <TextArea {...field} rows={4} />
                </Form.Item>
              )}
            />
            <div
              style={{
                left: 0,
                bottom: 0,
                width: "100%",
                borderTop: "1px solid #e9e9e9",
                padding: "10px 16px",
                background: "#fff",
                textAlign: "right"
              }}
            >
              <Button onClick={onCancel} style={{ marginRight: 8 }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default NotesForm;
