import AbstractRestService from "./AbstractRestService";
import { AxiosPromise } from "axios";
import DocumentsCheckListItem from "../../model/DocumentsCheckListItem";

export default class TravelerDocumentsCheckListsService extends AbstractRestService<
  DocumentsCheckListItem
> {
  constructor() {
    super("TravelerCheckList");
  }

  getCheckListById(
    travelerId: number,
    visaId: number
  ): AxiosPromise<DocumentsCheckListItem[]> {
    return this._http.post(`${this.apiUrl}${travelerId}/checklist_v1`, null, {
      params: { visaRequestId: visaId }
    });
  }

  updateCheckList(
    object: DocumentsCheckListItem
  ): AxiosPromise<DocumentsCheckListItem[]> {
    return this._http.put(`${this.apiUrl}updatechecklist`, object);
  }

  regenerateChecklist(travelerId: number, visaId: number): AxiosPromise {
    return this._http.post(`${this.apiUrl}${travelerId}/rebuild`, null, {
      params: { visaRequestId: visaId }
    });
  }
}
