import * as React from "react";
import { Table, Tooltip, Icon } from "antd";
import TravelerDocument from "../../model/TravelerDocument";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";
import moment from "moment/moment.js";

export interface Props {
  travelerId: number;
  docTypeId: number;
  visible: boolean;
}

const docService = new TravelerDocumentsService();

const DeletedDocuments: React.FC<Props> = ({
  travelerId,
  docTypeId,
  visible
}) => {
  const [docs, setDocs] = React.useState<TravelerDocument[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchDocs = async () => {
    setLoading(true);
    try {
      const { data } = await docService.getDeletedFiles(travelerId, docTypeId);
      setDocs(data);
    } catch (error) {}
    setLoading(false);
  };
  React.useEffect(() => {
    if (visible) {
      fetchDocs();
    }
  }, [visible]);

  return (
    <Table<TravelerDocument>
      loading={loading}
      size="middle"
      dataSource={docs}
      rowKey={({ id, documentsTypes }) =>
        id ? id.toString() : documentsTypes.name
      }
      pagination={{
        defaultPageSize: 5,
        showSizeChanger: true,
        pageSizeOptions: ["5", "10", "15"],
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
      }}
      columns={[
        {
          width: 30,
          render: (text, { documentsTypes: { iconName }, note }) =>
            note && note !== null ? (
              <Tooltip placement="top" title={note}>
                <i style={{ fontSize: 20 }} className={iconName} />
              </Tooltip>
            ) : (
              <i style={{ fontSize: 20 }} className={iconName} />
            )
        },
        {
          title: "Requested",
          render: (text, { documentsTypes }) =>
            documentsTypes ? documentsTypes.name : null
        },
        {
          dataIndex: "createdAt",
          title: "Created",
          className: "table-center",
          render: text =>
            text !== null && text ? moment(text).format("D.M.YYYY") : null
        },
        {
          render: (text, doc, index) => (
            <Tooltip placement="top" title="View">
              <a
                className="no-color"
                onClick={() => {
                  docService
                    .getDocumentURL(doc.id)
                    .then(({ data: { documentUrl } }) => {
                      const link = document.createElement("a");
                      link.href = documentUrl;
                      link.target = "_blank";
                      link.rel = "noopener noreferrer";
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });
                }}
              >
                <Icon
                  type="eye"
                  theme="twoTone"
                  twoToneColor="#fcad1c"
                  style={{ fontSize: 20 }}
                />
              </a>
            </Tooltip>
          )
        }
      ]}
    />
  );
};

export default DeletedDocuments;
