import * as React from "react";
import { Button, Drawer, Form, Row, Col, Icon, Spin, message } from "antd";
import { travelerActions } from "../../redux/traveler/travelerSlice";
import { connect } from "react-redux";
import { Mode } from "../../types";
import { Formik } from "formik";
import Traveler from "../../model/Traveler";
import PersonalInformation from "./PersonalInformation";
import TravelerContacts from "./TravelerContacts";
import Iqama from "./Iqama";
import Employments from "./Employments";
import Dragger from "antd/lib/upload/Dragger";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";
import PassportInfo from "./PassportInfo";
import { AppState } from "../../store/appStore";
import Family from "./Family";
import TravelerFamilyMember from "../../model/TravelerFamilyMember";

export interface Props {
  changeMode: (newMode: Mode) => void;
  onSubmit: (val: Traveler, file?: any) => Promise<boolean>;
  mode: Mode;
}

const docService = new TravelerDocumentsService();

const NewTraveler: React.FC<Props> = ({ changeMode, onSubmit, mode }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<any>();
  const disabled = mode === "view";
  return (
    <React.Fragment>
      <Button
        type="primary"
        onClick={() => {
          changeMode("new");
          setVisible(true);
        }}
        className="dark"
        size="small"
      >
        + Add traveler
      </Button>
      <Formik<{ traveler: Traveler }>
        enableReinitialize
        initialValues={{
          traveler: {
            isSaudi: false,
            passportTypeId: 2,
            travelerFamilyMembers: [] as TravelerFamilyMember[]
          } as Traveler
        }}
        onSubmit={(values, { resetForm }) =>
          onSubmit(values.traveler, file).then(res => {
            if (res) {
              setVisible(false);
              changeMode("view");
              setFile(undefined);
              resetForm();
              message.success("Traveler was succesfully created");
            }
          })
        }
        render={({ handleSubmit, setFieldValue, values, resetForm }) => {
          return (
            <Drawer
              title="New traveler"
              width="80%"
              onClose={() => {
                setVisible(false);
                changeMode("view");
                resetForm();
                setFile(undefined);
              }}
              visible={visible}
            >
              <Spin tip="Loading..." spinning={loading}>
                <Form
                  layout="horizontal"
                  hideRequiredMark
                  onSubmit={handleSubmit}
                >
                  <Row gutter={12} style={{ marginBottom: 12 }}>
                    <Dragger
                      showUploadList={false}
                      name="files"
                      customRequest={({ onSuccess, file }: any) => {
                        setLoading(true);
                        setFile(file);
                        docService
                          .getPassportValues(file)
                          .then(response => {
                            onSuccess(response, file);
                            const { data } = response;
                            const name = data.firstName;
                            delete data.firstName;
                            const passportValidUntil = data.expirationDate;
                            delete data.expirationDate;
                            const recognizedData = data.recognizedText;
                            delete data.recognizedData;
                            setFieldValue("traveler", {
                              ...values.traveler,
                              ...data,
                              name,
                              passportValidUntil,
                              recognizedData
                            });
                            setLoading(false);
                          })
                          .catch(() => setLoading(false));
                      }}
                      multiple={false}
                    >
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag passport file to this area to auto fill
                        form
                      </p>
                      <p className="ant-upload-hint">
                        Support for a single upload only
                      </p>
                    </Dragger>
                  </Row>
                  <Row gutter={12}>
                    <PersonalInformation
                      recognizedData={values.traveler.recognizedData}
                      disabled={disabled}
                    />
                  </Row>
                  <Row gutter={12}>
                    <TravelerContacts disabled={disabled} />
                  </Row>
                  <Row gutter={12}>
                    <PassportInfo disabled={disabled} />
                  </Row>
                  <Row gutter={12}>
                    <Iqama
                      disabled={disabled}
                      identityRecognizedData={
                        values.traveler.identityRecognizedData
                      }
                    />
                  </Row>
                  <Row gutter={12}>
                    <Employments disabled={disabled} />
                  </Row>
                  <Row gutter={12}>
                    <Family disabled={disabled} />
                  </Row>
                  <div
                    style={{
                      left: 0,
                      bottom: 0,
                      width: "100%",
                      borderTop: "1px solid #e9e9e9",
                      padding: "10px 16px",
                      background: "#fff",
                      textAlign: "right"
                    }}
                  >
                    <Button
                      onClick={() => {
                        setVisible(false);
                        changeMode("view");
                      }}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </Button>
                    <Button htmlType="submit" type="primary">
                      Submit
                    </Button>
                  </div>
                </Form>
              </Spin>
            </Drawer>
          );
        }}
      />
    </React.Fragment>
  );
};
const mapStateToProps = ({ traveler: { mode } }: AppState) => ({
  mode
});

const mapDispatchToProps = {
  onSubmit: travelerActions.submitTraveler,
  changeMode: travelerActions.changeMode
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewTraveler);
