import * as React from "react";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";
import { Modal, Button, Icon } from "antd";

export interface Props {
  id: number;
}
const service = new TravelerDocumentsService();
const TravelerPhoto: React.FC<Props> = ({ id }) => {
  return (
    <Button
      type="link"
      onClick={e => {
        e.stopPropagation();

        service.getDocumentURL(id).then(({ data: { documentUrl } }) => {
          const link = document.createElement("a");
          link.href = documentUrl;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }}
    >
      <Icon type="eye" style={{ fontSize: 20 }} />
    </Button>
  );
};

export default TravelerPhoto;
