import AbstractRestService from "./AbstractRestService";
import Discount from "../../model/Discount";

export default class DiscountService extends AbstractRestService<Discount> {
  constructor() {
    super("Discont");
  }

  addDiscount(reqId: number, discountId: number) {
    return this._http.post(`${this.apiUrl}webcreatediscont`, null, {
      params: { requestId: reqId, discontId: discountId }
    });
  }
}
