import * as React from "react";
import BasicPage from "../../_common/BasicPage/BasicPage";
import CompanyService from "../../../helpers/model_services/CompanyService";
import useSelectVals from "../../_hooks/useSelectVals";
import CountryService from "../../../helpers/model_services/CountryService";
import { useSearchProps } from "../../_hooks/useColumnProps";
import Company from "../../../model/Company";

export interface Props {}

const CompaniesPage: React.SFC<Props> = () => {
  const { data: countries } = useSelectVals(new CountryService());

  return (
    <BasicPage<Company>
      restService={new CompanyService()}
      inputsConfig={[
        {
          label: "Name",
          inputProps: { autoFocus: true },
          ...useSearchProps("name")
        },
        {
          label: "Country",
          inputProps: { type: "select" },
          selectOptions: countries.map(({ name, id }) => {
            return { displayValue: name, dataValue: id };
          }),
          ...useSearchProps("servicePlaceTypeId"),
          render: record => {
            const retVal = countries.find(item => record === item.id);
            return retVal ? retVal.name : null;
          }
        },
        {
          label: "Address",
          ...useSearchProps("alpha3")
        },
        {
          label: "Email",
          ...useSearchProps("numCode")
        },
        {
          label: "Phone",
          ...useSearchProps("phone")
        },
        {
          label: "Bank connection",
          ...useSearchProps("bankConnection"),
          inputProps: { type: "textarea" }
        },
        {
          label: "Insurance No.",
          ...useSearchProps("insuranceNo")
        },
        {
          label: "Insurance mail",
          ...useSearchProps("insuranceMail")
        }
      ]}
    />
  );
};

export default CompaniesPage;
