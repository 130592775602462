import * as React from "react";
import MyCard from "../../_common/MyCard";
import { Table, Row } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";
import RequestService, {
  ISearch,
  RequestListItem
} from "../../../helpers/model_services/RequestService";

import { VisaRequestListItem } from "../../../helpers/model_services/VisaRequestService";
import getVisaStatusParam from "./visaStatusParam";
import { reqCols, bcCols, visaCols } from "./columns";
import Title from "antd/lib/typography/Title";

export interface Props extends RouteComponentProps<{ searchValue: string }> {}

export interface State {
  loading: boolean;
  data: ISearch;
}

const requestService = new RequestService();

class SearchPage extends React.Component<Props, State> {
  state: State = {
    loading: false,
    data: {
      requests: [],
      bcs: [],
      visas: []
    }
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.match.params.searchValue !== prevProps.match.params.searchValue
    ) {
      this.fetch();
    }
  }

  fetch = async () => {
    const {
      match: {
        params: { searchValue }
      }
    } = this.props;
    this.setState({ loading: true });
    const { data } = await requestService.search(searchValue);
    this.setState({ data, loading: false });
  };

  render() {
    const {
      loading,
      data: { requests, visas, bcs }
    } = this.state;

    return (
      <MyCard
        headStyle={{ backgroundColor: "transparent" }}
        style={{
          marginTop: 84
        }}
      >
        <Row>
          <Table<RequestListItem>
            title={() => <Title level={4}>Requests</Title>}
            loading={loading}
            columns={reqCols}
            dataSource={requests}
            size="middle"
            onRow={row => ({
              onClick: () => {
                this.props.history.push(`/requests/all/${row.id}`);
              }
            })}
            rowKey={({ id }) => id.toString()}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
            }}
          />
        </Row>
        <Row>
          <Table<RequestListItem>
            title={() => <Title level={4}>Business cases</Title>}
            loading={loading}
            columns={bcCols}
            dataSource={bcs}
            size="middle"
            onRow={row => ({
              onClick: () => {
                this.props.history.push(`/business-cases/all/${row.id}`);
              }
            })}
            rowKey={({ id }) => id.toString()}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
            }}
          />
        </Row>
        <Row>
          <Table<VisaRequestListItem>
            title={() => <Title level={4}>VISA requests</Title>}
            loading={loading}
            columns={visaCols}
            dataSource={visas}
            size="middle"
            onRow={row => ({
              onClick: () => {
                this.props.history.push(
                  `/visa/${getVisaStatusParam(row.statusId)}/${row.id}`
                );
              }
            })}
            rowKey={({ id }) => id.toString()}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30"],
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
            }}
          />
        </Row>
      </MyCard>
    );
  }
}

export default withRouter(SearchPage);
