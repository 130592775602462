import AbstractRestService from "./AbstractRestService";
import Region from "../../model/Region";
import { AxiosPromise } from "axios";

export default class PriceListService extends AbstractRestService<Region> {
  constructor() {
    super("Codelists/PriceList");
  }

  checkServicePlace(
    countryId: number,
    isSaudi: boolean
  ): AxiosPromise<1 | 0 | -1> {
    return this._http.post(`${this.apiUrl}viewserviceplace`, {
      countryId,
      isSaudi
    });
  }
}
