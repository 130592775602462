import * as React from "react";
import MyCard from "../../_common/MyCard";
import { Row, Col, Spin } from "antd";
import InvoiceTable from "./InvoiceTable";
import PaymentsTable from "./PaymentsTable";
import FinanceOverview from "./FinanceOverview";
import NewInvoice from "./NewInvoice";
import { AppState } from "../../../store/appStore";
import { connect } from "react-redux";
import InvoiceService from "../../../helpers/model_services/InvoiceService";
import Invoice from "../../../model/Invoice";
import DeletedInvoices from "./DeletedInvoices";
import "./FinanceDetail.css";

export interface Props {
  deletedInvoices: Invoice[];
  loading: boolean;
}

const FinanceDetail: React.FC<Props> = ({ deletedInvoices, loading }) => {
  return (
    <Spin spinning={loading}>
      <Row>
        <Col span={18}>
          <Row>
            <NewInvoice />
          </Row>
          <Row>
            <PaymentsTable />
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <FinanceOverview />
          </Row>
          <Row>
            <MyCard
              title="Generated invoices"
              extra={<DeletedInvoices deletedInvoice={deletedInvoices} />}
            >
              <GeneratedInvoices />
            </MyCard>
          </Row>
          <Row>
            <MyCard
              title="Generated payment orders"
              extra={
                <DeletedInvoices
                  deletedInvoice={deletedInvoices}
                  isPaymentOrder
                />
              }
            >
              <GeneratedPaymentOrders />
            </MyCard>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

const GeneratedInvoices = () => <InvoiceTable />;

const GeneratedPaymentOrders = () => <InvoiceTable isPaymentOrder />;

const mapStateToProps = ({
  finance: { deletedInvoices, loading }
}: AppState) => ({
  deletedInvoices,
  loading
});

export default connect(mapStateToProps)(FinanceDetail);
