import { createSlice } from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import { Mode } from "../../types";
import VisaRequest from "../../model/VisaRequest";
import VisaRequestService, {
  VisaOptions,
  ExtraFee
} from "../../helpers/model_services/VisaRequestService";
import { requestActions } from "../request/requestSlice";
import { travelerActions } from "../traveler/travelerSlice";
import { visaNoteActions } from "./visaNoteSlice";
import TravelerDocumentsCheckListsService from "../../helpers/model_services/TravelersCheckListService";
import DocumentsCheckList from "../../model/DocumentsCheckListItem";
import DocumentsCheckListItem from "../../model/DocumentsCheckListItem";
import getName from "../../helpers/MyFunctions";
import { message } from "antd";

export interface VisaRequestState {
  loading: boolean;
  visa: VisaRequest;
  workflowId: number;
  error: string;
  visible: boolean;
  mode: Mode;
  inVisaSection: boolean;
  checklist: DocumentsCheckListItem[];
  extraFees: ExtraFee[];
}

export interface VisaRequestActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: VisaRequest;
  setVisible: boolean;
  changeMode: Mode;
  setVisaSection: boolean;
  setChecklist: DocumentsCheckListItem[];
  setWorkflowId: number;
  setExtraFees: ExtraFee[];
  setLoading: boolean;
}

export const visaSlice = createSlice<
  VisaRequestActions,
  VisaRequestState,
  AppState
>({
  slice: "visa",
  initialState: {
    loading: false,
    visa: {} as VisaRequest,
    error: "Error!",
    visible: false,
    mode: "view",
    inVisaSection: false,
    checklist: [],
    workflowId: 0,
    extraFees: []
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      visa: payload,
      loading: false,
      visible: true
    }),
    setVisible: (state, payload) => ({
      ...state,
      visible: payload
    }),
    changeMode: (state, payload) => ({
      ...state,
      mode: payload
    }),
    setVisaSection: (state, payload) => ({
      ...state,
      inVisaSection: payload
    }),
    setChecklist: (state, payload) => ({
      ...state,
      checklist: payload
    }),
    setWorkflowId: (state, payload) => ({
      ...state,
      workflowId: payload
    }),
    setExtraFees: (state, payload) => ({
      ...state,
      extraFees: payload
    }),
    setLoading: (state, payload) => ({
      ...state,
      loading: payload
    })
  }
});

const {
  fetchError,
  fetchStart,
  fetchSuccess,
  setChecklist,
  setWorkflowId,
  setExtraFees,
  changeMode,
  setLoading
} = visaSlice.actions;

const visaService = new VisaRequestService();
const checklistService = new TravelerDocumentsCheckListsService();

const fetchVisaRequest = (
  visaId?: number,
  fetchFromScratch?: boolean
): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    visa: {
      visa: { id: _id },
      inVisaSection
    }
  } = getState();
  const _inVisaSection = fetchFromScratch ? fetchFromScratch : inVisaSection;
  const id = visaId ? visaId : _id;
  dispatch(fetchStart());
  dispatch(visaNoteActions.fetchStart());
  try {
    const { data: visa } = await visaService.getById(id);
    dispatch(fetchChecklist(visa.travelerId, visa.id));
    const { data: workflowId } = await visaService.getWorkflowId(id);
    dispatch(setWorkflowId(workflowId));
    const { data: extraFees } = await visaService.getExtraFees(id);
    dispatch(setExtraFees(extraFees));

    if (_inVisaSection) {
      dispatch(travelerActions.fetchTraveler(visa.travelerId));
      const { data: reqId } = await visaService.getReqId(id);
      await dispatch(requestActions.fetchRequest(reqId));
      dispatch(requestActions.setVisible(false));
    }

    dispatch(fetchSuccess(visa));
    dispatch(visaNoteActions.fetchSuccess(visa.noteVisaRequest));
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
};

const changeStatus = (
  visaId: number,
  travelerId: number,
  statusId: number,
  verify: boolean
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const fetchRequest = !getState().visa.inVisaSection;
    dispatch(fetchStart());
    if (verify) {
      const resp = await dispatch(
        travelerActions.verifyChecklist(travelerId, visaId)
      );

      if (resp) {
        const { data } = await visaService.getById(visaId);
        const newVisa = data;
        newVisa.visaRequestStatusesId = statusId;
        await visaService.update(newVisa);
        await dispatch(fetchVisaRequest());
        if (fetchRequest) {
          await dispatch(requestActions.fetchRequest());
        }

        return true;
      } else {
        dispatch(fetchError("Cancelled by user."));
      }
    } else {
      const { data } = await visaService.getById(visaId);
      const newVisa = data;
      newVisa.visaRequestStatusesId = statusId;
      await visaService.update(newVisa);
      await dispatch(fetchVisaRequest());
      if (fetchRequest) {
        await dispatch(requestActions.fetchRequest());
      }

      return true;
    }
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
  return false;
};

const deleteVisa = (
  visaId: number
): ThunkAction<void, AppState, null, AnyAction> => async dispatch => {
  try {
    const { data: visa } = await visaService.getById(visaId);
    visaService.delete(visa).then(() => {
      dispatch(requestActions.fetchRequest());
    });
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
};

const updateVisaRequest = (
  visa: VisaRequest
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  dispatch(fetchStart());
  try {
    const {
      visa: { inVisaSection }
    } = getState();
    const { status } = await visaService.update(visa);
    await dispatch(fetchVisaRequest(visa.id, inVisaSection));
    return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
  return false;
};

const submitVisaRequest = (
  values: VisaRequest
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  dispatch(fetchStart());
  try {
    const { mode } = getState().visa;
    switch (mode) {
      case "new":
        const { status: statusC } = await visaService.create(values);
        if (statusC === 200) {
          dispatch(requestActions.fetchRequest());
          dispatch(setLoading(false));
          return true;
        } else {
          dispatch(fetchError(`Status ${statusC}`));
          return false;
        }
      case "edit":
        const { status: statusU } = await visaService.update(values);
        if (statusU === 200) {
          dispatch(fetchVisaRequest());
          return true;
        } else {
          dispatch(fetchError(`Status ${statusU}`));
          return false;
        }
      default:
        dispatch(fetchError("Invalid edit mode for this action"));
        return false;
    }
  } catch (error) {
    dispatch(fetchError(error.toString()));
    return false;
  }
};

const fetchChecklist = (
  travelerId?: number,
  visaId?: number
): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    visa: {
      visa: { id: _visaId, travelerId: _travelerId }
    }
  } = getState();
  const vId = visaId ? visaId : _visaId;
  const tId = travelerId ? travelerId : _travelerId;
  const { data: checklist } = await checklistService.getCheckListById(tId, vId);
  dispatch(setChecklist(checklist));
};

const updateChecklist = (
  check: DocumentsCheckList
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const { data: checklist } = await checklistService.updateCheckList(check);
    dispatch(setChecklist([...checklist]));
    return true;
  } catch (error) {
    return false;
  }
};

const updateExtraFees = (
  extraFees: ExtraFee[]
): ThunkAction<Promise<ExtraFee[]>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const { data: newExtraFees } = await visaService.setExtraFees(extraFees);
    await dispatch(setExtraFees(newExtraFees));

    return newExtraFees;
  } catch (error) {
    return [];
  }
};

const manageInsurance = (
  isCancelled: boolean
): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const {
      visa: {
        visa: { id }
      }
    } = getState();
    dispatch(fetchStart());
    await visaService.manageInsurance(id, isCancelled);
    dispatch(fetchVisaRequest());
  } catch (error) {
    dispatch(fetchError(error.toString()));
  }
};

const downloadInsurance = (
  type: number
): ThunkAction<Promise<number>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  try {
    const {
      visa: {
        visa: { id }
      },
      user: {
        user: { name, lastName }
      }
    } = getState();
    dispatch(fetchStart());
    const { data } = await visaService.downloadInsurance(
      id,
      type,
      getName(name, lastName)
    );
    await dispatch(fetchVisaRequest());
    return data;
  } catch (error) {}
  return -1;
};

const regenerateChecklist = (
  travelerId?: number,
  visaId?: number
): ThunkAction<void, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const {
    visa: {
      visa: { id: _visaId, travelerId: _travelerId }
    }
  } = getState();
  const vId = visaId ? visaId : _visaId;
  const tId = travelerId ? travelerId : _travelerId;
  try {
    await checklistService.regenerateChecklist(tId, vId);
    dispatch(fetchChecklist(tId, vId));
    message.success("Checklist was succesfully regenerated.");
  } catch (error) {}
};

const { actions } = visaSlice;

export const visaActions = {
  ...actions,
  fetchVisaRequest,
  updateVisaRequest,
  submitVisaRequest,
  changeStatus,
  deleteVisa,
  fetchChecklist,
  updateChecklist,
  updateExtraFees,
  manageInsurance,
  downloadInsurance,
  regenerateChecklist
};
export const visaReducer = visaSlice.reducer;
