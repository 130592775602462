import { VisaRequestNote } from "../../model/Note";
import { Mode } from "../../types";
import {
  createSlice,
  ThunkAction,
  AnyAction
} from "@redux-ts-starter-kit/core";
import { AppState } from "../../store/appStore";
import getName from "../../helpers/MyFunctions";
import VisaNoteService from "../../helpers/model_services/VisaNoteService";
import { visaActions } from "./visaSlice";

export interface VisaNoteState {
  loading: boolean;
  visaNotes: VisaRequestNote[];
  error: string;
  visible: boolean;
  mode: Mode;
}

export interface VisaNoteActions {
  fetchStart: never;
  fetchError: string;
  fetchSuccess: VisaRequestNote[];
  setVisible: boolean;
  setMode: Mode;
}

const service = new VisaNoteService();

export const visaNoteSlice = createSlice<
  VisaNoteActions,
  VisaNoteState,
  AppState
>({
  slice: "visaNotes",
  initialState: {
    loading: false,
    visaNotes: [],
    error: "Error!",
    visible: false,
    mode: "view"
  },
  cases: {
    fetchStart: state => ({
      ...state,
      loading: true
    }),
    fetchError: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    }),
    fetchSuccess: (state, payload) => ({
      ...state,
      loading: false,
      visaNotes: payload
    }),
    setVisible: (state, payload) => ({
      ...state,
      visible: payload
    }),
    setMode: (state, payload) => ({
      ...state,
      mode: payload
    })
  }
});

const submitNote = (
  values: VisaRequestNote
): ThunkAction<Promise<boolean>, AppState, null, AnyAction> => async (
  dispatch,
  getState
) => {
  const { fetchStart, fetchError } = visaNoteActions;
  dispatch(fetchStart());
  try {
    const {
      visaNotes: { mode },
      user: {
        user: { name, lastName }
      }
    } = getState();
    switch (mode) {
      case "new":
        await service.create({ ...values, user: getName(name, lastName) });
        break;

      case "edit":
        await service.update(values);
        break;

      case "delete":
        await service.delete(values);
        break;

      default:
        dispatch(fetchError("Invalid edit mode for this action"));
        return false;
    }
    await dispatch(visaActions.fetchVisaRequest());
    return true;
  } catch (error) {
    dispatch(fetchError(error.toString()));
    return false;
  }
};

export const visaNoteActions = {
  ...visaNoteSlice.actions,
  submitNote
};
export const visaNoteReducer = visaNoteSlice.reducer;
