import { useState, useEffect } from "react";
import { Mode } from "../../types";
import BaseClass from "../../model/_BaseClass";

function useData<T extends BaseClass>(customData?: T[]) {
  const [data, setData] = useState<T[]>(() => {
    const _data = customData ? customData : [];
    return _data;
  });
  const [selected, setSelected] = useState<T>();
  const [action, setAction] = useState<Mode>("view");

  const handleAction = (action: Mode, selectedRecord?: T) => {
    return () => {
      setAction(action);
      setSelected(selectedRecord);
    };
  };

  function getEditableData() {
    return data.map(item => ({
      ...item,
      delete: item.id ? handleAction("delete", item) : undefined,
      edit: handleAction("edit", item)
    }));
  }

  useEffect(() => {
    if (customData) {
      setData(customData);
    }
  }, [customData]);

  return {
    data: getEditableData(),
    setData,
    action,
    setAction,
    selected,
    setSelected,
    handleAction
  };
}

export default useData;
