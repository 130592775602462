import * as React from "react";
import { ColumnProps, FilterDropdownProps } from "antd/lib/table";
import { Input, Button, Row, DatePicker } from "antd";
import Highlighter from "react-highlight-words";
import BaseClass from "../../model/_BaseClass";
import moment, { Moment } from "moment/moment.js";

function getDefaultProps<T extends BaseClass>(
  dataIndex: string,
  searchInput?: Input | null,
  searchText?: string
): ColumnProps<T> {
  return {
    dataIndex,
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (searchInput && searchInput !== null) {
            searchInput.focus();
          }
        });
      }
    },
    render: searchText
      ? (text: any, record: T, index: number) => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text !== null ? text.toString() : ""}
          />
        )
      : undefined
  };
}

export function useSearchProps<T extends BaseClass>(
  dataIndex: string,
  config: {
    apiFilter?: boolean;
    isNumber?: boolean;
    isBoolean?: boolean;
    select?: { values: BaseClass[]; selectDataIndex?: string };
  } = {}
): ColumnProps<T> {
  const [searchInput, setSearchInput] = React.useState<Input | null>(null);
  const [searchText, setSearchText] = React.useState<string>("");
  const { apiFilter, isNumber, isBoolean, select } = config;

  const apiFilterProps: ColumnProps<T> = apiFilter
    ? {
        sorter: true
      }
    : {
        sorter: (a: T, b: T) => {
          if (isNumber) {
            return a[dataIndex] - b[dataIndex];
          } else if (isBoolean) {
            return a[dataIndex] === b[dataIndex] ? 0 : a[dataIndex] ? 1 : -1;
          } else if (select) {
            const { values, selectDataIndex } = select;
            const _selectDataIndex = selectDataIndex ? selectDataIndex : "name";
            const i1 = values.findIndex(v => v.id === a[dataIndex]);
            const i2 = values.findIndex(v => v.id === b[dataIndex]);
            if (i1 === -1) {
              return -1;
            } else if (i2 === -1) {
              return 1;
            } else {
              const v1 = values[i1][_selectDataIndex];
              const v2 = values[i2][_selectDataIndex];
              return v1.localeCompare(v2);
            }
          } else {
            return a[dataIndex] && b[dataIndex]
              ? a[dataIndex].toString().localeCompare(b[dataIndex].toString())
              : 0;
          }
        },
        onFilter: (value: any, record: T) => {
          if (select) {
            const { values, selectDataIndex } = select;
            const _selectDataIndex = selectDataIndex ? selectDataIndex : "name";
            const index = values.findIndex(v => v.id === record[dataIndex]);
            if (index !== -1) {
              const val = values[index][_selectDataIndex];
              if (val && val !== null) {
                return val
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase());
              }
            }
            return false;
          }
          return (
            record[dataIndex] &&
            record[dataIndex] !== null &&
            record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          );
        },
        render:
          isBoolean || select
            ? (text, record) => {
                if (isBoolean) {
                  return text ? "Yes" : "No";
                } else if (select) {
                  const { values, selectDataIndex } = select;
                  const _selectDataIndex = selectDataIndex
                    ? selectDataIndex
                    : "name";
                  const index = values.findIndex(
                    v => v.id === record[dataIndex]
                  );
                  return index === -1 ? null : values[index][_selectDataIndex];
                }
              }
            : undefined
      };
  return {
    ...getDefaultProps(dataIndex, searchInput, searchText),
    ...apiFilterProps,

    filterDropdown: isBoolean
      ? undefined
      : ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters
        }: FilterDropdownProps) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                setSearchInput(node);
              }}
              placeholder={`Search ${dataIndex}`}
              value={
                selectedKeys && selectedKeys.length > 0 ? selectedKeys[0] : ""
              }
              onChange={e => {
                setSelectedKeys!(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm!();
                setSearchText(selectedKeys![0].toString());
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
              type="primary"
              onClick={() => {
                confirm!();
                setSearchText(selectedKeys![0].toString());
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters!(selectedKeys!.map(v => v.toString()));
                setSearchText("");
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </div>
        )
  };
}

export function useIntervalProps<T extends BaseClass>(
  dataIndex: string,
  apiFilter: boolean = false
): ColumnProps<T> {
  const [searchInput, setSearchInput] = React.useState<Input | null>(null);
  const [tempFrom, setTempFrom] = React.useState<number>(0);
  const [tempTo, setTempTo] = React.useState<number>(0);
  const [from, setFrom] = React.useState<number>(0);
  const [to, setTo] = React.useState<number>(0);
  React.useEffect(() => {
    if (from !== tempFrom) {
      setTempFrom(from);
    }
    if (to !== tempTo) {
      setTempTo(to);
    }
  }, [from, to]);

  const apiFilterProps: ColumnProps<T> = !apiFilter
    ? {
        onFilter: (value: any, record: T) => {
          const _value: number =
            record[dataIndex] === null ? 0 : Number(record[dataIndex]);

          return from <= _value && _value <= to;
        },
        sorter: (a, b) => a[dataIndex] - b[dataIndex]
      }
    : { sorter: true };
  return {
    ...getDefaultProps(dataIndex, searchInput),
    ...apiFilterProps,
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: FilterDropdownProps) => {
      const _confirm = () => {
        confirm!();
        setFrom(tempFrom);
        setTo(tempTo);
      };
      return (
        <div style={{ padding: 8 }}>
          <Row>
            <Input
              ref={node => {
                setSearchInput(node);
              }}
              placeholder={`From`}
              value={tempFrom}
              onChange={e => {
                const curKeys = selectedKeys ? [...selectedKeys] : [];
                setTempFrom(Number(e.target.value));
                switch (curKeys.length) {
                  case 2:
                    curKeys[0] = e.target.value;
                    break;
                  case 1:
                    curKeys[0] = e.target.value;
                    break;
                  case 0:
                    curKeys.push(e.target.value);
                    break;
                  default:
                    break;
                }

                setSelectedKeys!(curKeys.map(v => v.toString()));
              }}
              onPressEnter={() => {
                _confirm();
              }}
              style={{ width: 90, marginBottom: 8, marginRight: 8 }}
            />

            <Input
              placeholder={`To`}
              value={tempTo}
              onChange={e => {
                const curKeys = selectedKeys ? [...selectedKeys] : [];
                setTempTo(Number(e.target.value));
                switch (curKeys.length) {
                  case 2:
                    curKeys[1] = e.target.value;
                    break;
                  case 1:
                    curKeys.push(e.target.value);
                    break;
                  case 0:
                    curKeys.push(String(from));
                    curKeys.push(e.target.value);
                    break;
                  default:
                    break;
                }

                setSelectedKeys!(curKeys.map(v => v.toString()));
              }}
              onPressEnter={() => {
                _confirm();
              }}
              style={{ width: 90, marginBottom: 8 }}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => {
                _confirm();
              }}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters!(selectedKeys!.map(v => v.toString()));
                setTo(0);
                setFrom(0);
              }}
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </Row>
        </div>
      );
    }
  };
}

export function useIntervalDateProps<T extends BaseClass>(
  dataIndex: string,
  apiFilter: boolean = false
): ColumnProps<T> {
  const initialMoment = moment();
  const [openFrom, setOpenFrom] = React.useState<boolean>(false);
  const [openTo, setOpenTo] = React.useState<boolean>(false);
  const [tempFrom, setTempFrom] = React.useState<Moment>(initialMoment);
  const [tempTo, setTempTo] = React.useState<Moment>(initialMoment);
  const [from, setFrom] = React.useState<Moment>(initialMoment);
  const [to, setTo] = React.useState<Moment>(initialMoment);
  const [filtered, setFiltered] = React.useState<boolean>(false);
  const apiFilterProps: ColumnProps<T> = !apiFilter
    ? {
        onFilter: (value: any, record: T) =>
          moment(record[dataIndex]).isBetween(from, to),
        sorter: (a: T, b: T) =>
          new Date(a[dataIndex]).getTime() - new Date(b[dataIndex]).getTime()
      }
    : {
        sorter: true
      };
  return {
    ...getDefaultProps(dataIndex),
    ...apiFilterProps,
    render: (text: Date) => moment(text).format("D.M.YYYY"),
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (!filtered) {
            setOpenFrom(true);
          }
        });
      } else {
        setOpenFrom(false);
        setOpenTo(false);
      }
    },
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }: FilterDropdownProps) => {
      const _confirm = () => {
        confirm!();
        setFiltered(true);
        setFrom(tempFrom);
        setTo(tempTo);
      };
      return (
        <div style={{ padding: 8 }}>
          <Row>
            <DatePicker
              open={openFrom}
              onOpenChange={status => {
                setOpenFrom(status);
                if (!status && !filtered) {
                  setOpenTo(true);
                }
              }}
              placeholder={`From`}
              value={tempFrom}
              onChange={e => {
                setTempFrom(e!);
                const curKeys = selectedKeys ? [...selectedKeys] : [];

                switch (curKeys.length) {
                  case 2:
                    curKeys[0] = e!.format("YYYY-MM-DD");
                    break;
                  case 1:
                    curKeys[0] = e!.format("YYYY-MM-DD");
                    break;
                  case 0:
                    curKeys.push(e!.format("YYYY-MM-DD"));
                    break;
                  default:
                    break;
                }

                setSelectedKeys!(curKeys.map(v => v.toString()));
              }}
              format="D.M.YYYY"
              style={{ width: 120, marginBottom: 8, marginRight: 8 }}
            />

            <DatePicker
              open={openTo}
              onOpenChange={status => {
                setOpenTo(status);
              }}
              placeholder={`To`}
              value={tempTo}
              onChange={e => {
                setTempTo(e!);
                const curKeys = selectedKeys ? [...selectedKeys] : [];

                switch (curKeys.length) {
                  case 2:
                    curKeys[1] = moment(e!).format("YYYY-MM-DD");
                    break;
                  case 1:
                    curKeys.push(moment(e!).format("YYYY-MM-DD"));
                    break;
                  case 0:
                    curKeys.push();
                    curKeys.push(moment(e!).format("YYYY-MM-DD"));
                    break;
                  default:
                    break;
                }

                setSelectedKeys!(curKeys.map(v => v.toString()));
              }}
              style={{ width: 120, marginBottom: 8 }}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => {
                _confirm();
              }}
              icon="search"
              size="small"
              style={{ width: 120, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters!(selectedKeys!.map(v => v.toString()));
                setFiltered(false);
              }}
              size="small"
              style={{ width: 120 }}
            >
              Reset
            </Button>
          </Row>
        </div>
      );
    }
  };
}
