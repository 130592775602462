import * as React from "react";
import { Row, Modal, Button, Icon, Spin, Tooltip, Col } from "antd";
import VisaForm from "./VisaForm";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import { visaActions } from "../../redux/visa/visaSlice";
import TravelerChecklist from "../traveler/TravelerChecklist";
import { Mode } from "../../types";
import TravelerAttachments from "../traveler/TravelerAttachments";
import { Dispatch } from "redux";
import { visaNoteActions } from "../../redux/visa/visaNoteSlice";
import Notes from "../_common/Notes";
import getName from "../../helpers/MyFunctions";
import VisaGeneratedDocs from "./VisaGeneratedDocs";
import Traveler from "../../model/Traveler";
import Title from "antd/lib/typography/Title";
import BackButton from "./BackButton";
import ForwardButton from "./ForwardButton";
import Insurance from "./Insurance";
import { travelerActions } from "../../redux/traveler/travelerSlice";

const VisaHeader: React.SFC<{
  internId: string;
  travelerName: string;
  countryInfo: string;
  isEditMode: boolean;
  changeMode: (newMode: Mode) => void;
}> = ({ internId, travelerName, countryInfo, isEditMode, changeMode }) => {
  return (
    <Row>
      <Col span={16}>
        <Title className="dunia-header" level={3}>
          Product: VISA #{internId}, {travelerName} | {countryInfo}
        </Title>
      </Col>
      <Col
        span={8}
        style={{
          textAlign: "right",
          paddingRight: 24
        }}
      >
        {isEditMode ? (
          <ConnectedVisaButtons header />
        ) : (
          <Tooltip placement="top" title="Switch to edit mode">
            <Button
              type="link"
              onClick={() => changeMode("edit")}
              style={{
                padding: 0,
                marginLeft: 12,
                marginRight: 16
              }}
            >
              <Icon
                type="edit"
                theme="twoTone"
                twoToneColor="#fcad1c"
                style={{ fontSize: 24 }}
              />
            </Button>
          </Tooltip>
        )}
      </Col>
    </Row>
  );
};

const VisaFooter: React.SFC = () => {
  return <ConnectedVisaButtons />;
};

const VisaButtons: React.SFC<{
  header?: boolean;
  loading: boolean;
}> = ({ header = false, loading }) => (
  <span
    className={header ? "visa-buttons" : undefined}
    style={header ? { float: "right", marginRight: 12 } : undefined}
  >
    <BackButton />
    <ForwardButton />
    <Button
      form="visa-form"
      className="dark"
      type="primary"
      loading={loading}
      htmlType="submit"
    >
      Save
    </Button>
  </span>
);

const ConnectedVisaButtons = connect(({ visa: { loading } }: AppState) => ({
  loading
}))(VisaButtons);

export interface Props {
  visible: boolean;
  onClose: () => void;
  visaId: number;
  traveler: Traveler;
  refreshVisaList?: () => void;
  loading: boolean;
  countryInfo: string;
  mode: Mode;
  changeMode: (newMode: Mode) => void;
  internId: string;
}

const VisaDetail: React.SFC<Props> = ({
  visible,
  onClose,
  visaId,
  traveler,
  refreshVisaList,
  loading,
  countryInfo,
  mode,
  changeMode,
  internId
}) => {
  const { name, lastName } = traveler;
  const travelerName = getName(name, lastName);
  const isEditMode = mode === "edit";
  return (
    <Spin spinning={loading}>
      <Modal
        visible={visible}
        width="98%"
        title={
          <VisaHeader
            countryInfo={countryInfo}
            travelerName={travelerName}
            isEditMode={isEditMode}
            changeMode={changeMode}
            internId={internId}
          />
        }
        footer={isEditMode ? <VisaFooter /> : null}
        onCancel={() => {
          onClose();
          if (refreshVisaList) {
            refreshVisaList();
          }
        }}
        style={{ top: 10 }}
      >
        <VisaForm traveler={traveler}></VisaForm>
        <Row>
          <Insurance />
        </Row>
        <Row>
          <VisaNotes visaRequestId={visaId} />
        </Row>
        <Row>
          <TravelerAttachments
            isEditMode={mode === "edit"}
            docTypeFilter={dt =>
              dt.area !== "SystemGenerated" && dt.area !== "Payment"
            }
            title="Checklist documents"
            inProductDetail
          />
        </Row>
        <Row>
          <VisaGeneratedDocs />
        </Row>
        <Row>
          <TravelerChecklist />
        </Row>
      </Modal>
    </Spin>
  );
};

const VisaNotes = connect(
  ({
    visaNotes: { visaNotes, mode, loading },
    visa: { mode: visaMode }
  }: AppState) => ({
    notes: visaNotes,
    mode,
    loading,
    disabled: visaMode === "view"
  }),
  {
    setMode: visaNoteActions.setMode,
    onSubmit: visaNoteActions.submitNote
  }
)(Notes);

const mapStateToProps = ({
  visa: {
    visible,
    visa: { id },
    loading,
    mode
  },
  traveler: { traveler },
  requestDetail: {
    request: { confirmedCountry, requestedCountry, businessCaseId, travelers }
  }
}: AppState) => {
  const t = travelers.find(v => v.id === traveler.id);
  return {
    internId: `${businessCaseId}${t ? t.alphaId : ""}`,
    mode,
    visible,
    visaId: id,
    traveler,
    loading,
    countryInfo: `${
      confirmedCountry
        ? `${
            confirmedCountry.regions !== null
              ? `${confirmedCountry.regions.name} / ${confirmedCountry.name}`
              : ""
          }`
        : requestedCountry
        ? `${
            requestedCountry!.regions !== null
              ? `${requestedCountry!.regions.name} / ${requestedCountry!.name}`
              : ""
          }`
        : ""
    }`
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onClose: () => {
    dispatch(visaActions.setVisible(false));
    dispatch(visaActions.changeMode("view"));
    dispatch(travelerActions.changeMode("view"));
  },
  changeMode: (newMode: Mode) => {
    dispatch(visaActions.changeMode(newMode));
    dispatch(travelerActions.changeMode(newMode));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisaDetail);
