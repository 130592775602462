import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./components/_App";
import * as serviceWorker from "./helpers/serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Provider } from "react-redux";
import store from "./store/appStore";

ReactDOM.render(
  <BrowserRouter>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiPickersUtilsProvider>
  </BrowserRouter>,
  document.getElementById("app")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
