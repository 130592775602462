import * as React from "react";
import { Row, Col } from "antd";
import InputField from "../_common/InputField/InputField";
import { Mode } from "../../types";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import MyCard from "../_common/MyCard";

export interface Props {
  disabled: boolean;
}

const Employments: React.SFC<Props> = ({ disabled }) => {
  return (
    <MyCard
      title="Employment"
      bordered={false}
      headStyle={{ backgroundColor: "#f2f2f2" }}
    >
      <Row>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.employerOccupation"
            label="Occupation"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.employerInfo"
            label="Empl. or school"
          />
        </Col>
        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.employerAddressPhone"
            label="Address"
          />
        </Col>

        <Col span={6}>
          <InputField
            disabled={disabled}
            name="traveler.employerAddress"
            label="Address"
          />
        </Col>
      </Row>
    </MyCard>
  );
};

export default Employments;
