import AbstractRestService from "./AbstractRestService";
import { AxiosPromise } from "axios";
import Fee from "../../model/Fee";
import Invoice from "../../model/Invoice";

export default class InvoiceService extends AbstractRestService<any> {
  constructor() {
    super("CodeLists/Invoce");
  }

  getByRequestItemId(id: number): AxiosPromise<any> {
    return this._http.get(`${this.apiUrl}${id}/requestiteminvoce`);
  }

  addFees(invoiceId: number, fees: Fee[]): AxiosPromise<any> {
    return this._http.put(`${this.apiUrl}${invoiceId}/fees`, fees);
  }

  generateInvoice(invoiceId: number): AxiosPromise<any> {
    return this._http.get(`${this.apiUrl}${invoiceId}/createinvoicepdf`);
  }

  downloadInvoice(invoiceId: number): AxiosPromise<any> {
    return this._http.get(`${this.apiUrl}${invoiceId}/downloaddata
    `);
  }

  makeInvoice(invoiceId: number): AxiosPromise<any> {
    return this._http.post(`${this.apiUrl}${invoiceId}/makeinvoice
    `);
  }

  getDeletedInvoices(reqItemId: number): AxiosPromise<Invoice[]> {
    return this._http.get(`${this.apiUrl}${reqItemId}/deletedinvoice
    `);
  }

  getInvoiceHtml(invoiceId: number, isComplex: boolean): AxiosPromise<string> {
    return this._http.get(`${this.apiUrl}${invoiceId}/createinvoicehtml/${
      isComplex ? 1 : 2
    }
    `);
  }
}
