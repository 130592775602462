import * as React from "react";
import {
  Table,
  Button,
  Modal,
  Checkbox,
  Row,
  Radio,
  message,
  Tooltip
} from "antd";
import TravelerDocument from "../../model/TravelerDocument";
import MyCard from "../_common/MyCard";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import VisaRequestService from "../../helpers/model_services/VisaRequestService";
import Traveler from "../../model/Traveler";
import { requestActions } from "../../redux/request/requestSlice";
import useSelectVals from "../_hooks/useSelectVals";
import DocumentsTypesService from "../../helpers/model_services/DocumentsTypesService";
import "../../css/GeneratedDocuments.css";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";
import getName from "../../helpers/MyFunctions";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface Props {
  documents: TravelerDocument[];
  travelers: Traveler[];
  refreshData: () => void;
  userName: string;
  isBc: boolean;
}

interface GenDoc {
  documentTypeName: string;
  docs: TravelerDocument[];
}

const visaService = new VisaRequestService();
const docService = new TravelerDocumentsService();
const GeneratedDocuments: React.FC<Props> = ({
  documents,
  travelers,
  refreshData,
  userName,
  isBc
}) => {
  const [isGenerating, setIsGenerating] = React.useState<boolean>(false);
  const { data: docTypes } = useSelectVals(new DocumentsTypesService());
  const [visible, setVisible] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<number[]>([]);
  const [type, setType] = React.useState<number>(3);
  const generatedDocTypes = docTypes.filter(
    ({ area }) => area === "SystemGenerated"
  );
  const data: GenDoc[] = [];

  generatedDocTypes.forEach(({ id, name }) => {
    const filteredDocs = documents.filter(
      ({ documentsTypesId }) => id === documentsTypesId
    );
    if (filteredDocs.length > 0) {
      data.push({
        documentTypeName: name,
        docs: filteredDocs.sort((a, b) => a.alphaId.localeCompare(b.alphaId))
      });
    }
  });
  const closeModal = () => {
    setVisible(false);
    setIsGenerating(false);
    setSelected([]);
    setType(3);
  };

  return (
    <MyCard
      title="Generated documents"
      extra={
        <React.Fragment>
          <Button
            type="primary"
            className="dark"
            size="small"
            disabled={!isBc}
            onClick={() => {
              setVisible(true);
            }}
          >
            Generate
          </Button>
          <Modal
            title="Generate documents"
            visible={visible}
            okButtonProps={{ loading: isGenerating }}
            onOk={async () => {
              setIsGenerating(true);
              const find = travelers.findIndex(t => t.alphaId === "A");
              try {
                if (type === 3) {
                  await Promise.all(
                    selected.map(e =>
                      visaService.generateDocuments(e, userName)
                    )
                  );
                  refreshData();
                  message.success("Documents were succesfully generated.");
                  closeModal();
                } else {
                  if (find !== -1) {
                    await visaService.generateDocuments(
                      travelers[find].id,
                      userName,
                      type,
                      selected
                    );
                    refreshData();
                    message.success("Documents were succesfully generated.");
                    closeModal();
                  }
                }
              } catch (error) {
                setIsGenerating(false);
              }
            }}
            onCancel={closeModal}
            okText="Generate"
          >
            <Row style={{ marginBottom: 12 }}>
              <Radio.Group
                value={type}
                onChange={({ target: { value } }) => {
                  setType(value);
                  if (value === 3) {
                    setSelected([]);
                  } else {
                    setSelected(travelers.map(({ id }) => id));
                  }
                }}
              >
                <Radio value={1}>Check in</Radio>
                <Radio value={2}>Delivery form</Radio>
                <Radio value={3}>Complete set</Radio>
              </Radio.Group>
            </Row>
            <Row>
              <Checkbox.Group
                onChange={value => setSelected(value as number[])}
                value={selected}
              >
                {travelers.map(({ name, lastName, id }) => (
                  <Row key={id}>
                    <Checkbox value={id}>{getName(name, lastName)}</Checkbox>
                  </Row>
                ))}
              </Checkbox.Group>
            </Row>
          </Modal>
        </React.Fragment>
      }
    >
      <Table<GenDoc>
        columns={[
          {
            width: 30,
            render: (text, { docs }) => {
              if (docs.length > 0) {
                const {
                  note,
                  documentsTypes: { iconName }
                } = docs[0];
                return note && note !== null ? (
                  <Tooltip placement="top" title={note}>
                    <i style={{ fontSize: 20 }} className={iconName} />
                  </Tooltip>
                ) : (
                  <i style={{ fontSize: 20 }} className={iconName} />
                );
              } else return null;
            }
          },
          { dataIndex: "documentTypeName", title: "Document type" },
          {
            title: "Travelers",
            render: (id, { docs }, index) => (
              <span>
                {docs.map(d => (
                  <Button
                    key={d.id}
                    type="link"
                    className="generated-doc no-color"
                    onClick={() => {
                      docService
                        .getDocumentURL(d.id)
                        .then(({ data: { documentUrl } }) => {
                          const link = document.createElement("a");
                          link.href = documentUrl;
                          link.target = "_blank";
                          link.rel = "noopener noreferrer";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        });
                    }}
                  >
                    {d.alphaId}
                  </Button>
                ))}
              </span>
            )
          }
        ]}
        dataSource={data}
        pagination={false}
        size="middle"
      />
    </MyCard>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelersDocuments, travelers, businessCaseId }
  },
  user: {
    user: { name, lastName }
  }
}: AppState) => ({
  documents: travelersDocuments.filter(({ documentsTypes, documentUrl }) => {
    const isDoc = documentUrl !== null;
    const isGenerated = documentsTypes.area === "SystemGenerated";
    return isDoc && isGenerated;
  }),
  travelers,
  userName: getName(name, lastName),
  isBc: businessCaseId !== null
});

const mapDispatchToProps = {
  refreshData: requestActions.fetchRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneratedDocuments);
