import * as React from "react";
import MyCard from "../_common/MyCard";
import { connect } from "react-redux";
import { AppState } from "../../store/appStore";
import InsuranceModel from "../../model/Insurance";
import { Descriptions, Button, message, Modal, Radio } from "antd";
import moment from "moment/moment.js";
import { visaActions } from "../../redux/visa/visaSlice";
import TravelerDocumentsService from "../../helpers/model_services/TravelerDocumentsService";

export interface Props {
  insurance: InsuranceModel[];
  loading: boolean;
  manageInsurance: (isCancelled: boolean) => void;
  downloadInsurance: (type: number) => Promise<number>;
  disabled: boolean;
}

const service = new TravelerDocumentsService();

const Insurance: React.FC<Props> = ({
  insurance,
  loading,
  manageInsurance,
  downloadInsurance,
  disabled
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [type, setType] = React.useState<number>(1);
  const hasInsurance = insurance.length > 0;
  const close = () => {
    setVisible(false);
    setType(1);
  };
  return (
    <MyCard
      title="Insurance"
      extra={
        hasInsurance && !insurance[0].isCancelled ? (
          <span>
            <Button
              disabled={disabled}
              loading={loading}
              className="dark"
              type="primary"
              onClick={() => setVisible(true)}
              style={{ marginRight: 12 }}
            >
              Download
            </Button>
            <Modal
              title="Choose insurance type to download"
              visible={visible}
              okButtonProps={{ loading }}
              onOk={async () => {
                try {
                  const resp = await downloadInsurance(type);
                  if (resp !== -1) {
                    const {
                      data: { documentUrl }
                    } = await service.getDocumentURL(resp);

                    const link = document.createElement("a");
                    link.href = documentUrl;
                    link.target = "_blank";
                    link.rel = "noopener noreferrer";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    close();
                  }
                } catch (error) {}
              }}
              onCancel={close}
              okText="Download"
            >
              <Radio.Group
                value={type}
                onChange={({ target: { value } }) => {
                  setType(value);
                }}
              >
                <Radio value={1}>Certificate insurance</Radio>
                <Radio value={2}>Client insurance</Radio>
                <Radio value={3}>Embassy insurance</Radio>
              </Radio.Group>
            </Modal>
            <Button
              disabled={disabled}
              loading={loading}
              className="dark"
              type="primary"
              onClick={() => manageInsurance(true)}
            >
              Cancel
            </Button>
          </span>
        ) : (
          <Button
            disabled={disabled}
            loading={loading}
            className="dark"
            type="primary"
            onClick={() => manageInsurance(false)}
          >
            Generate
          </Button>
        )
      }
    >
      {hasInsurance ? (
        <Descriptions bordered size="small">
          <Descriptions.Item label="From">
            {moment(insurance[0].from).format("D.M.YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="To">
            {moment(insurance[0].to).format("D.M.YYYY")}
          </Descriptions.Item>
          <Descriptions.Item label="Duration">
            {Math.round(
              Math.abs(
                (new Date(insurance[0].from).getTime() -
                  new Date(insurance[0].to).getTime()) /
                  (24 * 60 * 60 * 1000)
              )
            )}
          </Descriptions.Item>
        </Descriptions>
      ) : null}
    </MyCard>
  );
};

const mapStateToProps = ({
  visa: {
    visa: { insurance },
    loading,
    mode
  }
}: AppState) => ({
  insurance,
  loading,
  disabled: mode === "view"
});

const dispatchToProps = {
  manageInsurance: visaActions.manageInsurance,
  downloadInsurance: visaActions.downloadInsurance
};

export default connect(
  mapStateToProps,
  dispatchToProps
)(Insurance);
