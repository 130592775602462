import * as React from "react";
import Card, { CardProps } from "antd/lib/card";

const defaultStyle = {
  background: "#fff",
  margin: 12,
  padding: 0
};

const defaultHeadStyle = {
  backgroundColor: "#bcbcbc",
  fontSize: 16,
  fontWeight: 700
};

const MyCard: React.SFC<CardProps> = ({
  style: newStyle,
  headStyle: newHeadStyle,
  ...rest
}) => (
  <Card
    bordered={false}
    headStyle={{ ...defaultHeadStyle, ...newHeadStyle }}
    bodyStyle={{ backgroundColor: "#ffffff" }}
    {...rest}
    size="small"
    style={{ ...defaultStyle, ...newStyle }}
  />
);
export default MyCard;
