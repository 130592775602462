import { useState, useEffect } from "react";
import AbstractRestService from "../../helpers/model_services/AbstractRestService";
import BaseClass from "../../model/_BaseClass";

export default function useSelectVals<T extends BaseClass>(
  service: AbstractRestService<T>
) {
  const [data, setData] = useState<T[]>([]);

  useEffect(() => {
    initData();
  }, []);

  async function initData() {
    const { data } = await service.getAll();
    setData(data);
  }

  return {
    data
  };
}
