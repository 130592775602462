import AbstractRestService from "./AbstractRestService";
import { TravelerNote } from "../../model/Note";

export default class TravelerNoteService extends AbstractRestService<
  TravelerNote
> {
  constructor() {
    super("Codelists/TravelersNote");
  }
}
