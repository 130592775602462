import AbstractRestService from "./AbstractRestService";
import Fee from "../../model/Fee";
import { AxiosPromise } from "axios";
import FeeType from "../../model/FeeType";

export default class FeeService extends AbstractRestService<Fee> {
  constructor() {
    super("Codelists/Fee");
  }

  travelers() {
    return this._http.put(this.apiUrl + "travelers");
  }

  getFeeTypes(reqItemid: number): AxiosPromise<FeeType[]> {
    return this._http.get(`${this.apiUrl}${reqItemid}/typeofpayment`);
  }
}
