import AbstractRestService from "./AbstractRestService";
import RequestItemStatuses from "../../model/RequestItemStatus";

export default class RequestItemStatusesService extends AbstractRestService<
  RequestItemStatuses
> {
  constructor() {
    super("Codelists/RequestItemStatuses");
  }
}
