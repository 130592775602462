import * as React from "react";
import { message, Button, Drawer } from "antd";
import CustomerForm from "../customer/CustomerForm";
import { ShortCustomer } from "../../helpers/model_services/CustomerService";

export interface Props {
  onSubmit: (values: ShortCustomer) => Promise<boolean>;
  loading: boolean;
}

const NewCustomer: React.FC<Props> = ({ onSubmit, loading }) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const handleClose = () => setVisible(false);
  return (
    <React.Fragment>
      <Button loading={loading} type="primary" onClick={() => setVisible(true)}>
        Add
      </Button>
      <Drawer
        visible={visible}
        width={500}
        onClose={handleClose}
        style={{
          overflow: "auto",
          height: "calc(100% - 108px)",
          paddingBottom: "108px"
        }}
        title="New customer"
      >
        <CustomerForm
          onSubmit={values => {
            try {
              onSubmit(values).then(res => {
                if (res) {
                  message.success("Customer was created.");
                  setVisible(false);
                }
              });
            } catch (error) {}
          }}
          values={{ password: "initialCustomerPassword" } as ShortCustomer}
          onCancel={handleClose}
        />
      </Drawer>
    </React.Fragment>
  );
};

export default NewCustomer;
