import * as React from "react";
import {
  Row,
  Form,
  Select,
  DatePicker,
  Icon,
  InputNumber,
  Col,
  Input
} from "antd";
import { Field, FieldProps } from "formik";
import VisaRequest from "../../model/VisaRequest";
import InputField from "../_common/InputField/InputField";
import moment from "moment/moment.js";
import { OptionProps } from "antd/lib/select";
import useSelectVals from "../_hooks/useSelectVals";
import VisaStatusService from "../../helpers/model_services/VisaStatusService";
import CountryService from "../../helpers/model_services/CountryService";
import { VisaOptions } from "../../helpers/model_services/VisaRequestService";
import { AppState } from "../../store/appStore";
import { connect } from "react-redux";
import MyCard from "../_common/MyCard";
import { Mode } from "../../types";

export interface Props {
  options: VisaOptions;
  servicePlaceDisabled: boolean;
  reqCountryName: string;
  confCountryName: string;
  mode: Mode;
}

const MainFormSection: React.SFC<Props> = ({
  options: {
    entryCount: entries,
    porpouses: purposes,
    servicePlace: servicePlaces,
    tier: tiers
  },
  servicePlaceDisabled,
  children,
  reqCountryName,
  confCountryName,
  mode
}) => {
  const { data: visaStatus } = useSelectVals(new VisaStatusService());
  const { data: countries } = useSelectVals(new CountryService());
  const disabled = mode === "view";
  const isEdit = mode === "edit";
  return (
    <MyCard bordered={false}>
      {children}
      <Row>
        <Col span={7}>
          <Field
            name="visa.dateFrom"
            render={({
              field,
              form: {
                setFieldValue,
                values: { visa: values }
              }
            }: FieldProps<{ visa: VisaRequest }>) => (
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                label="Req. date from"
                style={{ marginBottom: 12 }}
              >
                <DatePicker
                  onChange={(value: any) => {
                    if (
                      !values.confirmedTripDateTo ||
                      (values.confirmedTripDateTo === null &&
                        values.dateTo &&
                        values.dateTo !== null)
                    ) {
                      const from = moment(value).startOf("day");
                      const to = moment(values.dateTo).startOf("day");
                      setFieldValue("visa", {
                        ...values,
                        durationOfStay: to.diff(from, "days") + 1
                      });
                    }
                    setFieldValue(field.name, value);
                  }}
                  value={
                    field.value === null || field.value === undefined
                      ? undefined
                      : moment(field.value)
                  }
                  format="D.M.YYYY"
                  disabled={isEdit || disabled}
                />
              </Form.Item>
            )}
          />
        </Col>
        <Col span={7}>
          <Field
            name="visa.dateTo"
            render={({
              field,
              form: {
                setFieldValue,
                values: { visa: values }
              }
            }: FieldProps<{ visa: VisaRequest }>) => (
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                label="Req. date to"
                style={{ marginBottom: 12 }}
              >
                <DatePicker
                  onChange={(value: any) => {
                    if (
                      !values.confirmedTripDateTo ||
                      (values.confirmedTripDateTo === null &&
                        values.dateFrom &&
                        values.dateFrom !== null)
                    ) {
                      const from = moment(values.dateFrom).startOf("day");
                      const to = moment(value).startOf("day");
                      setFieldValue("visa", {
                        ...values,
                        durationOfStay: to.diff(from, "days") + 1
                      });
                    }
                    setFieldValue(field.name, value);
                  }}
                  value={
                    field.value === null || field.value === undefined
                      ? undefined
                      : moment(field.value)
                  }
                  format="D.M.YYYY"
                  disabled={isEdit || disabled}
                />
              </Form.Item>
            )}
          />
        </Col>
        <Col span={5}>
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            label="Req. country"
            style={{ marginBottom: 12 }}
          >
            <Input disabled value={reqCountryName}></Input>
          </Form.Item>
        </Col>
        <Col span={5}>
          <InputField
            disabled={disabled}
            name="visa.visaRequestStatusesId"
            label="Status"
            type="select"
            node={
              <Select disabled={disabled}>
                {visaStatus.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          <Field
            name="visa.confirmedTripDateFrom"
            render={({
              field,
              form: {
                setFieldValue,
                values: { visa: values }
              }
            }: FieldProps<{ visa: VisaRequest }>) => (
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                label="Con. date from"
                style={{ marginBottom: 12 }}
              >
                <span>
                  <DatePicker
                    disabled={disabled}
                    onChange={(value: any) => {
                      if (
                        values.confirmedTripDateTo &&
                        values.confirmedTripDateTo !== null
                      ) {
                        const from = moment(value).startOf("day");
                        const to = moment(values.confirmedTripDateTo).startOf(
                          "day"
                        );
                        setFieldValue("visa", {
                          ...values,
                          durationOfStay: to.diff(from, "days") + 1
                        });
                      }
                      setFieldValue(field.name, value);
                    }}
                    value={
                      field.value === null || !field.value
                        ? undefined
                        : moment(field.value)
                    }
                    format="D.M.YYYY"
                    style={{ marginRight: 3 }}
                  />
                  {!disabled && (
                    <Icon
                      type="copy"
                      onClick={() => {
                        const value = values.dateFrom;
                        if (
                          values.confirmedTripDateTo &&
                          values.confirmedTripDateTo !== null
                        ) {
                          const from = moment(value).startOf("day");
                          const to = moment(values.confirmedTripDateTo).startOf(
                            "day"
                          );
                          setFieldValue("visa", {
                            ...values,
                            durationOfStay: to.diff(from, "days") + 1
                          });
                        }
                        setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </span>
              </Form.Item>
            )}
          />
        </Col>
        <Col span={7}>
          <Field
            name="visa.confirmedTripDateTo"
            render={({
              field,
              form: {
                setFieldValue,
                values: { visa: values }
              }
            }: FieldProps<{ visa: VisaRequest }>) => (
              <Form.Item
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                label="Con. date to"
                style={{ marginBottom: 12 }}
              >
                <span>
                  <DatePicker
                    disabled={disabled}
                    onChange={(value: any) => {
                      if (
                        values.confirmedTripDateFrom &&
                        values.confirmedTripDateFrom !== null
                      ) {
                        const from = moment(
                          values.confirmedTripDateFrom
                        ).startOf("day");
                        const to = moment(value).startOf("day");
                        setFieldValue("visa", {
                          ...values,
                          durationOfStay: to.diff(from, "days") + 1
                        });
                      }
                      setFieldValue(field.name, value);
                    }}
                    value={
                      field.value === null || !field.value
                        ? undefined
                        : moment(field.value)
                    }
                    format="D.M.YYYY"
                    style={{ marginRight: 3 }}
                  />
                  {!disabled && (
                    <Icon
                      type="copy"
                      onClick={() => {
                        const value = values.dateTo;
                        if (
                          values.confirmedTripDateFrom &&
                          values.confirmedTripDateFrom !== null
                        ) {
                          const from = moment(
                            values.confirmedTripDateFrom
                          ).startOf("day");
                          const to = moment(value).startOf("day");
                          setFieldValue(
                            "visa.durationOfStay",
                            to.diff(from, "days") + 1
                          );
                        }
                        setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </span>
              </Form.Item>
            )}
          />
        </Col>
        <Col span={5}>
          <Form.Item
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 14 }}
            label="Con. country"
            style={{ marginBottom: 12 }}
          >
            <Input disabled value={confCountryName}></Input>
          </Form.Item>
        </Col>
        <Col span={5}>
          <InputField
            disabled={disabled}
            name="visa.visaRequestPurposesId"
            label="Purpose"
            type="select"
            node={
              <Select>
                {purposes.map(({ purposeName: name, purposeId: id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          <InputField
            disabled={disabled}
            name="visa.visaEntryCountId"
            label="Entries"
            type="select"
            node={
              <Select>
                {entries.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={7}>
          <InputField
            disabled={disabled}
            name="visa.durationOfStay"
            label="Duration of stay"
            node={<InputNumber />}
            type="number"
          />
        </Col>
        <Col span={5}>
          <InputField
            disabled={disabled || servicePlaceDisabled}
            name="visa.servicePlaceId"
            label="Service place"
            type="select"
            node={
              <Select>
                {servicePlaces.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={5}>
          <InputField
            disabled={disabled}
            name="visa.servicePlaceAppointmentDateTime"
            label="App. date"
            node={<DatePicker style={{ minWidth: 0 }} />}
            type="datetime"
          />
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          <InputField
            disabled={disabled}
            name="visa.firstCountryOfEntryId"
            label="Mem. state of 1st entry"
            type="select"
            node={
              <Select
                showSearch
                defaultActiveFirstOption={false}
                filterOption={(
                  inputValue: string,
                  option: React.ReactElement<OptionProps>
                ) =>
                  option.props
                    .children!.toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {countries.map(({ name, id }) => (
                  <Select.Option key={id} value={id}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        <Col span={7}>
          <InputField
            disabled={disabled}
            name="visa.statesOfDestination"
            label="Mem. states of dest."
            type="select"
            node={
              <Select
                showSearch
                defaultActiveFirstOption={false}
                filterOption={(
                  inputValue: string,
                  option: React.ReactElement<OptionProps>
                ) =>
                  option.props
                    .children!.toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {countries.map(({ name, id }) => (
                  <Select.Option key={id} value={name}>
                    {name}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
        {tiers.length > 0 ? (
          <Col span={5}>
            <InputField
              disabled={disabled}
              name="visa.tier"
              label="Tier"
              type="select"
              node={
                <Select>
                  {tiers.map(({ tier }) => (
                    <Select.Option key={tier} value={tier}>
                      {tier}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
          </Col>
        ) : null}
      </Row>
    </MyCard>
  );
};

const mapStateToProps = ({
  requestDetail: {
    formOptions,
    request: { requestedCountry, confirmedCountry }
  },
  visa: { mode }
}: AppState) => ({
  options: formOptions,
  reqCountryName: requestedCountry ? requestedCountry.name : "-",
  confCountryName: confirmedCountry ? confirmedCountry.name : "-",
  mode
});

export default connect(mapStateToProps)(MainFormSection);
