import * as React from "react";
import { Button, Drawer, Form, Select, Row, Col, message } from "antd";
import { connect } from "react-redux";
import { Mode } from "../../types";
import { Formik } from "formik";
import Traveler from "../../model/Traveler";
import { AppState } from "../../store/appStore";
import VisaRequest from "../../model/VisaRequest";
import { visaActions } from "../../redux/visa/visaSlice";
import MainFormSection from "./MainFormSection";
import PreviousVisa from "./PreviousVisa";
import FinalCountry from "./FinalCountry";
import InvitedByPerson from "./InvitedByPerson";
import InvitedByCompany from "./InvitedByCompany";
import InputField from "../_common/InputField/InputField";
import PriceListService from "../../helpers/model_services/PriceListService";
import getName from "../../helpers/MyFunctions";
import Sponsor from "./Sponsor";

const priceService = new PriceListService();

export interface Props {
  changeMode: (newMode: Mode) => void;
  onSubmit: (val: VisaRequest) => Promise<boolean>;
  travelers: Traveler[];
  countryId: number;
}

const NewVisa: React.FC<Props> = ({
  changeMode,
  onSubmit,
  travelers,
  countryId
}) => {
  const [visible, setVisible] = React.useState<boolean>(false);
  const [servicePlaceDisabled, setServicePlaceDisabled] = React.useState<
    boolean
  >(false);
  return (
    <React.Fragment>
      <Button
        type="primary"
        className="dark"
        onClick={() => {
          changeMode("new");
          setVisible(true);
        }}
        size="small"
      >
        + Add product
      </Button>
      <Formik
        enableReinitialize
        initialValues={{
          visa: {
            hasPastVisa: false,
            hasPreviousFingerPrint: false,
            durationOfStay: 0,
            isCoveredBySponzor: false
          } as VisaRequest
        }}
        onSubmit={({ visa: values }, { resetForm }) => {
          if (values.travelerId) {
            onSubmit(values).then(res => {
              if (res) {
                changeMode("new");
                setVisible(true);
                resetForm();
                message.success(`Visa request was succesfully created.`);
              }
            });
          } else {
            message.error("No traveler selected.");
          }
        }}
        render={({
          handleSubmit,
          resetForm,
          values: {
            visa: { recognizedData }
          },
          setFieldValue
        }) => {
          return (
            <Drawer
              title="New product"
              width="95%"
              onClose={() => {
                setVisible(false);
                changeMode("view");
                resetForm();
              }}
              visible={visible}
              style={{
                overflow: "auto",
                paddingBottom: "108px"
              }}
            >
              <Form
                layout="horizontal"
                hideRequiredMark
                onSubmit={handleSubmit}
              >
                <MainFormSection servicePlaceDisabled={servicePlaceDisabled}>
                  <Row>
                    <Col span={7}>
                      <InputField
                        name="visa.travelerId"
                        label="Traveler"
                        type="select"
                        node={
                          <Select<number>
                            onChange={tId => {
                              const tIndex = travelers.findIndex(
                                t => t.id === tId
                              );

                              if (tIndex !== -1) {
                                const { isSaudi, name, lastName } = travelers[
                                  tIndex
                                ];
                                priceService
                                  .checkServicePlace(countryId, isSaudi)
                                  .then(({ data }) => {
                                    setServicePlaceDisabled(data === -1);
                                    if (data === 0) {
                                      message.error(
                                        `Traveler ${getName(
                                          name,
                                          lastName
                                        )} cannot get Visa for current country.`
                                      );
                                    } else {
                                      setFieldValue("visa.travelerId", tId);
                                    }
                                  });
                              }
                            }}
                          >
                            {travelers.map(({ name, lastName, id }) => (
                              <Select.Option key={id} value={id}>
                                {`${name === null ? "" : name}${
                                  lastName === null ? "" : ` ${lastName}`
                                }`}
                              </Select.Option>
                            ))}
                          </Select>
                        }
                      />
                    </Col>
                  </Row>
                </MainFormSection>
                <PreviousVisa recognizedData={recognizedData} />
                <FinalCountry />
                <InvitedByPerson />
                <InvitedByCompany />
                <Sponsor />
                <div
                  style={{
                    left: 0,
                    bottom: 0,
                    width: "100%",
                    borderTop: "1px solid #e9e9e9",
                    padding: "10px 16px",
                    background: "#fff",
                    textAlign: "right"
                  }}
                >
                  <Button
                    onClick={() => {
                      setVisible(false);
                      changeMode("view");
                      resetForm();
                    }}
                    style={{ marginRight: 8 }}
                  >
                    Cancel
                  </Button>
                  <Button htmlType="submit" type="primary">
                    Submit
                  </Button>
                </div>
              </Form>
            </Drawer>
          );
        }}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({
  requestDetail: {
    request: { travelers, confirmedCountryId, requestedCountryId }
  }
}: AppState) => ({
  travelers,
  countryId:
    confirmedCountryId === null ? requestedCountryId : confirmedCountryId
});

const mapDispatchToProps = {
  changeMode: visaActions.changeMode,
  onSubmit: visaActions.submitVisaRequest
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewVisa);
